import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backIcon from '@assets/h5Images/backIcon';
import { Input, Form } from 'antd';
import { updateUser_Func } from "../../features/loginClice"
import { Toast } from "antd-mobile";
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;
export default function Index() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { VIPNFT, userInfo } = useSelector((state: RootState) => {

        return {
            VIPNFT: state.nftModule.VIPNFT.length > 0 ? state.nftModule.VIPNFT[0] : null,
            userInfo: state.loginModule.userInfo
        }
    })


    useEffect(() => {
        if (userInfo) {
            form.setFieldsValue({
                name: userInfo.name,
                introduction: userInfo.introduction
            })
        }
    }, [userInfo])

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            dispatch(updateUser_Func(values.name, values.introduction))
        } catch (errorInfo: any) {
            Toast.show(errorInfo);
        }
    }
    return <div className="h5-personalPage-container">
        <div className="personal-container">
            <div className="personal-head" >
                <img src={backIcon} onClick={() => {
                    // onBackClick && onBackClick();
                    navigate("/wallet")
                }} />Personal
            </div>

            <div className="head-img">
                <img src={VIPNFT?.image} />
            </div>

            <div className="from-card">
                <Form form={form} layout='vertical' autoComplete='off'>
                    <Form.Item required={false} rules={[{ required: true, message: 'Cannot be empty,up to 20 characters' }]} name="name" label={<div className="label">Name</div>}>
                        <Input className='personal-input' maxLength={20} />
                    </Form.Item>
                    <Form.Item required={false} name="introduction" label={<div className="label">Introduction</div>}>
                        <TextArea style={{
                            height: 139,
                            resize: 'none'
                        }} maxLength={100} className='personal-TextArea' />
                    </Form.Item>
                </Form>
                <div className="btn" onClick={onCheck}>
                    Save
                </div>
            </div>
        </div>
    </div>
}