// import { agoraRTCJion, client, getAgoraUser_Func } from '@features/agoraRTCClice';
import React, { useRef } from 'react';
import { message } from 'antd'
import { forwardRef, useEffect, useLayoutEffect, useState, useImperativeHandle } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng';
import { config } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomRtcToken2_fun, getRoomRtcToken_fun } from '@features/loginClice';
import { EncoderConfigEnum, UserTypeEnum, getliveInfo_Func, setRoomInfo, setTokenInfo_Func, tokenResultError_Func } from '@features/roomClice';
import AgoraVideoPlayer from "@H5Components/VideoPlayer"
import GiftAnimation from '@components/GiftAnimation';
import BlindBoxGiftAnimation from '@components/GiftAnimation/blindBox';
import Spin from "react-spinners/ScaleLoader";
import './index.scss';
import { RootState } from '@/store';
import { ChannelTypeEnum } from '@/features/globalClice';

const client = AgoraRTC.createClient({
    mode: "live",
    codec: "vp8"
});



function Index(props: any, ref: any) {
    const dispatch = useDispatch();
    const [definition, setDefinition] = useState<EncoderConfigEnum>(EncoderConfigEnum.p1080);
    const [videoTrack, setVideoTrack] = useState<any>(null)
    const [audioTrack, setAudioTrack] = useState<any>(null)
    const [remoteUsers, setRemoteUsers] = useState<any>({})
    const [viewVolumeValue, setViewVolumeValue] = useState(100);


    const [status, setStatus] = useState(0);   //状态  0初始进入    1直播流加入     2直播流离开;

    const { userInfo, userType, VIPNFT, roomId } = useSelector((state: RootState) => {
        let roomId = 0;

        if (state.roomModule.onLineList.length > 0) {
            const roomObj = state.roomModule.onLineList.find((item) => {
                return item.roomType == 0
            })
            if (roomObj) {
                roomId = roomObj.roomId
            }
        }

        return {
            userInfo: state.loginModule.userInfo,
            userType: state.roomModule.userType,
            VIPNFT: state.nftModule.VIPNFT,
            roomId: roomId
        }
    });


    useEffect(() => {
        if (roomId > 0 && userInfo) {

            dispatch(getRoomRtcToken2_fun(roomId, VIPNFT.length <= 0 ? UserTypeEnum.audience : UserTypeEnum.vipAudience, definition, (data: any) => {
                if (data) {
                    join(VIPNFT.length <= 0 ? UserTypeEnum.audience : UserTypeEnum.vipAudience, data.rtcToken, roomId)
                }
            }));
        }

        return () => {
            leave();
        }

    }, [roomId, VIPNFT])
    useEffect(() => {
        if (audioTrack) {
            audioTrack.setVolume(viewVolumeValue)
        }
    }, [audioTrack, viewVolumeValue])
    const join = async (type: UserTypeEnum, AgoraRTCToken: string, channelName: number, cb?: Function) => { 
        try {
            const options: any = {}
            options.uid = await client.join(config.agoraRTCAppKey, `${channelName}`, AgoraRTCToken, userInfo.userId)
            client.on("user-published", handleUserPublished)
            client.on("user-unpublished", handleUserUnpublished)
            if (type != 1) {
                await client.setClientRole('audience', {
                    level: Number(1)
                });

            }



            //收到 token-privilege-will-expire 回调时，从服务器重新申请一个 Token，并调用 renewToken 将新的 Token 传给 SDK。
            client.on("token-privilege-will-expire", async function () {

                try {
                    dispatch(getRoomRtcToken2_fun(roomId, type, definition, (data: any) => {
                        if (data) {
                            // dispatch(setRtmToken(reTokenResult.data.rtmToken))
                            // dispatch(setLiveId(reTokenResult.data.liveId));
                            client.renewToken(data.rtcToken);
                        }

                    }));


                } catch (error) {
                    // setJoined(false)
                    await leave()
                }
            });

        } catch (error: any) {
            message.error(error.message);
            leave();
        }
        // dispatch(closeLoad())
    }
    const subscribe = async (user: any, mediaType: any) => {
        await client.subscribe(user, mediaType)
        if (mediaType === "video") {
        }
        if (mediaType === 'audio') {
            setAudioTrack(user.audioTrack)
            user.audioTrack.play();
        }
    }

    const handleUserPublished = async (user: any, mediaType: any) => {
 
        const id = user.uid
        await subscribe(user, mediaType)
        setRemoteUsers((prev: any) => ({
            ...prev,
            [id]: user
        }))
        if (mediaType == "video") {
            setStatus(1);
            // onGetLiveInfo()
        }
    }
    const handleUserUnpublished = (user: any, mediaType: any) => {
        if (mediaType === 'video') {
            const id = user.uid
            setRemoteUsers((pre: any) => {
                delete pre[id]
                return { ...pre }
            })
            // onRemoveLiveInfo()
        }
    }
    const leave = async () => {
        if (audioTrack) {
            audioTrack.close()
        }
        if (videoTrack) {
            videoTrack.close()
        }
        setRemoteUsers({})
        await client.leave()
    }

    const onSuspend = () => {
        const users = client.remoteUsers;
        if (users && users.length > 0) {
            client.unsubscribe(users[0]);
        }
    }

    const onPay = async () => {
        const users = client.remoteUsers;

        if (users.length > 0) {
            await subscribe(users[0], "audio")
            await subscribe(users[0], "video")
            setRemoteUsers((prev: any) => ({
                ...prev,
                [users[0].uid]: users[0]
            }))
        }
    }
    const onVolumeChange = async (e: number) => {
        if (audioTrack) {
            setViewVolumeValue(e)
        }
    }

    /**
     * 对组件外开放方法
     */
    useImperativeHandle(ref, () => ({
        onLeave: leave
    }))


    return <div >
        {Object.keys(remoteUsers).length ? <> {Object.keys(remoteUsers).map(id => <AgoraVideoPlayer videoTrack={remoteUsers[id]?.videoTrack} key={id}
            onSuspend={onSuspend}
            onPay={onPay}
            viewVolumeValue={viewVolumeValue}
            onVolumeChange={onVolumeChange}
        ></AgoraVideoPlayer>)}</> : <>

            <div className='soga-no-video'>
                <Spin
                    loading={true}
                    color="rgb(117, 122, 121)"

                    aria-label={status == 0 ? "connecting..." : 'Lower Broadcast...'}
                />
                <div className="text">
                    {status == 0 ? "connecting..." : 'Lower Broadcast...'}
                </div>
            </div>

        </>}

        <GiftAnimation />
        <BlindBoxGiftAnimation channelType={ChannelTypeEnum.live} />

    </div>;
}

export default forwardRef(Index)