import './index.scss'
import React, { useRef, useState, useLayoutEffect, forwardRef, useImperativeHandle } from "react";
import Play from './play';
import Volume from './volume';
import Time from './time';

const liveVideoStyle = {
  width: "100%",
  aspectRatio: "16/9"
}

const AgoraVideoPlayer = forwardRef((props:{
    videoTrack:any,
    audioTrack?:any, 
    config?:any
    onSuspend?:()=>void,
    onPay:()=>void,
    viewVolumeValue:number,
    onVolumeChange:(value:number)=>void
}, ref:any) => {

  const [play, setPlay] = useState(true);
  const {
    videoTrack,
    audioTrack, 
    config,
    onSuspend = () => { },
    onPay = () => { },
    viewVolumeValue,
    onVolumeChange = () => { },
  } = props;

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(true);



  useLayoutEffect(() => {
    if (videoRef.current !== null) {
      videoTrack?.play(videoRef.current, config);
    }
    return () => {
      videoTrack?.stop();
    };
  }, [videoTrack]);

  useLayoutEffect(() => {
    audioTrack?.play();
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack]);


  const onPayClick = () => {
    setPlay(true)
    onPay && onPay()
    setTimeout(() => {
      setIsSubscribed(true)
    }, 1000);
  }
  const onStopClick = () => {
    setPlay(false)
    handleCaptureImage();
    onSuspend && onSuspend()
  }
  const handleCaptureImage = () => {
    if (videoTrack && videoTrack.isPlaying) {
      const imageData = videoTrack.getCurrentFrameData();
      const canvas:any = canvasRef.current;
      canvas.width = imageData.width;
      canvas.height = imageData.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      ctx.putImageData(imageData, 0, 0);
      setIsSubscribed(false)
    }
  };

  return <>
    <canvas className='liveVideo' ref={canvasRef} style={{ ...liveVideoStyle, display: !isSubscribed ? 'block' : 'none' }} />
    <video  className='liveVideo' ref={videoRef} style={{ ...liveVideoStyle, display: isSubscribed ? 'block' : 'none' }} />
    <div id="soga_video_tools" className="soga-h5-video-tools show">
      <Play play={play} onPayClick={onPayClick} onStopClick={onStopClick} />
      <Volume value={viewVolumeValue} onChange={(e) => {
          onVolumeChange && onVolumeChange(e)
        }} />
      <Time />
    </div>
  </>;
});

export default AgoraVideoPlayer;
