const getConfig = () => {
    const baseConfig = {
        projectId: '3a600146-3648-4189-a834-e293bf8f069e',
        clientKey: 'cGoGbvj2PcfkFSQNODiU2YHtBS452wuAhveuKaf3',
        appId: 'sgBfGtGbYRSTsVnJvbVWXiUO3PwLpVp0rKwSpcsu',
        signMessage: "Welcome to Soga",
        sellUrl: 'https://soga-2.gitbook.io/q-and-a-of-soga/how-to-list',
        discordUrl: "https://discord.com/invite/yqa229h7tZ",
        twitterUrl: 'https://twitter.com/GametaHippo',
        twitterHomeUrl:'https://twitter.com/',
        youtuBeUrl: "https://youtu.be/6hjDs9vUyDY",
        gametaUrl: "https://www.gameta.pro/",
        elementAccountUrl:"https://www.element.market/account",
        gitbookUrl:"https://soga-2.gitbook.io/q-and-a-of-soga/",
        gametaHomeUrl:'https://www.gameta.pro/#/',


        agoraRTCAppKey:"52d97e2b3cff4b9eb3927cc9b98e8107",
        hashUrl: 'https://testnet.bscscan.com/tx/'
    }

    if (process.env.REACT_APP_ENV === "development") {
        return {
            ...baseConfig,
            chainName:"bsc-testnet",
            baseURL: 'https://pogo.ge81.com/api/v1',
            openBaseURL:'https://pogo.ge81.com/openapi/v1',
            chainIds: [97],
            chains: [ {
                    name: 'BSC',
                    id: 97
                },
            ],
            refreshTokenTime: 1000 * 60 * 10,    //10分钟刷一次token
            websocketServiceUrl:'wss://ws.pogo.ge81.com/websocket',
            twitterCallBackUrl:`http://localhost:3000/box/bnb`,
            discordCallbackUrl:`http://localhost:3000`, 
            imageUrl:'https://pogo.ge81.com/'
        }
    } else if (process.env.REACT_APP_ENV === "test") {
        return {
            ...baseConfig,
            chainName:"bsc-testnet",
            baseURL: 'https://pogo.ge81.com/api/v1',
            openBaseURL:'https://pogo.ge81.com/openapi',
            chainIds: [97],
            chains: [{
                    name: 'BSC',
                    id: 97
                },
            ],
            refreshTokenTime: 1000 * 60 * 10,   //10分钟刷一次token
            websocketServiceUrl:'wss://ws.pogo.ge81.com/websocket',
            twitterCallBackUrl:`https://pogo.ge81.com/`,
            discordCallbackUrl:`https://pogo.ge81.com/`, 
            imageUrl:''
        }
    } else {
        return {
            ...baseConfig,
            chainName:"bsc",
            baseURL: 'https://pogo.ge81.com/api/v1',
            openBaseURL:'https://pogo.ge81.com/openapi',
            chainIds: [56],
            chains: [
                {
                    name: "BSC",
                    id: 56,
                }
            ],
            refreshTokenTime: 1000 * 60 * 60 * 4,      //4个小时刷一次token
            websocketServiceUrl:'wss://ws.pogo.ge81.com/websocket',
            twitterCallBackUrl:`https://pogo.ge81.com/`,
            discordCallbackUrl:`https://pogo.ge81.com/`, 
            imageUrl:''
        }
    }
}

export const config = getConfig();

