import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { getGifts, getBuyGifts, getLiveRanks, getRecords, giveRedPacket, receiveRedPacket, getRedPacket, getBuyTvGifts } from '@/api/gifts';
import { message } from 'antd';
import { listUserNftInfo } from '@/api/login';
import { ChannelTypeEnum, closeLoad, closeRedModal, openLoad, openRewardModal, showFailModal } from './globalClice';
import { getBalance_Func } from './accountClice';
import { setGiftList, setBlindBoxList } from './webSocketClice'
import { v4 } from 'uuid';
import { messageUtil } from '@/utils/tools';



// interface IRedPacket {
//   redPkId?: string,    //红包 ID   可选
//   sender?: number,       //integer  发送人 ID 可选
//   liveId: number,        //integer 可选
//   roomId: number,        //integer 可选
//   asset: string,         //string  可选
//   amount: string,         //string  红包金额 可选
//   quantity: number,      //integer  红包数量 可选
//   received: number,      //integer 已抢的数量 可选
//   createTime: number,    //  integer  可选
//   status: number         //integer
// }
interface GiftCliceState {
  gifts: any[],
  buyGifts: any[],
  liveGifts: any[],
  recordGifts: any[],
  redPacketList: any[]
}
const initialState: GiftCliceState = {
  gifts: [],
  buyGifts: [],
  liveGifts: [],
  recordGifts: [],
  redPacketList: []
}

const GiftClice = createSlice({
  name: 'GiftModal',
  initialState,
  reducers: {
    setGifts(state, { payload }) {
      state.gifts = payload;
    },
    setBuyGifts(state, { payload }) {
      state.buyGifts = payload;
    },
    setLiveGifts(state, { payload }) {
      state.liveGifts = payload;
    },
    setRecordGifts(state, { payload }) {
      state.recordGifts = payload;
    },
    setRedPacketList(state, { payload }) {
      state.redPacketList = payload
    }
  }
});

export const { setGifts, setBuyGifts, setLiveGifts, setRecordGifts, setRedPacketList } = GiftClice.actions;

// 获取礼物列表接口
export const getGifts_Func = (type: ChannelTypeEnum): AppThunk => async (dispatch, getState) => {
  const result: any = await getGifts(type)
  if (result.success) {
    dispatch(setGifts(result.data))
  }
}

/**
 * 赠送礼物接口
 * @param roomId 
 * @param giftId 
 * @param quantity 
 * @param cb 
 * @returns 
 */
export const getBuyGifts_Func = (roomId: any, giftId: number, quantity: number, cb?: (state: boolean) => void,): AppThunk => async (dispatch, getState) => {
  const result: any = await getBuyGifts(roomId, giftId, quantity)
  // 如果success为true则送礼成功 false则是失败 
  if (result.success) {
    dispatch(getBalance_Func())
    dispatch(setBuyGifts(result.data))
  } else {
    if (result.code == "PR008") { 
      messageUtil('no live streaming',"error")
    } else { 
      messageUtil(result.msg,"error")
    }
    //
    cb && cb(false)
  }
}

// 获取礼物前100接口
export const getLiveRanks_Func = (liveId: any): AppThunk => async (dispatch, getState) => {
  const result: any = await getLiveRanks(liveId);
  if (result.success) {
    const liveRanks = result.data;
    if (liveRanks.length == 0) {
      dispatch(setLiveGifts(liveRanks))
      return
    }
    dispatch(setLiveGifts(result.data))
  }
}

// 获取礼物流水
export const getRecord_Func = (liveId: any, pageStart: number = 1): AppThunk => async (dispatch, getState) => {
  const result: any = await getRecords(liveId, pageStart, 1000)
  if (result.success) {
    dispatch(setRecordGifts(result.data))
  }
}
/**
 * 发红包
 * @param roomId 
 * @param amount 
 * @param quantity 
 * @returns 
 */
export const giveRedPacket_Func = (amount?: number, quantity?: number, cb?: Function): AppThunk => async (dispatch, getState) => {
  const rtmToken = getState().webSocketModule.rtmToken
  const result: any = await giveRedPacket(rtmToken, amount, quantity);
  if (result.success) { 
    messageUtil("Red packet sent successfully")
    dispatch(getBalance_Func())
    cb && cb(result.data)
  } else { 
    messageUtil(result.msg,"error")
    cb && cb(false)
  }
}
/**
 * 抢红包
 * @param roomToken 
 * @param rid 
 * @returns 
 */
export const receiveRedPacket_Func = (rid: string, cb?: Function): AppThunk => async (dispatch, getState) => {
  dispatch(closeRedModal());
  dispatch(openLoad());
  try {
    const roomToken = getState().webSocketModule.rtmToken;
    const liveId = getState().roomModule.liveId;
    const redPacketList = getState().giftModal.redPacketList;
    const result: any = await receiveRedPacket(roomToken, rid);

    if (result.success) {
      dispatch(getRedPacket_Func(liveId));
      dispatch(getBalance_Func());
      dispatch(closeLoad());
      dispatch(openRewardModal(result.data))
    } else {
      dispatch(getRedPacket_Func(liveId));
      cb && cb(false);
      dispatch(closeLoad());
      dispatch(showFailModal(`Unfortunately, didn't get it. Try again next time！`))
    }
  } catch (error) {
    dispatch(closeLoad());
  }
}
/**
 * 可抢的红包列表
 * @param liveId 
 * @returns 
 */
export const getRedPacket_Func = (liveId: number): AppThunk => async (dispatch, getState) => {
  const result: any = await getRedPacket(liveId);
  if (result.success) {
    dispatch(setRedPacketList(result.data))
  }
}

/**
 * 
 * @param {*} channelId 
 * @param {*} giftId 
 * @param {*} quantity 
 * @param {*} videoId 
 * @returns 
 */
export const getBuyTvGifts_Func = (giftId: number, quantity: number, obj: any): AppThunk => async (dispatch, getState) => {
  const userInfo = getState().loginModule.userInfo;
  const currentChannel: any = getState().roomModule.currentChannel;
  const gifts = getState().giftModal.gifts;
  const result: any = await getBuyTvGifts(currentChannel.channelId, giftId, quantity, currentChannel.videoId);
  if (result.success) {
    if (obj.type == 4) {  //礼物
      const grouped = [];
      grouped.push([5, [5, userInfo.userId, userInfo.name, 1, giftId, quantity, obj], v4()]);
      dispatch(setGiftList(grouped));
    } else if (obj.type == 6) {  //盲盒
      const grouped = [];
      const groupedItem = [];
      for (let i = 0; i < result.data.length; i++) {
        const newA = [result.data[i].actualGiftId, result.data[i].giftQuantity]
        const gift = gifts.find((item) => {
          return item.giftId == result.data[i].actualGiftId
        })
        newA.push(gift)
        groupedItem.push(newA);

      }
      grouped.push([7, [5, userInfo.userId, userInfo.name, 1, 2, groupedItem], v4()])
      dispatch(setBlindBoxList(grouped));
    }


  } else {
    messageUtil(result.msg,"error")
  }
}

export default GiftClice.reducer;