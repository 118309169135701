import { ChannelTypeEnum } from '@/features/globalClice';
import userHead from '@assets/h5Images/userHead';
import './index.scss';
import { operateFollow_Func } from '@/features/loginClice';
import { useDispatch, useSelector } from 'react-redux';
import { channelsFollow_func, getUserCount_func } from '@/features/roomClice';
import { useEffect, useState } from 'react';
import { RootState } from '@/store';
import peopleNumber from '@assets/h5Images/peopleNumber'

export default function Index({ type, watchCount }: { type: ChannelTypeEnum, watchCount: number }) {

    const dispatch = useDispatch();
    const [channel, setChannel] = useState<any>(null)

    const { roomInfo, followStat, roomId, userCount, channels } = useSelector((state: RootState) => {
        let roomId = 0;
        if (state.globalModule.channelType == ChannelTypeEnum.live) {
            if (state.roomModule.onLineList.length > 0) {
                const roomObj = state.roomModule.onLineList.find((item) => {
                    return item.roomType == 0
                })
                if (roomObj) {
                    roomId = roomObj.roomId
                }
            }
        }


        return {
            roomInfo: state.roomModule.roomInfo,
            followStat: state.loginModule.followStat,
            roomId: roomId,
            userCount: state.roomModule.userCount,
            channels: state.roomModule.channels,
        }
    })
    useEffect(() => {
        if (roomId && type == ChannelTypeEnum.live) {
            dispatch(getUserCount_func(roomId));
        }
    }, [roomId, type]);

    useEffect(() => {
        if (channels && channels.length > 0 && type == ChannelTypeEnum.tv) {
            setChannel(channels[0])
        }
    }, [channels, type])



    if(type==ChannelTypeEnum.live){
        return <div className="live-operate-container">
        <div className="info-container">
            <div className="headImg"><img src={userHead} /></div>
            <div className="name">{roomInfo?.name}</div>
            <div className={`follow ${roomInfo?.followFlag ? 'Following' : ''}`} onClick={() => {
                if (roomInfo?.followFlag) {
                    dispatch(operateFollow_Func('del', roomInfo.userId))
                } else {
                    dispatch(operateFollow_Func('add', roomInfo.userId))
                }

            }}>{roomInfo?.followFlag ? <><label className="soga-a">Following</label><label className="soga-b">Unfollow</label></> : 'Follow'}</div>
        </div>

        <div className="people-number">
            <img src={peopleNumber} /> {userCount >= 1000 ? "999+" : userCount}
        </div>
    </div>
    }else{
        return <div className="live-operate-container">
        <div className="info-container">
            <div className="headImg"><img src={`${channel?.channelsImagesBaseUri}/${channel?.logo}`} /></div>
            <div className="name">{channel?.name}</div>
            <div className='follow' onClick={() => {
                if (channel?.userFollowed) {
                    dispatch(channelsFollow_func('del', channel?.id))
                } else {
                    dispatch(channelsFollow_func('add', channel?.id))
                }

            }}>{channel?.userFollowed ? 'Following' : 'Follow'}</div>
        </div>

        <div className="people-number">
            <img src={peopleNumber} /> {watchCount >= 1000 ? "999+" : watchCount}
        </div>
    </div>
    }
   
}