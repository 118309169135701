import { getRoomRtcToken_fun } from '@/features/loginClice';
import { interval, Subject } from 'rxjs';

export class WebsocketService {
    messageSubject; // subject对象,用于发送事件
    url: any; // 默认请求的url
    connectParams: string[] | undefined;
    webSocket: any; // websocket对象
    connectSuccess = false; // websocket 连接成功
    period = 20 * 1000; // 10s检查一次
    serverTimeoutSubscription: any = null; // 定时检测连接对象
    reconnectFlag = true; // 重连
    reconnectPeriod = 5 * 1000; // 重连失败,则5秒钟重连一次
    reconnectSubscription: any = null; // 重连订阅对象
    runTimeSubscription: any; // 记录运行连接subscription
    runTimePeriod = 60 * 10000;// 记录运行连接时间
    eventTimestamp = new Date().getTime();


    roomId: string = '';
    tokenType: 1 | 2 | 3 = 2;
    constructor() {
        this.messageSubject = new Subject();
        // console.log('开始心跳检测');
        // 进入程序就进行心跳检测,避免出现开始就连接中断,后续不重连
        // this.heartCheckStart(); 
    }

    /**
     * 发送消息
     * @param message 发送消息
     */
    sendMessage(message: any) {
        this.webSocket.send(message);
    }

    /**
     * 创建新连接
     * @param url 要连接的url
     */
    connect(url: string, params: string[] | undefined) {
        if (url) {
            this.url = url;
            this.connectParams = params
        }
        this.calcRunTime();
        // 创建websocket对象

        this.createWebSocket();
    }

    /**
     * 创建连接
     */
    createWebSocket() {
        console.log('createWebSocket：', this.url, this.connectParams);

        // 如果没有建立过连接，才建立连接并且添加时间监听

        this.webSocket = new WebSocket(this.url, this.connectParams);
        // 建立连接成功
        this.webSocket.onopen = () => this.onOpen();
        // 接收到消息
        this.webSocket.onmessage = (e: any) => this.onMessage(e);
        // 连接关闭
        this.webSocket.onclose = (e: any) => this.onClose(e);
        // 异常
        this.webSocket.onerror = (e: any) => this.onError(e);
    }

    /**
     * 连接打开
     * @param e 打开事件
     */
    onOpen() {
        console.log('websocket 已连接');
        // 设置连接成功
        this.connectSuccess = true;
        // 如果是重连中 
        if (this.reconnectFlag) {
            // 1.停止重连
            this.stopReconnect();
            // 2.重新开启心跳
            this.heartCheckStart();
            // 3.重新开始计算运行时间
            console.log('重新开始计算运行时间')
            this.calcRunTime();
        }
    }

    /**
     * 接受到消息
     * @param event 接受消息事件
     */
    onMessage(event: any) {
        // console.log('接收到的消息', event.data);
        // 将接受到的消息发布出去
        this.eventTimestamp = new Date().getTime();
        const message = event.data;
        // console.log('接收到消息时间', new Date().getTime());
        this.messageSubject.next(message);
    }

    /**
     * 连接关闭
     */
    onClose(e: any) {
        console.log('连接关闭：', this.url, e);
        this.connectSuccess = false;
        this.webSocket && this.webSocket.close();
        // 关闭时开始重连
        // this.reconnect();
        this.stopRunTime();
        if (!this.connectSuccess && !e) {
            this.reconnectFlag = true;

            this.heartCheckStop()
        }
        // throw new Error('webSocket connection closed:)');
    }

    /**
     * 连接异常
     */
    onError(e: any) {
        // 出现异常时一定会进onClose,所以只在onClose做一次重连动作
        console.log('连接异常：', this.url, e);
        this.connectSuccess = false;
        this.reconnect();
        // throw new Error('webSocket connection error:)');
    }

    /**
     * 开始重新连接
     */
    async reconnect() {
        // 如果已重连,则直接return,避免重复连接
        if (this.connectSuccess) {
            this.stopReconnect();
            console.log('已经连接成功,停止重连：', this.url);
            return;
        }
        // 如果正在连接中,则直接return,避免产生多个轮训事件
        if (this.reconnectFlag) {
            console.log('正在重连,直接返回：', this.url);
            return;
        }

        if (this.roomId == '') {
            console.log('房间号为空：', this.url);
            return;
        }
        const result: any = await getRoomRtcToken_fun(this.roomId, this.tokenType);
        this.connectParams = [result.data.rtmToken, this.roomId]
        // 开始重连
        this.reconnectFlag = true;
        // 如果没能成功连接,则定时重连
        this.reconnectSubscription = interval(this.reconnectPeriod).subscribe(async (val) => {
            console.log(`重连:${val}次`);
            const url = this.url;
            const connectParams = this.connectParams;
            // 重新连接
            this.connect(url, connectParams);
        });
    }

    /**
     * 停止重连
     */
    stopReconnect() {
        // 连接标识置为false
        this.reconnectFlag = false;
        // 取消订阅
        if (typeof this.reconnectSubscription !== 'undefined' && this.reconnectSubscription != null) {
            this.reconnectSubscription.unsubscribe();
        }
    }

    /**
     * 开始心跳检测
     */
    heartCheckStart() {
        this.serverTimeoutSubscription = interval(this.period).subscribe((val) => {
            // 保持连接状态,重置下
            const curTime = new Date().getTime();
            // console.log(curTime - this.eventTimestamp <= this.period,curTime,this.eventTimestamp,curTime - this.eventTimestamp,this.period)
            if (
                this.webSocket != null &&
                this.webSocket.readyState === 1
            ) {
                console.log(val, '连接状态，发送消息保持连接:', this.url, new Date());
                this.eventTimestamp = new Date().getTime();
                this.sendMessage(`hb:124`);
            } else {
                this.eventTimestamp = new Date().getTime();
                console.log('连接已断开,重新连接：', this.url);
                // 停止心跳
                this.heartCheckStop();
                // 开始重连
                this.reconnect();
            }
        });
    }

    /**
     * 停止心跳检测
     */
    heartCheckStop() {
        // 取消订阅停止心跳
        if (
            typeof this.serverTimeoutSubscription !== 'undefined' &&
            this.serverTimeoutSubscription != null
        ) {
            this.serverTimeoutSubscription.unsubscribe();
        }
    }

    /**
     * 开始计算运行时间
     */
    calcRunTime() {
        this.runTimeSubscription = interval(this.runTimePeriod).subscribe((period) => {
            console.log('运行时间', `${period}分钟`);
        });
    }

    /**
     * 停止计算运行时间
     */
    stopRunTime() {
        if (typeof this.runTimeSubscription !== 'undefined' && this.runTimeSubscription !== null) {
            this.runTimeSubscription.unsubscribe();
        }
    }
}
//```

// 使用

// import { WebsocketService as wsSrv } from '/@/utils/websocket';

//  const protocol = window.location.protocol.replace('http', 'ws');
//     const host = window.location.host;
//     const wsService = new wsSrv();
//     wsService.connect(`${protocol}//${host}/wsstone/systemStatus`);
//     wsService.messageSubject.subscribe(
//       (response: any) => {
//         const data = response;
//       },
//       () => {},
//       () => {},
//     );
