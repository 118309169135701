import React, { useEffect, useState } from 'react';
import volumeIcon, { muteVolumeIcon } from '@assets/h5Images/volume';
import { Popover, Slider, Tooltip } from 'antd';
const style = {
    display: 'inline-block',
    height: 100,
};
let saveVolumeValue = 100
export default function Index({ onChange, value }: { onChange: (value: number) => void, value: number }) {
    const [isMute, setIsMute] = useState<boolean>(false)
    // const dispatch = useDispatch();

    // const content = () => {
    //     return <div className="volumeSliderContent">
    //         <div style={{
    //             textAlign: 'center'
    //         }}>
    //             {value}
    //         </div>
    //         <Slider value={value} min={0} max={100} style={style} vertical defaultValue={100} onChange={(e) => {
    //             onChange && onChange(e);
    //             setIsMute(false);
    //         }} />
    //     </div>
    // }

    useEffect(() => { 
        if (value == 0) {
            setIsMute(true);
        } else if (isMute) {
            setIsMute(false);
            saveVolumeValue = value
        }
    }, [value])
    return <><div className="tools-icons" style={{marginRight:"5px"}}>
        <img className='volume-img' src={isMute ? muteVolumeIcon : volumeIcon} onClick={() => {
             onChange(saveVolumeValue);
            // setIsMute(!isMute)
        }} />



        {/* <Popover placement="top" overlayClassName='volumeSlider' getPopupContainer={() => document.getElementById("soga_video_tools") || document.body} content={content()} arrowContent={false}>
           
        </Popover> */}
    </div >
        <div className="tools-icons"> <Slider
        style={{
            width:'50px'
        }}
        value={value}
        max={100}
        onAfterChange={(e) => {
            onChange && onChange(e);
            setIsMute(false);
        }} /></div>

    </>
}