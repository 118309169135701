import { RootState } from "@/store";
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
let index = 0;
export default function Index({ originTime, onCallBack }: { originTime: number, onCallBack: (time: number) => void }) {
    const [liveTimeLong, setLiveTimeLong] = useState<number>(1000);
    const [liveTimeLongText, setLiveTimeLongText] = useState('00:00:00');


    const { watchStop } = useSelector((state: RootState) => {
        return {
            watchStop: state.globalModule.watchStop
        }
    })

    useEffect(() => {
        setLiveTimeLong(originTime * 1000)
    }, [originTime])
    useEffect(() => {
        let timeout: any;
        if (liveTimeLong > (60 * 60 * 1000)) {
            setLiveTimeLongText("01:00:00");
            if (originTime < (60 * 60)) {
                setTimeout(() => {
                    onCallBack && onCallBack(liveTimeLong)
                }, 15000)
            }
        } else if (!watchStop) {
            timeout = setTimeout(() => {
                const timeLong = liveTimeLong + 1000;
                setLiveTimeLong(timeLong);
                const timeText = formatTime(timeLong)
                setLiveTimeLongText(timeText);
                index = index + 1;
                if (index == 10) {
                    onCallBack && onCallBack(liveTimeLong)
                    index = 0;
                }
            }, 1000)
        } else {
            timeout && clearTimeout(timeout)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [liveTimeLong, watchStop])

    return <label className='longtime'>{liveTimeLongText}</label>
}

function formatTime(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let formattedTime = '';

    // if (hours > 0) {
    formattedTime += `${hours.toString().padStart(2, '0')}:`;
    // }

    // if (hours > 0 || minutes > 0) {
    formattedTime += `${minutes.toString().padStart(2, '0')}:`;
    // }

    formattedTime += seconds.toString().padStart(2, '0');

    return formattedTime;
}