import React, { useEffect, useState } from 'react';
import './index.scss';
import time_icon from '@assets/h5Images/time';
import Progress from '../progress/timeProgress';
import { beginView_func, endView_func, getTask_10009_func } from '@features/roomClice';
import { useDispatch,useSelector } from 'react-redux';
import { encrypt, Encrypt } from '@utils/aes'
import Time from './liveTime';
import {listAwardsRecord_Func} from '@features/taskClice';
import { RootState } from '@/store';



export default function Index({ videoId }:{videoId:number}) {

    const [task, setTask] = useState<any>(null);
    const [token, setToken] = useState<string|null>(null);

    const [time, setTime] = useState(0);
    const [progressTime, setProgressTime] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(beginView_func(videoId, (token:any) => {
            setToken(token);
        }))
    }, [])
    const {watchStop}=useSelector((state:RootState)=>{
        return {
            watchStop:state.globalModule.watchStop
        }
    })
    useEffect(() => {
        dispatch(getTask_10009_func((task) => {
            setTime(task.data.videoTime);
            setProgressTime(task.data.videoTime);
            setTask(task);
        }))
    }, [])

    useEffect(()=>{
        if(task){
            dispatch(listAwardsRecord_Func(task.task.id,task.data.taskRecordId))
        }
    },[task?.task?.id]) 
    
    if (task) {
        return <div className="watched-container">
            <div className="left">
                <div className="title"><img src={time_icon} /> Time watched</div>
                <div className="time"><Time originTime={time} onCallBack={(liveTimeLong) => {
                    const obj = JSON.stringify({
                        "videoId": videoId,
                        "token": token
                    })
                    const aesToken = encrypt(obj, "8ad3fac6c6b3528499d347d924443abb");
                    dispatch(endView_func(videoId, aesToken, (token, task) => {
                        setToken(token);
                        setTask(task);
                    }))
                    setProgressTime(liveTimeLong / 1000);
                }} /></div>
            </div>
            <div className="right">
                <Progress percent={progressTime / 60} task={task.task} records={task.records} />
            </div>
        </div>
    } else {
        return <></>
    }
}