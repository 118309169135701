import React, { useEffect, useState } from 'react';
import './blindBox.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { config } from '@/config';
import { removeBlindBoxList, setBlindBoxList } from '@/features/webSocketClice';
import blindBoxGif from '@assets/svgs/blindBoxGif.gif'
import { ChannelTypeEnum } from '@/features/globalClice';

function GiftEffect({channelType}:{channelType:ChannelTypeEnum}) {
    const dispatch = useDispatch();

    const handleAnimationEnd = (uuid: string) => {
        // setShowEffect(false);
        dispatch(removeBlindBoxList(uuid))
    };


    const { blindBoxList } = useSelector((state: RootState) => {
        return {
            blindBoxList: state.webSocketModule.blindBoxList
        }
    })
    return (
        <div className="gift-effect-container">
            {
                blindBoxList.map((item) => {
                    return <div key={item[2]} className="gift-effect" onAnimationEnd={() => {
                        handleAnimationEnd(item[2])
                    }}>
                        <div className="gift-effect-container">
                            <div className="blindBoxGifts">
                                {item[1][5].map((gift: any, index: number) => { 
                                    if (index == 0 && channelType == ChannelTypeEnum.live) {  
                                        return undefined
                                    }
                                    return <div key={index}>
                                        <img src={`${gift[2].imgBaseUri}${gift[2].icon}`} /> <label className='gift-number'>x {gift[1]}</label>
                                    </div>
                                })}
                            </div>
                            <img className="blindBox_img" src={`/img/blindBoxGif.gif?m=${item[2]}`} ></img>
                        </div>
                    </div>
                })
            }
        </div>
    );
}

export default GiftEffect;