import { config } from '@/config'
import { axios } from '@utils/request'
import Qs from 'qs'


interface IRegister {
    wallet: string,
    signature: string,
    code?: string,
}
/**
 * 登入/注册
 * @param d 
 * @returns 
 */
export function register(d: IRegister) {
    return axios({
        url: `/user:register`,
        method: 'post',
        data: Qs.stringify(d),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 刷新 token
 * @returns 
 */
export function refreshToken() {
    return axios({
        url: `/user:refreshToken`,
        method: 'post'
    })
}

/**
 * 获取详细信息
 * @returns 
 */
export function getUserInfo() {
    return axios({
        url: `/user:info`,
        method: 'get',
    })
}
/**
 * 修改用户信息
 * @param name  名称 
 * @param introduction   简介
 * @returns 
 */
export function updateUser(name: string, introduction: string) {
    return axios({
        url: '/user:update',
        method: 'post',
        data: Qs.stringify({
            name,
            introduction
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 我的关注列表
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export function getFollowList(pageStart: number = 1, pageSize?: number) {
    return axios({
        url: `/follow:list`,
        method: 'post',
        // data: Qs.stringify({
        //     pageStart,
        //     pageSize: pageSize || 10,
        //     cond: {}
        // }),
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded'
        // }
        data: {
            pageStart,
            pageSize: pageSize || 10,
            cond: {}
        }
    })
}
/**
 * 关注用户
 * @param followedUserId 
 * @returns 
 */
export function addFollow(followedUserId: number) {
    return axios({
        url: `/follow:add`,
        method: 'post',
        data: Qs.stringify({
            followedUserId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 取消关注
 * @param followedUserId 
 * @returns 
 */
export function delFollow(followedUserId: number) {
    return axios({
        url: `/follow:del`,
        method: 'post',
        data: Qs.stringify({
            followedUserId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 我的粉丝列表
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export function getFansList(pageStart: number = 1, pageSize?: number) {
    return axios({
        url: `/fans:list`,
        method: 'post',
        data: {
            pageStart,
            pageSize: pageSize || 10,
            cond: {}
        }
    })
}

/**
 *  获取用户信息列表
 * @returns 
 */
export function listUserNftInfo(userId: number[]) {
    return axios({
        url: `/user:listUserNftInfo`,
        method: 'post',
        data: userId
    })
}

/**
 *  获取关注跟粉丝数量
 * @returns 
 */
export function followfans() {
    return axios({
        url: `/followfans:count`,
        method: 'get'
    })
}
export function getAuthorize(responseType: string, state: string, redirectUri: string, clientId: string, scope: string) {
    return axios({
        url: `${config.openBaseURL}/oauth2/authorize`,
        method: 'get',
        params: {
            responseType,
            state,
            redirectUri,
            clientId,
            scope
        }
    })
}

export function postAuthorize(responseType: string, state: string, redirectUri: string, clientId: string, scope: string) {
    return axios({
        url: `${config.openBaseURL}/oauth2/authorize`,
        method: 'post',
        data: Qs.stringify({
            responseType,
            state,
            redirectUri,
            clientId,
            scope
        })
    })
}

/**
 * 邀请码信息接口
 * @returns 
 */
export function getInviteInfo() { 
    return axios({
        url: `/user:inviteInfo`,
        method: 'get'
    })
  }
  /**
   * 绑定邀请码
   * @param {*} code 
   * @returns 
   */
  export function bindInviteCode(code:string) {
    return axios({
        url: `/user:bindInviteCode`,
        method: 'post',
        data: Qs.stringify({
            code
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
     
  }