
import React, { useState, useEffect } from 'react';
import { Tooltip, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import backIcon from '@assets/h5Images/backIcon';
import cedis from '@assets/h5Images/cedis'
import faq from '@assets/h5Images/faq';
import Progress from '@H5Components/progress/progress';
// import { setInviterModal } from '@features/globalClice'
import { RootState } from '@/store';
import './index.scss'
import { getInviteInfo_Func } from '@/features/loginClice';
import { useNavigate } from 'react-router-dom';
import { setInviterModal } from '@/features/globalClice';

export default function Index() {

    const dispatch = useDispatch(); 
    const navigate = useNavigate()
    useEffect(()=>{
        dispatch(getInviteInfo_Func());
    },[])

    
    const { inviteInfo } = useSelector((state:RootState) => {
        return { 
            inviteInfo: state.loginModule.inviteInfo
        }
    })
 
    return <div className="h5-soga-quest-container">
        <div className="quest-info-head" >
            <img src={backIcon} onClick={() => {
                navigate("/questlist")
            }} />Inviting Quest
        </div>

        <div className="inviting-quest-title">
            Currently <label>35</label> people have been invited,
            and the effective number is <label>25</label>
            <Tooltip title="A valid invitation is when the invitee completes the newbie task">
                <img src={faq} />
            </Tooltip>

        </div>

        <div className="inviting-quest-Cumulative">
            <div className='title'>Cumulative gain</div>
            <div className="cedis">
                <img src={cedis} />
                <div className='Number'>x35</div>
            </div>
        </div>

        <div className="inviting-quest-reward-plan">
            <div className='card-tip'>
                Reward1
            </div>
            <div className="reward-title">
                Received upon successful invitation,<br />
                up to 100 people
            </div>

            <div className="inviting-quest-Cumulative">
                <div className='title'>Each person invited gets</div>
                <div className="cedis">
                    <img src={cedis} />
                    <div className='Number' style={{ right: '-25px' }}>x1</div>
                </div>
            </div>
        </div>
        <div className="inviting-quest-reward-plan">
            <div className='card-tip'>
                Reward2
            </div>
            <div className="reward-title">
                The more invitations you have, <br />
                the more extra rewards you will receive
            </div>
            <Progress percent={inviteInfo ? inviteInfo.effectInviteCount : 0} />
        </div>

        <div className="my-info">
            <div className="info-row">
                <div className="title">My invitation code</div>
                <div className="value">{inviteInfo ? inviteInfo.code : '--'}</div>
            </div>

            <div className="info-row">
                <div className="title">My invitation link</div>
                <div className="value link-card">
                    <div className="link">{inviteInfo ? inviteInfo.inviteUrl : '--'}</div>
                    <div className='btn' onClick={() => {
                        navigator.clipboard.writeText(inviteInfo ? inviteInfo.inviteUrl : '--');
                        message.success('Copied to clipboard');
                    }}>copy</div>
                </div>
            </div>

            <div className="info-row">
                <div className="title">My inviter</div>
                <div className="value link-card">
                    {
                        inviteInfo?.inviteCode ? inviteInfo?.inviteCode : <>  - <div className='btn' onClick={() => {
                            dispatch(setInviterModal(true))
                        }}>Fill in the inviter</div></>
                    }
                  

                </div>
            </div>
        </div>
    </div>
}