import { config } from '@/config';
import { Menu, message } from 'antd';
import { AppThunk } from '@/store';
import {
    addBlackList, addRoomHost, addSilenceList, delBlackList, delRoomHost, delSilenceList, downBroadcast, getBlackList, getOnLineList, getRoomImg, getRoomList, getSilenceList, getUserCount,
    getUserRooms, getliveInfo, listRoomHost, upBroadcast, updateRoomName, uploadImg, getChannels,
    channelsFollow, channelsUnFollow, videosList, getComments, pushComments, beginView, endView

} from '@/api/room';
import { createSlice } from '@reduxjs/toolkit';
import { showFailModal, showSuccessModal } from './globalClice';
import { listUserNftInfo } from '@/api/login';
import { setChannelName, setRtcToken, setRtmToken } from './webSocketClice';
import { setRedPacketList } from './giftClice';
import { v4 } from 'uuid';
import { getUserTasks } from '@/api/task';
import { messageUtil } from '@/utils/tools';

export enum SilenceType {
    All = 1,    // 全部禁言
    Sige = 2,   //个人禁言
    Send = 3,   //可以发送信息
}
export enum UserTypeEnum {
    host = 1,
    vipAudience = 2,
    audience = 3,
    administrators = 4,
}
interface RoomCliceState {
    roomList: any[],      //直播列表
    blackList: any[],     //黑名单
    userRooms: any[],    //开播列表
    silenceList: any[],   //禁言名单
    roomHostList: any[],     //主播列表,
    roomManageList: any[],     //房管列表,
    onLineList: any[],      //正在直播的房间列表,
    roomImg: any,       //房间图片
    silenceType: SilenceType,
    userType: UserTypeEnum,
    sigeSilence: boolean,
    userCount: number,     //在线用户人数
    roomInfo: any,       //直播间直播信息
    liveId: number,
    roomName: string,    //直播间名称
    roomType: number,  //房间类型
    encoderConfig: {
        level: number,
        definitions: EncoderConfigEnum,
        flag: boolean
    }[],
    channels: any[],
    videos: any[],
    comments: any[],
    orginMessageList: any[],
    currentChannel?: {
        channelId: number,
        videoId: number
    }
}

const initialState: RoomCliceState = {
    blackList: [],
    roomList: [],
    userRooms: [],
    silenceList: [],
    roomHostList: [],
    onLineList: [],
    roomImg: '',
    silenceType: SilenceType.Send,
    userType: UserTypeEnum.audience,
    sigeSilence: false,
    roomManageList: [],
    userCount: 0,
    roomInfo: null,
    liveId: 0,
    roomName: '',
    encoderConfig: [],
    roomType: -1,
    channels: [],
    videos: [],
    comments: [],
    orginMessageList: [],
    currentChannel: undefined
}

const RoomClice = createSlice({
    name: 'RoomModule',
    initialState,
    reducers: {
        setRoomList(state, { payload }) {
            state.roomList = payload
        },
        setUserRooms(state, { payload }) {
            state.userRooms = payload
        },
        setBlackList(state, { payload }) {
            state.blackList = payload
        },
        setSilenceList(state, { payload }) {
            state.silenceList = payload
        },
        setRoomHostList(state, { payload }) {
            state.roomHostList = payload
        },
        setOnLineList(state, { payload }) {
            state.onLineList = payload
        },
        setRoomImg(state, { payload }) {
            state.roomImg = payload
        },
        setSilenceType(state, { payload }) {
            state.silenceType = payload
        },
        setUserType(state, { payload }) {
            state.userType = payload
        },
        setRoomManageList(state, { payload }) {
            state.roomManageList = payload
        },
        setSigeSilence(state, { payload }) {
            state.sigeSilence = payload
        },
        setUserCount(state, { payload }) {
            state.userCount = payload
        },
        setRoomInfo(state, { payload }) {
            state.roomInfo = payload
        },
        setLiveId(state, { payload }) {
            state.liveId = payload
        },
        setRoomName(state, { payload }) {
            state.roomName = payload
        },
        setEncoderConfig(state, { payload }) {
            state.encoderConfig = payload
        },
        setRoomType(state, { payload }) {
            state.roomType = payload;
        },
        setChannels(state, { payload }) {
            state.channels = payload
        },
        setVideos(state, { payload }) {
            state.videos = payload
        },
        setComments(state, { payload }) {
            state.comments = payload
        },
        setCurrentChannel(state, { payload }) {
            state.currentChannel = payload
        },
        setOrginMessageList(state, { payload }) {
            const allMessage = state.orginMessageList.concat([payload]);
            if (allMessage.length > 200) {
                const newMessage = allMessage.slice(allMessage.length - 200, allMessage.length);
                state.orginMessageList = newMessage
            } else {
                state.orginMessageList = allMessage
            }
        },
        clearOrginMessageList(state, { payload }) {
            state.orginMessageList = []
        },
    },
});

export const { setRoomList, setUserRooms, setBlackList, setSilenceList, setRoomHostList, setOnLineList, setRoomImg,
    setSilenceType, setUserType, setRoomManageList, setSigeSilence, setUserCount, setRoomInfo, setLiveId, setRoomName,
    setEncoderConfig, setRoomType, setChannels, setVideos, setComments, setCurrentChannel, setOrginMessageList, clearOrginMessageList } = RoomClice.actions

export enum EncoderConfigEnum {
    p720 = '720p',
    p1080 = '1080p'
}


export const setTokenInfo_Func = (result: any): AppThunk => async (dispatch, getState) => {
    const userInfo = getState().loginModule.userInfo;
    dispatch(setRtcToken(result.data.rtcToken));
    dispatch(setRtmToken(result.data.rtmToken));
    dispatch(setRoomName(result.data.roomName));
    dispatch(setChannelName(result.data.channelName));
    dispatch(setEncoderConfig(result.data.videos))
    dispatch(setRoomType(result.data.roomType))
    const silence = result.data.silence;
    // if (result.data.userType == UserTypeEnum.audience || result.data.userType == UserTypeEnum.vipAudience || (result.data.userType == UserTypeEnum.host)) {

    // } 

    if (silence.includes(userInfo.userId) && silence.includes(0)) {
        dispatch(setSigeSilence(true));
        dispatch(setSilenceType(SilenceType.All))
    } else if (!silence.includes(userInfo.userId) && silence.includes(0)) {
        dispatch(setSilenceType(SilenceType.All))
    } else if (silence.includes(userInfo.userId) && !silence.includes(0)) {
        dispatch(setSigeSilence(true));
        dispatch(setSilenceType(SilenceType.Sige))
    } else {
        dispatch(setSigeSilence(false));
        dispatch(setSilenceType(SilenceType.Send))
    }
    dispatch(setLiveId(result.data.liveId))
    dispatch(setUserType(result.data.userType))
}


/**
 * 获取直播间列表
 * @param roomId 
 * @param pageStart 
 * @returns 
 */
export const getRoomList_Func = (roomId: number | undefined, pageStart: number = 1): AppThunk => async (dispatch, getState) => {
    const result: any = await getRoomList(roomId, pageStart);
    if (result.success) {
        dispatch(setRoomList(result.data.list))
    }
}

/**
 * 获取开播房间列表
 * @returns 
 */
export const getUserRooms_Func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await getUserRooms();
    if (result.success) {
        dispatch(setUserRooms(result.data))
    }
}
/**
 * 获取黑名单
 * @param roomId 
 * @returns 
 */
export const getBlackList_Func = (roomId: number, pageStart: number): AppThunk => async (dispatch, getState) => {
    const result: any = await getBlackList(roomId, pageStart, 1000);
    if (result.success) {

        if (result.data.list.length === 0) {
            dispatch(setBlackList([]))
            return
        }
        const userIds = result.data.list.map((item: any) => {
            return item.userId
        })


        const users: any = await listUserNftInfo(userIds);
        if (users.success) {
            const newList = result.data.list.map((item: any) => {
                const user = users.data.find((e: any) => {
                    return e.userId == item.userId
                })
                return {
                    ...item,
                    ...user
                }
            })
            dispatch(setBlackList(newList))
        } else {
            dispatch(setBlackList(result.data.list))
        }
    }
}
/**
 * 添加黑名单
 * @param roomId 
 * @param blackUserId 
 * @returns 
 */
export const addBlackList_Func = (roomId: number, blackUserId: number, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await addBlackList(roomId, blackUserId);
    if (result.success) {
        dispatch(getBlackList_Func(roomId, 1))
        messageUtil("Operation successful")
        cb && cb()
    } else if (result.code === "PR024") {
        messageUtil("Unable to operate the property management and anchor", "error")
    }
}
/**
 * 移除黑名单
 * @param roomId 
 * @param blackUserId 
 * @returns 
 */
export const delBlackList_Func = (roomId: number, blackUserId: number, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await delBlackList(roomId, blackUserId);
    if (result.success) {
        messageUtil("Operation successful")
        dispatch(getBlackList_Func(roomId, 1))
        cb && cb()
    }
}

/**
 * 禁言名单列表
 * @param roomId 
 * @param pageStart 
 * @returns 
 */
export const getSilenceList_Func = (roomId: number, pageStart: number): AppThunk => async (dispatch, getState) => {
    // const result: any = await getBlackList(roomId, pageStart, 1000);
    // if (result.success) {
    //     dispatch(setSilenceList(result.data.list))
    // }

    const result: any = await getSilenceList(roomId, pageStart, 1000);
    if (result.success) {
        if (result.data.list.length === 0) {
            dispatch(setSilenceList([]))
            return
        }
        const userIds = result.data.list.filter((item: any) => {
            return item.userId != 0
        }).map((item: any) => {
            return item.userId
        })
        if (userIds.length == 0) {
            dispatch(setSilenceList(result.data.list))
            return
        }
        const users: any = await listUserNftInfo(userIds);
        if (users.success) {
            const newList = result.data.list.map((item: any) => {
                const user = users.data.find((e: any) => {
                    return e.userId == item.userId
                })
                return {
                    ...item,
                    ...user
                }
            })
            dispatch(setSilenceList(newList))
        } else {
            dispatch(setSilenceList(result.data.list))
        }
    }
}

/**
 * 添加禁言人员
 * @param roomId 
 * @param silenceUserId 
 * @param timeLong 
 * @param type 
 * @param cb 
 * @returns 
 */
export const addSilenceList_Func = (roomId: number, silenceUserId?: number, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await addSilenceList(roomId, silenceUserId);
    if (result.success) {
        dispatch(getSilenceList_Func(roomId, 1))
        silenceUserId == 0 && dispatch(setSilenceType(SilenceType.All))
        messageUtil("Operation successful")
        cb && cb()
    } else if (result.code === "PR024") {
        messageUtil("Unable to operate the property management and anchor", "error")
    }
}
/**
 * 解除禁言人员
 * @param roomId 
 * @param silenceUserId 
 * @param cb 
 * @returns 
 */
export const delSilenceList_Func = (roomId: number, silenceUserId: number, cb?: Function): AppThunk => async (dispatch, getState) => {
    const silenceType = getState().roomModule.silenceType
    const result: any = await delSilenceList(roomId, silenceUserId);
    if (result.success) {
        dispatch(getSilenceList_Func(roomId, 1))
        if (silenceUserId == 0) {
            dispatch(setSilenceType(SilenceType.Send));
        }
        // if (silenceType != SilenceType.All) {
        //     dispatch(setSilenceType(SilenceType.Send)); 
        // }
        messageUtil("Operation successful")
        cb && cb()
    }
}


/**
 * 主播列表
 * @param roomId 
 * @param type   1主播    2房管
 * @param cb 
 * @returns 
 */
export const listRoomHost_Func = (roomId: string, type: 1 | 2, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await listRoomHost(roomId, type);
    if (result.success) {
        if (type == 1) {
            dispatch(setRoomHostList(result.data));
        } else {
            dispatch(setRoomManageList(result.data))
        }

        cb && cb()
    }
}

/**
 * 添加授权主播
 * @param userHostWallet 
 * @param roomId 
 * @param type   1主播    2房管 
 * @param cb 
 * @returns 
 */
export const addRoomHost_Func = (userHostWallet: string, roomId: string, type: 1 | 2, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await addRoomHost(userHostWallet, roomId, type);
    if (result.success) {
        dispatch(showSuccessModal("Successful operation"))
        dispatch(listRoomHost_Func(roomId, type))
        cb && cb()
    } else {
        if (result.code == "PR007") {
            dispatch(showFailModal("anchor already exists"))
        } else if (result.code === 'PR014') {
            // dispatch(showFailModal("operation failed"))
            dispatch(showFailModal("Too many room hosts"))
        } else if (result.code === 'PR019') {
            dispatch(showFailModal("user does not exist"))
        } else {
            dispatch(showFailModal("operation failed"))
        }

    }
}
/**
 * 删除授权主播
 * @param userHostWallet 
 * @param roomId 
 * @param type   1主播    2房管 
 * @param contractAddress 
 * @param cb 
 * @returns 
 */
export const delRoomHost_Func = (userHostWallet: string, roomId: string, type: 1 | 2, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await delRoomHost(userHostWallet, roomId, type);
    if (result.success) {
        dispatch(showSuccessModal("Successful operation"))
        dispatch(listRoomHost_Func(roomId, type))
        cb && cb()
    } else {
        dispatch(showFailModal("operation failed"))
    }
}

/**
 * 图片上传
 * @param roomId 
 * @param file 
 */
export const uploadImg_Func = async (roomId: any, file: any) => {
    const result: any = await uploadImg(roomId, file);
}
/**
 * 获取正在直播的列表
 * @returns 
 */
export const getOnLineList_Func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await getOnLineList();
    if (result.success) {
        dispatch(setOnLineList(result.data));
        if (result.data.length > 0) {
            dispatch(getRoomImg_Func(result.data[0].roomId))
        } else {
            dispatch(setRoomImg(''))
        }
    }
}
/**
 * 房间图片
 * @param roomId 
 * @returns 
 */
export const getRoomImg_Func = (roomId: string): AppThunk => async (dispatch, getState) => {
    const result: any = await getRoomImg(roomId)
    if (result.success) {
        dispatch(setRoomImg(result.data))
    }
}

/**
 * 在线用户人数
 * @param roomId 
 * @returns 
 */
export const getUserCount_func = (roomId: number): AppThunk => async (dispatch, getState) => {
    const result: any = await getUserCount(roomId)
    if (result.success) {
        dispatch(setUserCount(result.data))
    }
}
/**
 * 获取直播间的详细信息
 * @param liveId 
 * @returns 
 */
export const getliveInfo_Func = (liveId: number): AppThunk => async (dispatch, getState) => {
    const result: any = await getliveInfo(liveId);
    if (result.success) {
        dispatch(setRoomInfo(result.data))
    }
}

/**
 * 上播
 * @param roomId 
 * @returns 
 */
export const upBroadcast_Func = (roomId: number, reType: 0 | 1 | 2, cb?: (result: boolean) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await upBroadcast(roomId, reType);
    if (result.success) {
        // dispatch(getliveInfo_Func(result.data))
        // dispatch(setLiveId(result.data))
        cb && cb(true)
    } else {
        cb && cb(false)
    }
}


/**
 * 上播
 * @param roomId 
 * @returns 
 */
export const downBroadcast_Func = (roomId: number, cb?: (result: boolean) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await downBroadcast(roomId);
    if (result.success) {
        dispatch(setLiveId(0));
        dispatch(setRoomInfo(null))
        dispatch(setRedPacketList([]))
        cb && cb(true)
    } else {
        cb && cb(false)
    }
}
/**
 * 修改房间名字
 * @param roomId 
 * @param roomName 
 * @param cb 
 * @returns 
 */
export const updateRoomName_Func = (roomId: number, roomName: string, cb?: (result: boolean) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await updateRoomName(roomId, roomName);
    if (result.success) {
        dispatch(setRoomName(roomName))
        cb && cb(true)
    } else {
        cb && cb(false)
    }
}




/**
 * 获取token异常信息处理 提示
 */
export const tokenResultError_Func = (reTokenResult: any): AppThunk => async (dispatch, getState) => {
    if (reTokenResult.code == 'PR009') {
        dispatch(showFailModal('Someone is already broadcasting in the live broadcast room'));
    } else if (reTokenResult.code == "PR006") {
        dispatch(showFailModal('no permission'));
    } else if (reTokenResult.code == "PR021") {
        dispatch(showFailModal('No VIP NFT'));
    } else if (reTokenResult.code == "PR018") {
        dispatch(showFailModal('no permission'));
    } else if (reTokenResult.code != "PR011") {
        dispatch(showFailModal(reTokenResult.msg));
    }
}



/**
 * 频道列表
 * @returns 
 */
export const getChannels_func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await getChannels();
    if (result.success) {
        dispatch(setChannels(result.data));
    }
}
/**
 * 频道关注
 * @param {*} channelId 
 * @returns 
 */
export const channelsFollow_func = (operate: "add" | "del", channelId: number, cb?: () => void): AppThunk => async (dispatch, getState) => {


    if (operate == 'add') {
        const result: any = await channelsFollow(channelId);
        if (result.success) {
            // dispatch(getFollowList_Func(1)); 
            messageUtil("Follow Success")
            dispatch(getChannels_func())
        } else {
            messageUtil(result.msg, "error")
        }
    } else {
        const result: any = await channelsUnFollow(channelId);
        if (result.success) {
            dispatch(getChannels_func())
            messageUtil('Cancel Follow Successfully')
            cb && cb()
        } else {
            messageUtil(result.msg, "error")
        }
    }
}

export const videosList_func = (channelId: number): AppThunk => async (dispatch, getState) => {
    const result: any = await videosList(channelId);
    if (result.success) {
        dispatch(setVideos(result.data));
    }
}

/**
 * 评论列表
 * @param {*} channelId 
 * @returns 
 */
export const getComments_func = (channelId: number): AppThunk => async (dispatch, getState) => {
    const result: any = await getComments(channelId);
    if (result.success) {
        const newComments = result.data.map((item: any) => {
            return {
                0: 'msg',
                1: [1, item.userId, item.userName, 0, item.content],
                2: v4(),
                _id: v4()
            }
        })
        dispatch(setComments(newComments));
    }
}
/**
 * 
 * @param {*} videoId 
 * @param {*} videoTime 
 * @param {*} content 
 * @returns 
 */
export const pushComments_func = (videoId: number, videoTime: number, content: string): AppThunk => async (dispatch, getState) => {
    const userInfo = getState().loginModule.userInfo;
    const result: any = await pushComments(videoId, videoTime, content);
    if (result.success) {
        dispatch(setOrginMessageList({
            0: 'msg',
            1: [1, userInfo.userId, userInfo.name, 0, content],
            2: v4(),
            _id: v4()
        }))
    }
}

export const beginView_func = (videoId: number, cb: (data: any) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await beginView(videoId);
    if (result.success) {
        cb && cb(result.data)
    }
}


export const getTask_10009_func = (cb: (data: any) => void): AppThunk => async (dispatch, getState) => {
    const results: any = await getUserTasks("10009");
    if (results.success) {
        cb && cb(results.data[0])
    }
}

export const endView_func = (videoId: number, endToken: string, cb: (data: any, data2: any) => void): AppThunk => async (dispatch, getState) => {
    const result = await endView(videoId, endToken);
    try {
        const result2: any = await beginView(videoId);
        const results: any = await getUserTasks("10009");
        if (result2.success) {
            cb && cb(result2.data, results.data[0])
        }
    } catch (error) {
        
    }
  
}

export default RoomClice.reducer;