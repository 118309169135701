import { Popup } from "antd-mobile";
import { useDispatch, useSelector } from 'react-redux';
import cedis from "@assets/h5Images/cedis";
import { setGiftListModal, openSendGiftModal } from '../../features/globalClice'
import { RootState } from "@/store";
import './giftsPopup.scss'

export default function Index({ visible }: { visible: boolean }) {
    const dispatch = useDispatch();
    const { gifts } = useSelector((state: RootState) => {
        return {
            gifts: state.giftModal.gifts
        }
    })

    return <Popup
        visible={visible}
        onMaskClick={() => {
            dispatch(setGiftListModal(false))
        }}
        onClose={() => {
            dispatch(setGiftListModal(false))
        }}
        bodyStyle={{ height: '40vh' }}
    >
        <div className="h5-gifts-modal">
            {
                gifts && gifts.map((item) => {
                    return <div className="item" key={item.giftId} onClick={() => {
                        dispatch(openSendGiftModal(item))
                        dispatch(setGiftListModal(false))
                    }}>
                        <div className="gift">
                            <img src={`${item.imgBaseUri}${item.icon}`} />
                        </div>
                        <div className="info">
                            <img src={cedis} className="cedis" /> {item.unitPrice}
                        </div>
                    </div>
                })
            }
        </div>
    </Popup>
}