import React,{ useMemo } from 'react';

import './emojiData.scss'
import { emoSvg } from '../../features/globalClice'

import DOMPurify from 'dompurify' 

export default function EmojiData({ msgInfo }:{ msgInfo:any }) { 
  const getMessageHTML=useMemo(()=>{
    let message = msgInfo; 

    // 正则表达式，匹配表情标识字符串
    const emojiRegex = /\[\w+\]/g;

    // 使用正则表达式进行替换
    const replacedMessage = message.replace(emojiRegex, (match:any) => {
      const emojiImagePath = emoSvg[match];  
      if (emojiImagePath) {
        return `<img src="${emojiImagePath}" className="emo" alt="${match}" />`;
      }
      return match; // 返回原始的匹配内容，如果未找到对应表情
    }); 
 
    const sanitizedHTML = DOMPurify.sanitize(replacedMessage);
    return sanitizedHTML
  },[msgInfo])

  return <span dangerouslySetInnerHTML={{ __html: getMessageHTML }} className='emoImgData'>  
  </span>
}