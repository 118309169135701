import { message } from "antd"
import { Toast } from "antd-mobile"

export const messageUtil = (text: string, config?: "success" | 'error' ) => {
    if (process.env.REACT_APP_ISMOBILE === 'true') { 
        if (config == "error") {
            Toast.show({
                icon: 'fail',
                content: text,
            })
        } else {
            Toast.show({
                icon: 'success',
                content: text,
            })
        }
    } else {
        if (config == "error") {
            message.error(text) 
        } else {
            message.success(text)
        }

    }
} 