import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import Play from '../VideoPlayer/play';
import Volume from '../VideoPlayer/volume';
import Videojs from 'video.js';
import './video-js.scss';
import './index.scss';
import AdVideo from './adVideo';
let saveIndex = 0;
let currentTime = 0;
const MultiSourceVideo = ({ videos, onPlayIndex }: { videos: any[], onPlayIndex: (videoIndex: number) => void }, ref: any) => {
    const videoRef = useRef(null);
    const [video, setVideo] = useState<any>(null);
    const [volumeValue, setVolumeValue] = useState<number>(1);
    const [play, setPlay] = useState(true);
    const [isAdPlaying, setIsAdPlaying] = useState(false);
    useEffect(() => {

        const player: any = Videojs(
            "custom-video",
            {
                bigPlayButton: false,
                textTrackDisplay: false,
                errorDisplay: false,
                controlBar: false,
                controls: false,
                fluid: false,
                liveui: false,
                modalDialog: false,
                width: 456,
                height: 256,
                type: "application/x-mpegURL"
            },
            () => {
                // player.play(); 
            }
        );
        let currentVideoIndex = saveIndex;
        if (videos.length > 0) {


            var videoList = videos.map((item, index) => {
                return {
                    src: item.videoPath,
                    type: item.videoType
                }
            })

            const playNextVideo = () => {
                // Remove any existing sources
                player.pause();
                player.src([]);

                // Add next video source
                player.src(videoList[currentVideoIndex]);
                saveIndex = currentVideoIndex;
                // Increment video index or loop back to the beginning
                currentVideoIndex = (currentVideoIndex + 1) % videoList.length;
                // Play the next video
                onPlayIndex && onPlayIndex(currentVideoIndex)
                player.currentTime(currentTime);
                try {
                    player?.play(); 
                } catch (error) {
                   
                }


                currentTime = 0;
            }

            // Listen for ended event to play next video
            player.on('ended', playNextVideo);

            // Initial play
            playNextVideo();

            setVideo(player)

            // setTimeout(() => {
            //     player?.pause();
            //     setIsAdPlaying(true);

            // }, 10000)
        }
        return () => {
            currentTime = player.currentTime();
            player.dispose()
        }
    }, [videos.length])
    // useEffect(() => {
    //     return () => {
    //         if (video) {
    //             storage.set('soga-tv-current', { videoIndex, currentVideoIndex });
    //         }
    //     }
    // }, [video])
    /**
     * 对组件外开放方法
     */
    useImperativeHandle(ref, () => ({
        onVideoPlayTime: () => {
            return {
                currentTime: video.currentTime()
            }
        }
    }))

 

    // 设置音量 
    useEffect(() => {
        video?.volume(volumeValue); // 音量范围从0到1
    }, [volumeValue])
    return (
        <>
            <video id="custom-video" ref={videoRef} className="tv-video">
                Your browser does not support the video tag.
            </video>
            {/* <AdVideo onEnded={() => {
                setIsAdPlaying(false);
                video?.play();
            }} isAdPlaying={isAdPlaying} /> */}
            <div id="soga_video_tools" className="soga-h5-video-tools show">
                <Play play={play} onPayClick={() => {
                    video?.play();
                    setPlay(true)
                }} onStopClick={() => {
                    video?.pause();
                    setPlay(false)
                }} />
                <Volume value={Math.floor(volumeValue * 100)} onChange={(e) => {
                    setVolumeValue(e / 100)
                }} />
            </div>
        </>
    );
};

export default forwardRef(MultiSourceVideo)