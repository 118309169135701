
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import Emoji from './emojiData'
import { MsgType, RoomMsgType } from "../../features/webSocketClice"
import redPkIcon from '@assets/h5Images/redPkIcon'

export default function Index({ messageInfo }:{ messageInfo:any }) {
    const Name = ({ msgInfo }:{ msgInfo:any }) => {
        return <label className="message-name">{msgInfo[2]}: </label>
    }


    const elem = useMemo(() => {

        switch (messageInfo[0]) {
            case MsgType.msg:
                const msgInfo = messageInfo[1];
                switch (msgInfo[0]) {
                    case RoomMsgType.chatMessage:
                        return <div className="chatInfo item"><Name msgInfo={msgInfo} /><Emoji msgInfo={msgInfo[4]} /></div>
                    case RoomMsgType.giftNotification:
                        return <div className="chatInfo item gift" >{msgInfo[2]} delivered  <img src={`${msgInfo[6].imgBaseUri}${msgInfo[6].icon}`} /> ×{msgInfo[5]} </div>
                    case RoomMsgType.redPkNotification:
                        return <div className="chatInfo item redPk">{msgInfo[2]} received {msgInfo[5]} POGO <img src={redPkIcon} /></div>
                    case RoomMsgType.entryNotification:
                        return <div className="chatInfo item" style={{ opacity: 0.5 }}>{msgInfo[2]} entered the live broadcast room </div>
                    case RoomMsgType.blindBoxNotification:
                        return <div className="chatInfo item gift" >

                            {msgInfo[5].map((item2:any, index:number) => {
                                if (index == 0) {
                                    return <span key={item2[0]}>
                                        {msgInfo[2]}   delivered  <img key={index} src={`${item2[2].imgBaseUri}${item2[2].icon}`} />  ×{item2[1]}  dropped
                                    </span>
                                }
                                return <span key={item2[0]}> <img src={`${item2[2].imgBaseUri}${item2[2].icon}`} />×{item2[1]}</span>
                            })}
                        </div>
                    default:
                        break;
                }
                break;
            default:
                break;
        }


    }, [messageInfo[0]])

    return <div>
        {elem}
    </div>
}