import { dataURLtoFile } from '@/utils/stringFormat'
import { axios } from '@utils/request'
import Qs from 'qs'

/**
 * 获取 直播/socket token
 * @param roomId 
 * @param tokenType 
 * @returns 
 */
export function getRoomRtcToken(roomId: number, tokenType: 1 | 2 | 3) {
    return axios({
        url: `/room:rtcToken`,
        method: 'get',
        params: {
            roomId: roomId,
            tokenType: tokenType
        }
    })
}


/**
 * 获取 直播/socket token
 * @param roomId 
 * @param tokenType 
 * @param definition
 * @returns 
 */
export function getRoomToken(roomId: number, tokenType: 1 | 2 | 3 | 4, definition: '720p' | '1080p') {
    return axios({
        url: `/room:room/${roomId}/${definition}`,
        method: 'get',
        params: {
            tokenType: tokenType
        }
    })
}
/**
 * 直播间列表
 * @param roomId 
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export function getRoomList(roomId: number | undefined, pageStart: number = 1, pageSize?: number) {
    return axios({
        url: '/room:list',
        method: 'post',
        data: {
            pageStart,
            pageSize: pageSize || 10,
            cond: {
                roomId
            }
        }
    })
}

/**
 * 领取房间NFT
 * @returns 
 */
export function receiveRoomNFT() {
    return axios({
        url: `/room:receive`,
        method: 'post'
    })
}
/**
 * 查询 房间NFT 领取状态
 * @returns 
 */
export function receiveStatus() {
    return axios({
        url: `/room:receiveStatus`,
        method: 'get'
    })
}
/**
 * 重新获取房间mint数据
 */
export function getRoomNFTCallData() {
    return axios({
        url: `/room:roomCallData`,
        method: 'get'
    })
}

/**
 * 
 * @returns 获取直播房间
 */
export function getUserRooms() {
    return axios({
        url: `/room:userRooms`,
        method: 'get'
    })
}
/**
 * 主播列表
 * @param roomId 
 * @returns 
 */
export function listRoomHost(roomId: string, type: 1 | 2) {
    return axios({
        url: `room:listRoomHost`,
        method: 'get',
        params: {
            roomId,
            type
        }
    })
}
/**
 * 添加主播授权
 * @param userHostWallet   钱包地址
 * @param roomId    房间id
 * @param contractAddress   合约地址
 * @returns 
 */
export function addRoomHost(userHostWallet: string, roomId: string, type: 1 | 2) {
    return axios({
        url: `/room:addRoomHost`,
        method: 'post',
        data: Qs.stringify({
            userHostWallet,
            roomId,
            type
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 删除授权主播
 * @param userHostWallet   钱包地址
 * @param roomId    房间id
 * @param contractAddress   合约地址
 * @returns 
 */
export function delRoomHost(userHostWallet: string, roomId: string, type: 1 | 2) {
    return axios({
        url: `/room:delRoomHost`,
        method: 'post',
        data: Qs.stringify({
            userHostWallet,
            roomId,
            type
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 黑名单列表
 * @param roomId 
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export function getBlackList(roomId: number, pageStart: number = 1, pageSize?: number) {
    return axios({
        url: `/host:listBlackList`,
        method: 'post',
        data: {
            pageStart,
            pageSize: pageSize || 10,
            cond: {
                roomId
            }
        }
    })
}
/**
 * 添加黑名单
 * @param roomId 
 * @param blackUserId 
 * @returns 
 */
export function addBlackList(roomId: number, blackUserId: number) {
    return axios({
        url: `/host:addBlackList`,
        method: 'post',
        data: Qs.stringify({
            roomId,
            blackUserId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 移除黑名单
 * @param roomId 
 * @param blackUserId 
 * @returns 
 */
export function delBlackList(roomId: number, blackUserId: number) {
    return axios({
        url: `/host:delBlackList`,
        method: 'post',
        data: Qs.stringify({
            roomId,
            blackUserId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 禁言列表 
 * @param roomId 
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export function getSilenceList(roomId: number, pageStart: number = 1, pageSize?: number) {
    return axios({
        url: `/host:listSilenceList`,
        method: 'post',
        data: {
            pageStart,
            pageSize: pageSize || 10,
            cond: {
                roomId
            }
        }
    })
}

/**
 * 设置禁言
 * @param roomId  房间号
 * @param silenceUserId   用户id
 * @param timeLong    禁言时长
 * @param type   1 全体禁言   2 按用户经验
 * @returns 
 */
export function addSilenceList(roomId: number, silenceUserId?: number) {
    return axios({
        url: `/host:addSilenceList`,
        method: 'post',
        data: Qs.stringify({
            roomId,
            silenceUserId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 设置禁言
 * @param roomId  房间号
 * @param silenceUserId   用户id  传0 解除全员经验 
 * @returns 
 */
export function delSilenceList(roomId: number, silenceUserId: number) {
    return axios({
        url: `/host:delSilenceList`,
        method: 'post',
        data: Qs.stringify({
            roomId,
            silenceUserId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 图片上传
 * @param roomId 
 * @param imageDataURL 
 * @returns 
 */
export function uploadImg(roomId: number, imageContent: any) {

    // 构建FormData对象，用于上传图片
    // const formData = new FormData();
    // formData.append('file', dataURLtoFile(imageDataURL, `${roomId}.jpg`));

    return axios({
        url: `/room:uploadImg`,
        method: 'post',
        data: Qs.stringify({
            roomId,
            imageContent
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 获取正在直播的列表
 * @returns 
 */
export function getOnLineList() {
    return axios({
        url: `/room:listOnLine`,
        method: 'get',
    })
}
/**
 * 获取房间图片
 * @param roomId 
 * @returns 
 */
export function getRoomImg(roomId: string) {
    return axios({
        url: `/room:image`,
        method: 'get',
        params: {
            roomId
        }
    })
}

/**
 * 用户在线人数
 * @param roomId 
 * @returns 
 */
export function getUserCount(roomId: number) {
    return axios({
        url: `/room:userCount`,
        method: 'get',
        params: {
            roomId
        }
    })
}
/**
 * 直播信息
 * @param roomId 
 * @returns 
 */
export function getliveInfo(liveId: number) {
    return axios({
        url: `/room:liveInfo`,
        method: 'get',
        params: {
            liveId
        }
    })
}

/**
 * 下播
 * @param roomId 
 * @returns 
 */
export function downBroadcast(roomId: number) {
    return axios({
        url: `/room:downBroadcast`,
        method: 'post',
        params: {
            roomId
        }
    })
}

/**
 * 上播
 * @param roomId 
 * @returns 
 */
export function upBroadcast(roomId: number, reType: 0 | 1 | 2) {
    return axios({
        url: `/room:upBroadcast`,
        method: 'post',
        params: {
            roomId,
            reType
        }
    })
}
/**
 * 修改房间名称
 * @param roomId 
 * @param roomName 
 * @returns 
 */
export function updateRoomName(roomId: number, roomName: string) {
    return axios({
        url: `/room:updateRoomName`,
        method: 'post',
        params: {
            roomId,
            roomName
        }
    })
}

/**
 * 获取频道列表
 * @returns 
 */
export function getChannels() {
    return axios({
        url: `/channels:list`,
        method: 'get',
        params: {
        }
    })
}
/**
 * 频道关注
 * @param {*} channelId 
 * @returns 
 */
export function channelsFollow(channelId: number) {
    return axios({
        url: "/channels:follow",
        method: 'post',
        data: Qs.stringify({
            channelId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 频道取消
 * @param {*} channelId 
 * @returns 
 */
export function channelsUnFollow(channelId: number) {
    return axios({
        url: "/channels:unfollow",
        method: 'post',
        data: Qs.stringify({
            channelId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 视频列表
 * @param {*} channelId 
 * @returns 
 */
export function videosList(channelId: number) {
    return axios({
        url: `/videos:list?channelId=${channelId}`,
        method: 'get'
    })
}
/**
 * 评论列表
 * @param {*} channelId 
 * @returns 
 */
export function getComments(channelId: number) {
    return axios({
        url: `/comments:list?channelId=${channelId}`,
        method: 'get'
    });
}
/**
 * 视频评论
 * @param {*} videoId 
 * @param {*} videoTime 
 * @param {*} content 
 * @returns 
 */
export function pushComments(videoId: number, videoTime: number, content: string) {
    return axios({
        url: `/comments:publish`,
        method: "post",
        data: {
            videoId,
            videoTime,
            content
        }
    })
}

/**
 * 开启观看时间
 * @param {*} videoId 
 * @returns 
 */
export function beginView(videoId:number) {
  
    return axios({
        url: `/videos:beginView`,
        method: "post",
        data: {
            videoId
        }
    })
}
/**
 * 结算时间
 * @param {*} videoId 
 * @param {*} endToken 
 * @returns 
 */
export function endView(videoId:number, endToken:string) {
 
    return axios({
        url: `/videos:endView`,
        method: "post",
        data: {
            videoId,
            endToken
        }
    })
}