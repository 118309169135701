import axios from 'axios'
import { message, notification } from 'antd';
import { config as webConfig } from '../config'


// 创建 axios 实例
const service = axios.create({
    baseURL: webConfig.baseURL, // api base_url
    withCredentials: true,
    timeout: 60000, // 超时时间：6分钟 注意和nginx配置保持一致
    maxBodyLength: 31457280,  // 请求体最大长度 单位B，上限30MB 注意和nginx配置保持一致
    maxContentLength: 5242880,  // 响应的最大长度，单位 B ，5MB，针对文件或者表格数据大量返回 注意和nginx配置保持一致
})
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const err = (error) => { 
    if (error.response) {
        const data = error.response.data 
        if (error.response.status === 401) { // 登录失效
            localStorage.removeItem('token'); 
        } else if (error.response.status === 402) {  // 缺少权限
            notification.error({
                message: 'You do not have permission to view current information',
                description: 'Please contact the system administrator to obtain data permissions',
                duration: null
            })
        } else if (error.response.status === 403) {
            localStorage.removeItem('token'); 
            notification.error({
                message: 'Forbidden',
                description: data.message,
                duration: null
            })
        } else if (error.response.status === 504) {  // 请求超时
            console.log(error);
            notification.error({
                message: 'tips',
                description: 'The request time is long, please check later',
                duration: null
            });
        } else if (error.response.status === 500) {  // 服务端报错
            notification.error({
                message: 'tips',
                description: 'service exception',
                duration: null
            });
        }
    } else {
        // 请求超时
        // notification.error({
        //     message: 'tips',
        //     description: 'The request time is long, please check later',
        //     duration: null
        // }); 
    }
    return Promise.reject(error)
}

// 请求拦截，设置token
service.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    // config.headers['Access-Token'] = token ? `Bearer ${token}` : '';
    config.headers['Authorization'] = token ? `Bearer ${token}` : '';
    config.headers['clientType'] = 'pc';
    config.headers['version'] = '1';
    config.headers['Chain-Name'] = webConfig.chainName || 'bsc'; 
    return config
}, err)

// 处理响应
service.interceptors.response.use((response) => { 
    return response.data
}, err)

function requests(options = {}) {
    return service.request({ ...options })
}

export { requests as axios }
