
import metaMask from '@assets/h5Images/metaMask';
import logo from '@assets/h5Images/soga-logo';
import './index.scss'
import { useAccount, useConnectModal, useNetwork } from '@particle-network/connect-react-ui';
// import { RootState } from '@/store';
// import { useDispatch, useSelector } from 'react-redux';
// import head from '@assets/h5Images/head';
// import { receiveVip_Func } from '@/features/nftsClice';
// import { useChain } from '@particle-network/connect-react-ui/dist/provider/ModalContext';

export default function Index() {

    const connectKit = useConnectModal();
    // const { chain } = useNetwork()

    // const account = useAccount()
    // const dispatch = useDispatch()

    // if (account) {
    //     return <div className="h5-connectWallet-page">
    //         <img className="h5-smallLogo H5-ntfImg" src={head} />
    //         <div className="btn" onClick={() => {
    //             dispatch(receiveVip_Func(chain?.id || 0, account))
    //         }}> Claim</div>
    //     </div>
    // } else {
    //     return <div className="h5-connectWallet-page">
    //         <img className="h5-smallLogo" src={logo} />
    //         <div className="text">connect a wallet</div>
    //         <div className="btn" onClick={() => {
    //             connectKit.openConnectModal && connectKit.openConnectModal()
    //         }}><img className='metaMask-logo' src={metaMask} /> MetaMask</div>
    //     </div>
    // }

    return <div className="h5-connectWallet-page">
        <img className="h5-smallLogo" src={logo} />
        <div className="text">connect a wallet</div>
        <div className="btn" onClick={() => {
            connectKit.openConnectModal && connectKit.openConnectModal()
        }}><img className='metaMask-logo' src={metaMask} /> MetaMask</div>
    </div>
}