
export const getAddress = (address: string) => {
    if (address && address.length > 10) {
        let first = address.substring(0, 5);
        let last = address.substring((address.length - 5));
        return `${first}...${last}`
    } else {
        return address
    }
}


export const stringCopy = (text: string, cb?: Function) => {
    try {
        let url = text;//拿到想要复制的值
        let copyInput = document.createElement('input');//创建input元素
        document.body.appendChild(copyInput);//向页面底部追加输入框
        copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
        copyInput.select();//选择input元素
        document.execCommand("Copy");//执行复制命令
        copyInput.remove();//删除动态创建的节点 
        cb && cb(true)
    } catch (error) {
        cb && cb(false)
    }

}


export const getImage = (urls: string, imageUrl: any) => {
    if (urls) {
        // const logoList = item.images.split(',');
        // const logo = logoList[logoList.length - 1];
        if (imageUrl.includes('nfts')) {
            return `${urls}/${imageUrl}`
        } else {
            return `${urls}/${imageUrl}/preview.png`
        }
    } else {
        return ''
    }
}


// 将base64数据转换为File对象
export function dataURLtoFile(dataURL: string, fileName: string) {
    const arr: any = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}

// 将长名字替换成前10位加...
export function nameDns(name: string) {
    if (name && name.length > 10) {
        let newName = name.slice(0, 10)
        return newName + '...'
    } else {
        return name
    }
}

// 将长描述替换成前20位加...
export function remarkDns(name: string) {
    if (name !== undefined) {
        if (name.length > 20) {
            let newName = name.slice(0, 10)
            return newName + '...'
        } else {
            return name
        }
    } else {
        return '--'
    }
}


// 将钱包截取前面6位中间加上...以及截取后四位
export function walletDns(name: string) {
    let newName = name.slice(0, 6)
    let afterName = name.slice(name.length - 4, name.length)
    return newName + '...' + afterName
}

// 将后台的年月日修改成xxxx-xx-xx格式
export function dateFormat(date: string) {
    let year = date.slice(0, 4)
    let month = date.slice(4, 6)
    let day = date.slice(6, 8)
    return year + '-' + month + '-' + day
}

// 将传入的时间转换成年月日或者年月
export function convertDate(number: number) {
    const newDate = new Date();
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1 < 10 ? '0' + Number(newDate.getMonth() + 1) : newDate.getMonth() + 1;
    let day = newDate.getDate() < 10 ? '0' + Number(newDate.getDate()) : newDate.getDate();
    if (number === 6) {
        return year + '' + month
    } else if (number === 8) {
        return year + '' + month + '' + day
    }
}
