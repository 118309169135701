import { axios } from '../utils/request'
/**
 * 获取服务器
 * @returns 
 */
export function getConfig(){
  return axios({
    url:`/config:get`,
    method: 'get'
  })
}