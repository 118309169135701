 
import { Mask, Toast } from "antd-mobile"; 
import modalClose from '@assets/h5Images/modalClose'
import './sendGift.scss'
import React, { useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import spending from '@assets/h5Images/spending';
import walletIcon from '@assets/h5Images/walletIcon';
import cedis from "@assets/h5Images/cedis";
import { AssetTypeEnum, withdrawApply_Func } from '@features/accountClice'
import { Input,message } from 'antd';
import { RootState } from "@/store";

export default function Index({ visible, onClose }: { visible: boolean, onClose?: () => void }) {
    const [amount, setAmount] = useState<any>()
    const dispatch = useDispatch();


    const { pogoBalance, serverConfig } = useSelector((state:RootState) => {
        const pogoBalance = state.accountModal.balance.find((item) => {
            return item.asset == AssetTypeEnum.POGO
        })
        return {
            pogoBalance: pogoBalance,
            serverConfig: state.globalModule.serverConfig
        }
    })
    const onClick = () => {

        if (amount === '0' || amount === '' || amount == undefined) {
            message.error('Cannot be empty or 0')
        } else {
            if (amount < (serverConfig?.pogoWithdrawMinAmount || 20000)) {
                message.error(`Minimum withdrawal amount is ${serverConfig?.pogoWithdrawMinAmount || 20000} SOGA`)
                return;
            } else if (Number(amount) > Number(pogoBalance?.balance)) {
                message.error('Input amount too large')
                return;
            }
            dispatch(withdrawApply_Func(AssetTypeEnum.POGO, amount))
            setAmount('')
        }
    }

    return <Mask className="h5-send-gift-modal" visible={visible}>
        <div className="h5-send-gfit-message">

            <img src={modalClose} className="modal-close" onClick={() => {
                onClose && onClose();
            }} />
            
            <div className="send-transfer-message">
            <div className="title">Transfer</div>
            <div className="subTitle">Withdrawal requires manual review, which takes 3~5 working days</div>
            <div className="normal-container">
                <div className='normalInput'><div className="left">From</div> <div className='right'><img src={spending} alt="" />Spending</div></div>
                <div className='normalInput'><div className="left">To</div> <div className='right'><img src={walletIcon} alt="" />Wallet</div></div>
            </div>

            <div className='title' > Asset</div>
            <div className='normalInput asset'><img src={cedis} alt="" /> SOGA</div>
            <div className="group-t"><div className="title">Amount</div> <div className="title"><label>{pogoBalance?.balance || "--"}</label> Available</div></div>
            <Input.Group compact>
                <Input value={amount} onChange={(e) => {
                    if (e.target.value === "" || e.target.value === '0') {
                        setAmount(e.target.value)
                    } else {
                        const v = e.target.value.replace(/[^0-9]/g, '');
                        setAmount(v)
                    }
                }} className='amount' />


                <div className='amountAll' onClick={() => {
                     setAmount(pogoBalance?.balance)
                }}>ALL</div>
            </Input.Group>
            <div className="freeTip">
                Fee: <label>{serverConfig?.pogoWithdrawFee || '--'}</label> SOGA (Minimum withdrawal amount is <label>{serverConfig?.pogoWithdrawMinAmount || '--'}</label> SOGA.)
            </div>

            <div className='btn' onClick={onClick}>
                Confirm Transfer
            </div>
        </div>
        </div>
    </Mask>
}