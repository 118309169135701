import CryptoJS from 'crypto-js' 


export function encrypt(text, key) {
    if (typeof text !== 'string') {
        text = JSON.stringify(text)
    }
    const _key = CryptoJS.enc.Utf8.parse(key)// token 只能16位
    const srcs = CryptoJS.enc.Utf8.parse(text)
    const encrypted = CryptoJS.AES.encrypt(srcs, _key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}

export function decrypt(text) {
    var decryptKey = document.getElementById("key").value;
    if (typeof text !== 'string') {
        text = JSON.stringify(text)
    }
    const _key = CryptoJS.enc.Utf8.parse(decryptKey)
    const decrypt = CryptoJS.AES.decrypt(text, _key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}

export function Encrypt(word, keyStr) {
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    // iv = CryptoJS.enc.Utf8.parse(iv)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    // 加密模式设置为 ECB，补码方式设置为 Pkcs7（即 PKCS5Padding）
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    //   iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
} 