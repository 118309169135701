import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { register, refreshToken, getUserInfo, updateUser, getFollowList, addFollow, delFollow, getFansList, listUserNftInfo, followfans, postAuthorize, getAuthorize, getInviteInfo, bindInviteCode } from '../api/login'
import { getRoomRtcToken, getRoomToken } from '@/api/room';
import { message } from 'antd';
import { TokenTypeEnum, setInviterModal, showFailModal, showSuccessModal } from './globalClice';
import { EncoderConfigEnum, UserTypeEnum, getChannels_func, getliveInfo_Func, setLiveId, tokenResultError_Func } from './roomClice';
import { Toast } from 'antd-mobile';
import { messageUtil } from '@/utils/tools';
import { error } from 'console';


interface LoginCliceState {
    token: string | null,
    loginState: boolean,
    loginLoading: boolean,
    userInfo: any,
    followList: any[],
    fansList: any[],
    userNftInfo: any[],
    followStat: boolean,
    followfansNum: any,
    followCount: number,
    fansCount: number,
    inviteInfo: any
}


const initialState: LoginCliceState = {
    token: localStorage.getItem('token'),
    loginState: false,
    loginLoading: false,
    userInfo: null,
    followList: [],
    followCount: 0,
    fansList: [],
    userNftInfo: [],
    followStat: false,
    followfansNum: {},
    fansCount: 0,
    inviteInfo: null
}
const LoginClice = createSlice({
    name: 'LoginModule',
    initialState,
    reducers: {
        setToken(state, { payload }) {
            state.token = payload;
        },
        setLoginState(state, { payload }) {
            state.loginState = payload
        },
        setLoginLoading(state, { payload }) {
            state.loginLoading = payload
        },
        setUserInfo(state, { payload }) {
            state.userInfo = payload
        },
        setFollowList(state, { payload }) {
            state.followList = payload
        },
        setFansList(state, { payload }) {
            state.fansList = payload
        },
        setUserNftInfo(state, { payload }) {
            state.userNftInfo = payload
        },
        setFollowStat(state, { payload }) {
            state.followStat = payload
        },
        setFollowfansNum(state, { payload }) {
            state.followfansNum = payload
        },
        setFollowCount(state, { payload }) {
            state.followCount = payload
        },
        setFansCount(state, { payload }) {
            state.fansCount = payload
        },
        setInviteInfo(state, { payload }) {
            state.inviteInfo = payload
        },
    },
});

export const { setToken, setLoginState, setLoginLoading, setUserInfo, setFollowList, setFansList, setUserNftInfo, setFollowStat, setFollowfansNum, setFollowCount, setFansCount, setInviteInfo } = LoginClice.actions;
/**
 * 注册/登录
 * @param wallet 
 * @param signature 
 * @param code 
 * @param cb 
 * @returns 
 */
export const register_Func = (wallet: string, signature: string, code?: string, cb?: Function): AppThunk => async (dispatch, getState) => {
    const res: any = await register({
        wallet,
        signature,
        code
    });
    if (res.success && res.data === false) {
        cb && cb(false)
    } else if (res.success && res.data) {
        localStorage.setItem('token', res.data.token);
        dispatch(setToken(res.data.token))
        dispatch(userInfo_Func())
        dispatch(setLoginState(true))
        cb && cb(true)
    } else {
        if (res.code == "PU013") {
            dispatch(showFailModal("account Disabled"))
            cb && cb(false)
        }
    }
}

/**
 * 获取邀请信息
 * @returns 
 */
export const getInviteInfo_Func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await getInviteInfo();
    if (result.success) {
        dispatch(setInviteInfo(result.data))
    }
}
export const bindInviteCode_Func = (code: string): AppThunk => async (dispatch, getState) => {
    const result: any = await bindInviteCode(code);
    if (result.success) {
        dispatch(setInviterModal(false));
        dispatch(getInviteInfo_Func());
    } else { 
        messageUtil('Invalid invitation code!',"error")
    }
}

export const disConnect_Func = (): AppThunk => async (dispatch, getState) => {
    localStorage.removeItem('token');
    dispatch(setLoginState(false));
    dispatch(setToken(null));
    dispatch(setUserInfo(null));
    localStorage.removeItem('signingLock');
}
/**
 * 获取直播token
 * @returns 
 */
export const refreshToken_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
    const res: any = await refreshToken();
    if (res.success && res.data === false) {
    } else if (res.success && res.data) {
        localStorage.setItem('token', res.data.token);
        dispatch(setToken(res.data.token))
        cb && cb()
    } else {
        dispatch(disConnect_Func());
        localStorage.removeItem('token');
        dispatch(setToken(null))
    }
}
/**
 * 用户信息
 * @param cb 
 * @returns 
 */
export const userInfo_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
    try {
        const res: any = await getUserInfo();
        if (res.success && res.data) {
            dispatch(setUserInfo(res.data));
            dispatch(getChannels_func())
            cb && cb()
        } else {
            cb && cb()
        }
    } catch (error: any) {
        dispatch(disConnect_Func());
        cb && cb()
    }

}

/**
 * 获取token
 * @param roomId 
 * @param tokenType 
 * @returns 
 */
export const getRoomRtcToken_fun = (roomId: any, tokenType: UserTypeEnum, definition: EncoderConfigEnum = EncoderConfigEnum.p720) => {
 
    return getRoomToken(roomId, tokenType, tokenType == 1 ? EncoderConfigEnum.p1080 : definition);
}

export const getRoomRtcToken2_fun = (roomId: any, tokenType: UserTypeEnum, definition = EncoderConfigEnum.p720, cb: Function): AppThunk => async (dispatch, getState) => {
 
    const result: any = await getRoomToken(roomId, tokenType, definition);
    if (result.success) {
        dispatch(getliveInfo_Func(result.data.liveId));
        dispatch(setLiveId(result.data.liveId));
        cb && cb(result.data)
    } else {
        dispatch(tokenResultError_Func(result))
        cb && cb(null)
    }
}


/**
 * 修改用户信息
 * @param anme 
 * @param introduction 
 * @param cb 
 * @returns 
 */
export const updateUser_Func = (name: string, introduction: string, cb?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await updateUser(name, introduction);
    if (result.success) {
        dispatch(userInfo_Func());
 
        messageUtil("Successfully saved")
        cb && cb()
    } else { 
        messageUtil('Save failed',"error")
        cb && cb()
    }
}

/**
 * 我的关注列表
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export const getFollowList_Func = (pageStart?: number, pageSize?: number): AppThunk => async (dispatch, getState) => {
    const result: any = await getFollowList(pageStart, pageSize);
    if (result.success) {
        if (pageStart == 1) {
            dispatch(setFollowCount(result.data.count))
        }
        dispatch(setFollowList(result.data.list));
    }
}
/**
 * 添加 删除 关注
 * @returns 
 */
export const operateFollow_Func = (operate: 'add' | 'del', followedUserId: number, cb?: Function,pageSize?:number): AppThunk => async (dispatch, getState) => {
    if (operate == 'add') {
        const result: any = await addFollow(followedUserId);
        if (result.success) {
            dispatch(getFollowList_Func(1,pageSize)); 
            messageUtil('Follow Success')
            dispatch(setFollowStat(true))
            cb && cb()
        }
    } else {
        const result: any = await delFollow(followedUserId);
        if (result.success) {
            dispatch(getFollowList_Func(1,pageSize)); 
            dispatch(setFollowStat(false)) 
            messageUtil('Cancel Follow Successfully')
            cb && cb()
        }
    }

}
/**
 * 我的粉丝列表
 * @param pageStart 
 * @param pageSize 
 * @returns 
 */
export const getFansList_Func = (pageStart?: number, pageSize?: number): AppThunk => async (dispatch, getState) => {
    const result: any = await getFansList(pageStart, pageSize);
    if (result.success) {
        if (pageStart == 1) {
            dispatch(setFansCount(result.data.count))
        }
        dispatch(setFansList(result.data.list));
    }
}


export const listUserNftInfo_Func = (userId: number[]): AppThunk => async (dispatch, getState) => {
    const result: any = await listUserNftInfo(userId);
    if (result.success) {
        dispatch(setUserNftInfo(result.data));
    }
}

export const followfans_Func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await followfans();
    if (result.success) {
        dispatch(setFollowfansNum(result.data));
    }
}


export const getAuthorize_Func = (responseType: string, state: string, redirectUri: string, clientId: string, scope: string): AppThunk => async (dispatch, getState) => {
    const result: any = await getAuthorize(responseType, state, redirectUri, clientId, scope);
    console.log(result, "===========get result")
    if (result.success) {

    }
}

export const postAuthorize_Func = (responseType: string, state: string, redirectUri: string, clientId: string, scope: string): AppThunk => async (dispatch, getState) => {
    const result: any = await postAuthorize(responseType, state, redirectUri, clientId, scope);
    console.log(result, "===========result")
    if (result.success) {
        window.location.replace(result.data)
    }
}



export default LoginClice.reducer;