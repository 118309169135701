import { combineReducers } from 'redux';
// import { History,createBrowserHistory } from 'history';  
// import {connectRouter} from 'connected-react-router';  
import globalReducer from './globalClice';
import loginReducer from './loginClice';
import roomReducer from './roomClice';
import webSocketReducer from './webSocketClice';
import nftReducer from './nftsClice'
import accountReducer from './accountClice'
import giftReducer from './giftClice';
import taskReducer from './taskClice'

export default function createRootReducer() {
  return combineReducers({
    globalModule: globalReducer,
    loginModule: loginReducer,
    roomModule: roomReducer,
    webSocketModule: webSocketReducer,
    nftModule: nftReducer,
    accountModal: accountReducer,
    giftModal: giftReducer,
    taskModule: taskReducer
  });
}