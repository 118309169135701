import { axios } from '@utils/request';
import Qs from 'qs'

/**
 * 获取账户余额
 * @param asset  账户类型 
 * @returns 
 */
export function balance() {
  return axios({
    url: '/account:balance',
    method: 'get',
    params: {
      asset: 'POGO'
    }
  })
}

/**
 * 获取历史账单
 * @param asset  账户类型 
 * @returns 
 */

export function history(month: string, pageStart: number = 1, pageSize: number, timestamp: number) {
  return axios({
    url: `/bill:history?t=${new Date()}`,
    method: 'get',
    params: {
      asset: 'POGO',
      month: month,
      pageStart: pageStart,
      pageSize: pageSize || 10,
      timestamp: timestamp
    }
  })
}

/**
 * 获取充值地址
 * @returns 
 */
export function getReceiver() {
  return axios({
    url: `/recharge:receiver`,
    method: 'get',
    params: {
      asset: 'POGO'
    }
  })
}
/**
 * 提币操作
 * @returns 
 */
export function withdrawApply(asset: string, amount: number) {
  return axios({
    url: `/withdraw:apply`,
    method: 'post',
    data: Qs.stringify({
      asset,
      amount
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 查询奖池信息
 * @returns 
 */
export function bonusPoolQuery(date: string) {
  return axios({
    url: `/bonusPool:query`,
    method: 'get',
    params: {
      date: date
    }
  })
}

/**
 * 获取公益池余额
 * @returns 
 */
export function charitablePool() {
  return axios({
    url: `/charitablePool:balance`,
    method: 'get'
  })
}

/**
 * 查询用户分红记录
 * @returns 
 */
export function userRecords(month: string) {
  return axios({
    url: `/bonusPool:userRecords`,
    method: 'get',
    params: {
      month: month
    }
  })
}

/**
 * 获取可领取的金额总额
 * @returns 
 */
export function receivableAmount() {
  return axios({
    url: `/bonusPool:receivableAmount`,
    method: 'get'
  })
}

/**
 * 一键领取分红
 * @returns 
 */
export function receiveAll() {
  return axios({
    url: `/bonusPool:receiveAll`,
    method: 'post'
  })
}