import { RootState } from "@/store"
import { useSelector } from "react-redux";
import './index.scss'


export default function Index(){
    const { VIPNFTList, roomNFTList } = useSelector((state:RootState) => {
        return {
            VIPNFTList: state.nftModule.VIPNFT,
            roomNFTList: state.nftModule.roomNFTs
        }
    })

    return <div className="h5-myNfts-container">
        {VIPNFTList.map((item) => {
            return <div className="card" key={item.name}>
                <div className="card-img">
                    <img src={item.image} />
                </div>
                <div className="title">{item.name}</div>
            </div>
        })}
  
        {roomNFTList.map((item) => {
            return <div className="card"  key={item.name}>
                <div className="card-img">
                    <img src={item.image} />
                </div>
                <div className="title">{item.name}</div>
            </div>
        })}
    </div>
}