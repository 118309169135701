import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chatMessage_Func } from '../../features/webSocketClice';
import { message, Input } from 'antd'
import { SilenceType } from '../../features/roomClice';
import Emoji from '../Emoji'
import './index.scss'
import { RootState } from '@/store';
import { ChannelTypeEnum } from '@/features/globalClice';

const { TextArea } = Input;
export default function Index({ type,onPushComments }:{ type:ChannelTypeEnum,onPushComments:(value:string)=>void }) {
    const [sendMsg, setSendMsg] = useState('');
    const [placeholder, setPlaceholder] = useState('Interact with everyone...');
    const [disabledSend, setDisabledSend] = useState(false)
    const [isSend, setIsSend] = useState(false)
    const dispatch = useDispatch();

    const [write, setWrite] = useState(false);
    const divRef:any = useRef(null);

    const { silenceType } = useSelector((state:RootState) => {
        return {
            silenceType: state.roomModule.silenceType
        }
    })



    const sendMessageClick = () => {
        if (disabledSend) {
            return
        }


        if (isSend) {
            message.info('Sending messages too fast')
            return
        }
        const revaue = sendMsg.replace(/^\s*|(\s*$)/g, "");
        if (revaue.length === 0) {
            message.info('Message cannot be empty')
            return
        }
        setIsSend(true)
        const noEmpty = sendMsg.trim()

        if (type == 2) {
            onPushComments && onPushComments(noEmpty)
        } else {
            dispatch(chatMessage_Func(noEmpty));
        }
        setSendMsg('')
        setTimeout(() => {
            setIsSend(false)
        }, 5000)
    }
    function onEmoItemClick(item:any) {
        if (disabledSend) {
            return
        }
        if (sendMsg.length + item.length > 50) {
            return
        }
        setSendMsg(`${sendMsg}${item}`)
        setWrite(true)
    }

    useEffect(() => {
        if (silenceType == SilenceType.Send) {
            setPlaceholder('Interact with everyone...');
            setDisabledSend(false)
        } else if (silenceType == SilenceType.Sige) {
            setPlaceholder('You have been banned from speaking');
            setSendMsg('')
            setDisabledSend(true)
        } else {
            setPlaceholder('Everyone is prohibited from speaking');
            setSendMsg('')
            setDisabledSend(true)
        }
    }, [silenceType])


    useEffect(() => {
        const handleClickOutside = (event:any) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setWrite(false)
            }
        };

        // 在组件挂载时添加事件监听器
        document.addEventListener('click', handleClickOutside);

        // 在组件卸载时移除事件监听器
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    if (write) {
        return <div id="h5-send-message-card" className="h5-send-message-card" ref={divRef} >
            <TextArea
                disabled={disabledSend}
                value={sendMsg}
                autoFocus
                maxLength={50}
                placeholder={placeholder}
                style={{ height: 60, resize: 'none' }}
                onPressEnter={sendMessageClick}
                onChange={(e) => {
                    setSendMsg(e.target.value)
                }}
            />
            <div className="btns">
                <Emoji disabled={disabledSend} className={disabledSend ? 'disabled' : ''} onEmoItemClick={onEmoItemClick} />
                <div className={`btn ${disabledSend ? 'disabledBtn' : ''}`} onClick={sendMessageClick}>Send</div>
            </div>

        </div>
    } else {
        return <div className="h5-message-write" onClick={() => {
            setWrite(true);
        }}>
            Send a comment
        </div>
    }

}