import { axios } from "@/utils/request"
import Qs from 'qs'

/**
 * 
 * @param callbackUrl 推特授权
 * @returns 
 */
export function twitter_checkBindTwitter(callbackUrl: string) {
    return axios({
        url: `/twitter:checkBindTwitter?callbackUrl=${callbackUrl}`,
        method: 'get'
    })
}
/**
 * 
 * @param oauthToken 推特验证
 * @param oauthVerifier 
 * @returns 
 */
export function twitter_callback(state: string, code: string) {
    return axios({
        url: `/twitter:callback?state=${state}&code=${code}`,
        method: 'post',

    })
}

/**
 * 授权discord
 */
export function checkBindDiscord(callbackUrl: string) {
    return axios({
        url: `/discord:checkBindDiscord?callbackUrl=${callbackUrl}`,
        method: 'get'
    })
}
/**
 * 
 * @param code 检测是否进群
 * @param callbackUrl 
 * @returns 
 */
export function discordCallback(code: string, callbackUrl: string) {
    return axios({
        url: `/discord:callback?code=${code}&callbackUrl=${callbackUrl}`,
        method: 'post',
    })
}

export function getUserTasks(taskIds: string) {
    return axios({
        url: `/tasks:userTasks?taskIds=${taskIds}`,
        method: 'get'
    })
}



export function listAwardsRecord(taskId: number | string, taskRecordId: number | string) {
    return axios({
        url: `/tasks:listAwardsRecord`,
        method: 'post',
        data: {
            pageStart: 1,
            pageSize: 200,
            cond: {
                taskId: taskId,
                taskRecordId: taskRecordId
            }
        }

    })

}


/**
 * 领取奖励
 * @param {*} taskId 
 * @param {*} taskRecordDetailId 
 * @param {*} taskRecordId 
 * @returns 
 */
export function tasks_issueAwards(taskId: number, taskRecordDetailId: number, taskRecordId: number) { 
    return axios({
        url: `/tasks:issueAwards`,
        method: 'post',
        data: Qs.stringify({
            taskId,
            taskRecordDetailId,
            taskRecordId
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }

    })
}

/**
 * 做任务
 * @param taskId 
 * @param tweetId 
 * @param replyTweetId 
 * @returns 
 */
export function task_do(taskId:string, tweetId?:string, replyTweetId?:string) {
    return axios({
        url: `/tasks:do`,
        method: 'post',
        data: {
            taskId: taskId,
            otherData: {
                tweetId: tweetId,
                replyTweetId: replyTweetId
            }
        } 
    }) 
}