import React, { useEffect, useState } from 'react';
import messageIcon from '@assets/h5Images/message-icon';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { ChannelTypeEnum, openSendGiftModal, setGiftListModal  } from '@features/globalClice';
import giftIcon from '@assets/h5Images/gifts';
import { RootState } from '@/store';
import SendMessage from '../sendMessage'

export default function Index({ type, onPushComments }:{ type:ChannelTypeEnum, onPushComments?:(value:string)=>void}) {

    const dispatch = useDispatch();

    const [gift, setGift] = useState<any>(null)

    const { gifts } = useSelector((state:RootState) => {
        return {
            gifts: state.giftModal.gifts
        }
    })
    useEffect(() => {
        if (gifts) {
            const gift = gifts.find((item) => {
                if (type == ChannelTypeEnum.live) {
                    return item.giftId == 11;
                } else if (type == ChannelTypeEnum.tv) {
                    return item.giftId == 24;
                }
            })
            setGift(gift)
        }
    }, [gifts])
    return <div className="live-operate-container">
        <SendMessage type={type} onPushComments={(value:string)=>{
            onPushComments && onPushComments(value)
        }} />

        <div className="operate-container">
            {/* <img src={messageIcon} onClick={() => {
                dispatch(setSendMessageModal(true));
            }} /> */}
            {gifts.length > 0 && <>
                <div className="gift-fist" onClick={() => { 
                    dispatch(openSendGiftModal(gift))
                }}>
                    <img src={`${gift?.imgBaseUri}${gift?.icon}`} />
                </div>
                <div className="gift-fist" >
                    <img src={giftIcon} onClick={() => {
                        dispatch(setGiftListModal(true))
                    }} />
                </div>
            </>}


        </div>
    </div>
}