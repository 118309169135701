import { AssetTypeEnum } from "@/features/accountClice";
import { RootState } from "@/store";
import { useAccount, useConnectKit } from "@particle-network/connect-react-ui";
import { useDispatch, useSelector } from "react-redux";
import edit from '@assets/h5Images/edit';
import copy from '@assets/h5Images/copy';
import { message } from "antd";
import cedis from '@assets/h5Images/cedis';
import bnb from '@assets/h5Images/bnb';
import { TransferModalTypeEnum, setTransferModal } from '@features/globalClice';
import { getAddress } from '@utils/stringFormat';
import './index.scss'
import { useEffect, useState } from "react";
import { followfans_Func } from "@/features/loginClice";
import { useNavigate } from "react-router-dom";
import Follow from '@H5Components/follow'

export default function Index() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [popupType,setPopupType]=useState<'Following' | 'Followers'>('Following')
    const [visible,setVisible]=useState<boolean>(false)
 
    const account = useAccount()
    const { VIPNFT, bnbBalance, marketUnitPrice, followfansNum, walletPogoBalance, sogabalance, userInfo } = useSelector((state: RootState) => {
        const allPogoBalance = state.accountModal.balance.find((item) => {
            return item.asset == AssetTypeEnum.POGO
        })
        return {
            userInfo: state.loginModule.userInfo,
            VIPNFT: state.nftModule.VIPNFT.length > 0 ? state.nftModule.VIPNFT[0] : null,
            sogabalance: allPogoBalance,
            bnbBalance: state.accountModal.bnbBalance,
            walletPogoBalance: state.accountModal.pogoBalance,
            marketUnitPrice: state.accountModal.marketUnitPrice,
            followfansNum: state.loginModule.followfansNum
        }
    })
    useEffect(() => {
        if (userInfo) {
            dispatch(followfans_Func())
        }
    }, [userInfo])
    return <>
        <div className="h5-personalPage-container">
            <div className="wallet-container">
                <div className="user-info">
                    <img className="head-img" src={userInfo?.avatar || 'https://pogo.ge81.com/headImg/1.jpg'} />
                    <div className="user-detal">
                        <div className="soga-name"> {userInfo?.name} <img className="img" src={edit} onClick={() => {
                            // props.onPersonalClick && props.onPersonalClick()
                            navigate("/personal")
                        }} /></div>
                        <div className="soga-id">  {getAddress(account || "")} <img className="img" src={copy} onClick={() => {
                            navigator.clipboard.writeText(account || '');
                            message.success('Copied to clipboard');
                        }} /></div>
                    </div>
                </div>

                <div className="follow-card">
                    <div className="item" onClick={()=>{
                        setPopupType("Following")
                        setVisible(true)
                    }}>
                        <div className="frist">{followfansNum.followCount || 0}</div>
                        <div className="end">Following</div>
                    </div>
                    <div className="middle"></div>
                    <div className="item" onClick={()=>{
                        setPopupType("Followers")
                        setVisible(true)
                    }}>
                        <div className="frist">{followfansNum.fansCount || 0}</div>
                        <div className="end">Followers</div>
                    </div>
                </div>

                <div className="card">
                    <div className="head">
                        <div className="title"> Spending </div>
                        <div className="btn" onClick={() => {
                            dispatch(setTransferModal({
                                show: true,
                                type: TransferModalTypeEnum.recharge
                            }))
                        }}> Withdraw </div>
                    </div>
                    <div className='body'>
                        <div className='item'>
                            <div className="left"><img src={cedis} /> SOGA</div>
                            <div className="right">
                                <div className="a">{sogabalance?.balance}</div>
                                <div className="b">{marketUnitPrice && marketUnitPrice.pogo && `$${(marketUnitPrice.pogo * sogabalance?.balance).toFixed(2)}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="head">
                        <div className="title"> Wallet </div>
                        {/* <div className="btn" onClick={() => {
                        connectKit.particle.openWallet()
                    }}> My wallet </div> */}
                    </div>
                    <div className='body'>
                        <div className='item'>
                            <div className="left"><img src={cedis} /> SOGA</div>
                            <div className="right">
                                <div className="a">{walletPogoBalance}</div>
                                <div className="b">{marketUnitPrice && marketUnitPrice.pogo && `$${(marketUnitPrice.pogo * walletPogoBalance).toFixed(2)}`}</div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="left"><img src={bnb} /> BNB</div>
                            <div className="right">
                                <div className="a">{bnbBalance}</div>
                                <div className="b">{marketUnitPrice && marketUnitPrice.bnb && `$${(marketUnitPrice.bnb * bnbBalance).toFixed(2)}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Follow type={popupType} visible={visible} onClose={()=>{
            setVisible(false)
        }} />
    </>
}