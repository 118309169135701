import React, { useState, useEffect, useRef } from 'react';
import './index.scss'
import { useSelector } from 'react-redux';
import MessageItem from './messageItem'
import { RootState } from '@/store';
import { List, CellMeasurer, CellMeasurerCache, AutoSizer } from 'react-virtualized';
 
 
// let isAutoScrollEnabled = true; 
let suo: boolean = false
let newMessage:any[]=[]
function ChatWindow() {
  const [messageList, setMessageList] = useState<any[]>([])
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<any>({
    auto: true,
    readCount: 0
  })
  const [readCount, setReadCount] = useState<number>(0)
  const [readId, setReadId] = useState<string>('')
  const messageContainerRef = useRef(null);



  const { orginMessageList } = useSelector((state: RootState) => {
    return {
      orginMessageList: state.roomModule.orginMessageList
    }
  })
  newMessage=orginMessageList; 
  useEffect(() => {
    if (isAutoScrollEnabled || (!isAutoScrollEnabled && messageList.length < 1000)) {
      if (newMessage.length > 0) {
        if (!suo) { 
          suo = true;
          setTimeout(() => {
            if (readId) {
              const index = newMessage.findIndex(obj => obj[2] == readId); 
              setReadCount(newMessage.length - index - 1)
            }
            suo = false
            setMessageList(newMessage) 
           
          }, 150);
        }
      }
    } else {
      if (readId) { 
        const index = orginMessageList.findIndex(obj => obj[2] == readId);
        setReadCount(orginMessageList.length - index - 1)
      }
    }
  }, [orginMessageList, isAutoScrollEnabled, readId])

 

  useEffect(() => {
    const listRef: any = messageContainerRef;
    if (listRef.current && isAutoScrollEnabled) {
      const bottomIndex = messageList.length - 1;
      listRef.current.scrollToRow(bottomIndex);
    }
  }, [messageList, isAutoScrollEnabled]);




  // 创建一个缓存对象，用于存储已测量的列表项高度
  const cache = new CellMeasurerCache({
    defaultHeight: 50, // 默认高度，可根据实际需求调整
    fixedWidth: true, // 如果列表项宽度是固定的，可以设置为true
  });


  const rowRenderer = ({ index, key, style, parent }: { index: number, key: string, style: any, parent: any }) => {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ measure }) => (
          <div key={messageList[index][2]} style={style} onLoad={measure}>
            <MessageItem key={messageList[index][2]} messageInfo={messageList[index]} />
          </div>
        )}
      </CellMeasurer>

    );
  };

  // 监听滚动事件
  const handleScroll = () => {
    const listRef: any = messageContainerRef;
    if (listRef.current) {
      const isAtBottom =
        listRef.current.Grid._rowStopIndex === messageList.length - 1;
      if (isAutoScrollEnabled != isAtBottom) {

        setIsAutoScrollEnabled(isAtBottom)
        if (isAtBottom) {
          setReadCount(0)
          setReadId('')
        } else {
          const lastItem = orginMessageList[orginMessageList.length - 1];
          setReadCount(0)
          setReadId(lastItem[2])
        }

      } 
    }
  };

  const toBottom = () => {
    const listRef: any = messageContainerRef;
    if (listRef.current) {
      const bottomIndex = messageList.length - 1;
      listRef.current.scrollToRow(bottomIndex);
    }
  }
  return (

    <div className="chat-window"  >
      {!isAutoScrollEnabled && <div onClick={toBottom} className="chat-window-tip">
        {readCount == 0 && <label> Back to the bottom</label>}
        {readCount >= 1000 && <label> Latest news</label>}
        {(readCount > 0 && readCount < 1000) && <label> {readCount} unread messages</label>}
      </div>}
      <AutoSizer>
        {({ height, width }) => (
          <List
            id="messagess"
            ref={messageContainerRef}
            height={height}
            onScroll={(event) => {
              // onChildScroll(event);
              handleScroll();
            }}
            rowCount={messageList.length}
            rowHeight={cache.rowHeight} // 列表项的高度
            rowRenderer={rowRenderer}
            width={width} // 列表的宽度
          />
        )}
      </AutoSizer>
 
    </div>
  );
}

export default React.memo(ChatWindow);
