
import { config } from '@/config';
import { AppThunk } from '@/store';
import { WebsocketService as wsSrv } from '@/utils/webSocket';
import { createSlice } from '@reduxjs/toolkit';
import { SilenceType, setSigeSilence, setSilenceType, setUserCount } from './roomClice';
import { v4 } from 'uuid'
import { openRedModal, showFailModal } from './globalClice';
import { getRoomRtcToken_fun } from './loginClice';
import { getRedPacket } from '@/api/gifts';
import { getLiveRanks_Func, getRecord_Func, getRedPacket_Func } from './giftClice';
import { message as msg } from 'antd'

interface WebSocketCliceState { // 定义初始化状态的类型
    messageList: any[],
    entryInfoList: any[],
    giftList: any[],
    blindBoxList: any[],
    rtcToken: string,
    rtmToken: string
    involuntaryLeave: boolean,
    channelName: string,
    loadGiftListStatus: LoadGiftListStatusEnum,
    currentGameId: number,     //当前游戏ID
    gameIsStart: boolean,       // 游戏是否开始
    gameMatchID: number,        //对局id
}

export enum LoadGiftListStatusEnum {
    Record = "Record",
    LiveRanks = 'LiveRanks',
    Empty = 'empty'
}

const initialState: WebSocketCliceState = {
    messageList: [],
    entryInfoList: [],
    giftList: [],
    blindBoxList: [],
    rtcToken: '',
    rtmToken: '',
    involuntaryLeave: false,
    channelName: '',
    loadGiftListStatus: LoadGiftListStatusEnum.Empty,  //当前需要刷新的数据是礼物流水还是 礼物排行
    currentGameId: 0,     //当前游戏ID
    gameIsStart: false,       // 游戏是否开始 
    gameMatchID: 0
}

const WebSocketClice = createSlice({
    name: 'WebSocketModule',
    initialState,
    reducers: {
        setMessageList(state, { payload }) {
            const p = payload.map((item: any) => {
                return {
                    0: item[0],
                    1: item[1],
                    2: item[2],
                    _id: item[2]
                }
            })
            const allMessage = state.messageList.concat(p);
            if (allMessage.length > 1000) {
                const newMessage = allMessage.slice(allMessage.length - 2000, allMessage.length);
                state.messageList = newMessage
            } else {
                state.messageList = allMessage
            }

        },
        clearMessageList(state, { payload }) {
            state.messageList = []
        },
        setEntryInfoList(state, { payload }) {
            state.entryInfoList = payload
        },
        setGiftList(state, { payload }) {
            state.giftList = state.giftList.concat(payload)
        },
        clearGiftList(state, { payload }) {
            state.giftList = payload
        },
        removeGiftList(state, { payload }) {
            const gifts = state.giftList.filter((item, index) => {
                return !(item[2] == payload)
            })
            state.giftList = gifts
        },

        setBlindBoxList(state, { payload }) {
            state.blindBoxList = state.blindBoxList.concat(payload)
        },
        clearBlindBoxList(state, { payload }) {
            state.blindBoxList = payload
        },
        removeBlindBoxList(state, { payload }) {
            const gifts = state.blindBoxList.filter((item, index) => {
                return !(item[2] == payload)
            })
            state.blindBoxList = gifts
        },
        setRtcToken(state, { payload }) {
            state.rtcToken = payload
        },
        setInvoluntaryLeave(state, { payload }) {
            state.involuntaryLeave = payload
        },
        setRtmToken(state, { payload }) {
            state.rtmToken = payload
        },
        setChannelName(state, { payload }) {
            state.channelName = payload
        },
        setLoadGiftListStatus(state, { payload }) {
            state.loadGiftListStatus = payload
        },
        setCurrentGameId(state, { payload }) {
            state.currentGameId = payload
        },
        setGameIsStart(state, { payload }) {
            state.gameIsStart = payload
        },
        setGameMatchID(state, { payload }) {
            state.gameMatchID = payload
        },
    },
});

export const { setMessageList, clearMessageList, clearGiftList, setEntryInfoList, setGiftList,
    setBlindBoxList, clearBlindBoxList, removeBlindBoxList, setRtcToken, removeGiftList, setInvoluntaryLeave,
    setRtmToken, setChannelName, setLoadGiftListStatus, setCurrentGameId, setGameIsStart, setGameMatchID } = WebSocketClice.actions


export const clearMessage = (): AppThunk => async (dispatch, getState) => {
    dispatch(clearMessageList([]))
    dispatch(setEntryInfoList([]))
    dispatch(clearGiftList([]))
    dispatch(setRtmToken(''))
    dispatch(setRtcToken(''))
}

const wsService = new wsSrv();

let roomId = ''
let tokenType: 1 | 2 | 3 = 1
export const WebsocketConnect = (token: string, roomId: string, tokenType: 1 | 2 | 3) => {
    roomId = roomId;
    tokenType = tokenType;
    wsService.roomId = roomId;
    wsService.tokenType = tokenType;
    wsService.connect(config.websocketServiceUrl, [token, roomId]);
}

export const webSocketClose = () => {
    wsService.onClose(undefined)
}

export const onMessageSubject = (): AppThunk => async (dispatch, getState) => {
    wsService.messageSubject.subscribe(
        (response: any) => {
            const data: string = response;
            console.log("webSocket message:", data)

            const type = data.substring(0, data.indexOf(':'))

            switch (type) {
                case MsgType.ack:
                    const ackMsg: any[] = data.split(':')
                    dispatch(ackMessage_Func(ackMsg));
                    break;
                case MsgType.hb:

                    break;
                case MsgType.chat:
                    // dispatch(chatMessage_Func(messageList));
                    break;
                case MsgType.kick:
                    const kickMsg: any[] = data.split(':')
                    dispatch(kickMessage_Func(kickMsg))
                    break;
                case MsgType.silenced:
                    const silencedMsg: any[] = data.split(':')
                    dispatch(silencedMessage_Func(silencedMsg))
                    break
                case MsgType.msg:
                    const messageList: any[] = JSON.parse(data.substring(data.indexOf(':') + 1, data.length))
                    dispatch(msgMessage_Func(type, messageList))
                    break;
                case MsgType.redpkt:
                    const redpktMsg: any[] = data.split(':')
                    dispatch(redpktMessage_Func(redpktMsg))
                    break;
                default:
                    break;
            }
        },
        (error: any) => { },
        () => { },
    );
}

export enum MsgType {
    ack = 'ack',            //应答消息
    hb = 'hb',              //心跳
    chat = 'chat',          //发送聊天消息
    kick = 'kick',          //强制离房消息
    silenced = 'silenced',  //禁言、解禁消息
    msg = 'msg',            //房间公共消息
    redpkt = 'lottery'       // 红包消息
}

/**
 * 应答消息
 *  消息方向：双向        
    消息格式：```ack:原消息ID(整型):应答码(字符串):错误消息|应答数据```    
    示例：原消息 ID = 123 的成功应答为 ```ack:123:0:```，错误应答为 ```ack:123:PM001:Not Found```    
    应答：无    
    _警告：错误消息或应答数据中中可能含有 ":"，在解析数据需要注意!_ 
 * @param message 
 * @returns 
 */
const ackMessage_Func = (message: string[]): AppThunk => async (dispatch, getState) => {

    switch (message[2]) {
        case 'PR001':
            wsService.onClose('')
            const result: any = await getRoomRtcToken_fun(roomId, tokenType);
            if (result.success) {
                wsService.connect(config.websocketServiceUrl, [result.data.rtmToken, roomId])
            }
            break;
        case 'PR002':
            dispatch(enterMessage_Func())
            break;
        case 'PS001':
            msg.error(message[3])
            break;
        default:
            break;
    }
}

/**
 * 发送聊天消息
 * 说明：向当前房间发送聊天消息    
    消息方向：客户端 > 服务端        
    消息格式：```chat:自定义息ID(整型):0:文本消息```     
    示例：消息 ID = 123，文本消息为 "hi!" ```chat:123:0:hi!```       
    应答：有
 * @param message 
 * @returns 
 */
export const chatMessage_Func = (message: string): AppThunk => async (dispatch, getState) => {
    const userInfo: any = getState().loginModule.userInfo
    if (userInfo) {
        // dispatch(setMessageList(["chat", [userInfo.name || userInfo.userId, message]]))
        wsService.sendMessage(`chat:${userInfo.userId}:${message}`);
    }
}

/**
 * 强制离房消息

说明：当用户被强制离开房间时发送此消息，消息发送后 WebSocket 将断开连接。    
消息方向：服务器 > 客户端        
消息格式：```kick:操作人ID(整型):操作人类型(1 主播，2 房管，3 系统):附加消息```     
示例：被房管(UID = 7890) 踢出房间 ```kick:7890:2:```          
应答：无

kick:1001:2:null =========datadatadatadata
 * @param message 
 * @returns 
 */
const kickMessage_Func = (message: string[]): AppThunk => async (dispatch, getState) => {


    let text = ""
    const type: any = message[2];
    if (type == 1) {
        text = "You have been forced to leave the live broadcast room by the host"
    } else if (type == 2) {
        text = "You have been forced to leave the live broadcast room by the administrator"
    } else if (type == 3) {
        text = message[3]
    }

    if (text) {
        await dispatch(showFailModal(text))
        await dispatch(setInvoluntaryLeave(true))
    }
}

/**
 * 禁言、解禁消息
说明：当用户禁言状态发生改变时发送此消息。    
消息方向：服务器 > 客户端        
消息格式：```silenced:状态(0 解禁，1 禁言):操作人ID(整型):操作人类型(1 主播，2 房管):被禁言人ID(0 表示全体禁言)```     
示例：被主播(UID = 10003) 禁言时 ```silenced:1:10003:1:10031```          
应答：无
 * @param message 
 * @returns 
 */
const silencedMessage_Func = (message: any[]): AppThunk => async (dispatch, getState) => {

    const sigeSilence = getState().roomModule.sigeSilence
    const silenceType = getState().roomModule.silenceType
    if (message[1] == 'true') {
        if (message[4] == 0) {
            dispatch(setSilenceType(SilenceType.All))
        } else if (silenceType != SilenceType.All) {
            dispatch(setSigeSilence(true));
            dispatch(setSilenceType(SilenceType.Sige))
        } else {
            dispatch(setSigeSilence(true));
            dispatch(setSilenceType(SilenceType.Sige))
        }

    } else {
        if (message[4] == 0 && sigeSilence) {
            dispatch(setSilenceType(SilenceType.Sige))
        } else if (message[4] == 0 && !sigeSilence) {
            dispatch(setSigeSilence(false));
            dispatch(setSilenceType(SilenceType.Send))
        } else if (message[4] != 0 && silenceType == SilenceType.All) {
            dispatch(setSigeSilence(false));
            dispatch(setSilenceType(SilenceType.All))
        } else {
            dispatch(setSigeSilence(false));
            dispatch(setSilenceType(SilenceType.Send))
        }

    }
    // dispatch(setMessageList([message]))
}
//房间公共消息类型
export enum RoomMsgType {
    chatMessage = 1,   //聊天消息
    entryNotification = 8,   //进房通知
    departureNotification = 9,   //离房通知
    forcedDepartureNotification = 4,   //强制离房通知
    giftNotification = 5,   //礼物通知 
    redPkNotification = 6,   //礼物通知 
    blindBoxNotification = 7,              //盲盒通知
    gameSelection = 10,     //游戏选定通知
    gameExit = 11,          //退出游戏
    gameOpening = 12,           //游戏开局通知
    gameEnd = 13,              //游戏结束
    pairwiseSettlement = 14,     //对局结算完成通知
}







/**
 * 
 * ### 2.6 房间公共消息

    说明：房间内的公聊、进出房、礼物等公共消息。    
    消息方向：服务器 > 客户端        
    消息格式：```msg:JSON 数组```          
    应答：无     
    JSON 数组格式：
    ```[消息类型(整型),消息参数1,消息参数2,...消息类型(整型),消息参数1,消息参数2,.... ]```    
    客户端解析出 JSON 数据后按顺序显示消息即可。
 * @param messages 
 * @returns 
 */

// let giftNotificationLocked: boolean = false;
// let giftNotificationStatus: number = 1;
let giftNotificationLocked: boolean = false;
let refreshCount = 0;
// let timerId;
let refreshTimerId: any;
const msgMessage_Func = (type: string, messageAll: any[]): AppThunk => async (dispatch, getState) => {

    const messages: any[] = [type, messageAll]
    while (messageAll.length > 0) {
        switch (messageAll[0]) {
            case RoomMsgType.chatMessage:
                const chatList = messageAll.splice(0, 5);
                const groupedArray = groupArray(type, chatList);
                dispatch(setMessageList(groupedArray));
                break
            case RoomMsgType.entryNotification:

                const entryList = messageAll.splice(0, 6);
                dispatch(setEntryInfoList(entryList));
                // const entryArray = groupArray(type, entryList);
                // dispatch(setMessageList(entryArray))
                dispatch(setUserCount(entryList[entryList.length - 1]))  //在线人数
                break;
            case RoomMsgType.departureNotification:

                const departureList = messageAll.splice(0, 6);

                const departureArray = groupArray(type, departureList);
                dispatch(setUserCount(departureList[departureList.length - 1]))   //在线人数 
                // dispatch(setEntryInfoList(departureArray));

                break;
            case RoomMsgType.giftNotification:
                const giftList = messageAll.splice(0, 6);
                const gifts = getState().giftModal.gifts;
                const giftgroupedArray = giftArray(type, gifts, giftList, 4);
                dispatch(setMessageList(giftgroupedArray))
                dispatch(setGiftList(giftgroupedArray));
                if (!giftNotificationLocked) {
                    dispatch(onLoadGiftList());
                }
                // if (messageAll.length > 0) {
                //     dispatch(msgMessage_Func(type, messageAll))
                // }
                break;
            case RoomMsgType.redPkNotification:
                const redPkList = messageAll.splice(0, 6);
                const redPkArray = groupArray(type, redPkList);
                dispatch(setMessageList(redPkArray))

                break
            case RoomMsgType.blindBoxNotification:

                const blindBoxList = messageAll.splice(0, 5);
                const footArry = messageAll.splice(0, blindBoxList[4]);

                const boxGifts = getState().giftModal.gifts;
                const boxArray = blindBoxArray(type, boxGifts, blindBoxList, footArry);
                await dispatch(setMessageList(boxArray))
                await dispatch(setBlindBoxList(boxArray));
                if (!giftNotificationLocked) {
                    dispatch(onLoadGiftList());
                }
                break
            case RoomMsgType.gameSelection:    //游戏选择
                const gameSelectionList = messageAll.splice(0, 6);
                dispatch(setCurrentGameId(gameSelectionList[gameSelectionList.length - 1]));   //存储游戏id
                // 执行游戏选择后的逻辑
                //
                //
                break;
            case RoomMsgType.gameExit:    //游戏退出
                const gameExitList = messageAll.splice(0, 6);
                dispatch(setCurrentGameId(0));   //  游戏退出
                // 执行游戏退出后的逻辑
                //
                //
                break;
            case RoomMsgType.gameOpening:    //游戏开始
                const gameOpeningList = messageAll.splice(0, 7);
                dispatch(setGameIsStart(true))
                dispatch(setGameMatchID(gameOpeningList[gameOpeningList.length - 1]))   //游戏开始 对局id存储
                break;
            case RoomMsgType.gameEnd:    //游戏结束
                const gameEndList = messageAll.splice(0, 7);
                dispatch(setGameIsStart(false))
                dispatch(setGameMatchID(0))   //游戏结束 对局id重置为0

                // 执行游戏退出后的逻辑
                //
                //
                break;
            case RoomMsgType.pairwiseSettlement:    //对局结算
                const pairwiseSettlementList = messageAll.splice(0, 6);
                break;
            case RoomMsgType.forcedDepartureNotification:
                messageAll = []
                break
            default:
                messageAll = []
                break;
        }
    }


}


const onLoadGiftList = (): AppThunk => async (dispatch, getState) => {

    const loadGiftListStatus = getState().webSocketModule.loadGiftListStatus;
    if (loadGiftListStatus == LoadGiftListStatusEnum.Empty) {
        return;
    }

    giftNotificationLocked = true
    clearInterval(refreshTimerId);

    const refreshGiftRank = () => {
        if (refreshCount > 1) {
            giftNotificationLocked = false
        }
        refreshCount++;
        const liveId = getState().roomModule.liveId
        if (loadGiftListStatus == LoadGiftListStatusEnum.Record) {
            dispatch(getRecord_Func(liveId))
        } else {
            dispatch(getLiveRanks_Func(liveId))
        }
        if (refreshCount > 3) {
            clearInterval(refreshTimerId);
            refreshCount = 0
            console.log("Refresh stopped.");
        }
    }
    setTimeout(() => {
        refreshCount = 1;
        refreshGiftRank();
        refreshTimerId = setInterval(refreshGiftRank, 5000);
    }, 1000);
}

/**
 * 礼物解析
 * @param type 
 * @param gifts 
 * @param array 
 * @param groupSize 
 * @param giftIdIndex 
 * @returns 
 */
function giftArray(type: string, gifts: any, array: any[], giftIdIndex: number) {
    const grouped = [];
    const newA = array;
    const gift = gifts.find((item: any) => {
        return item.giftId == newA[giftIdIndex]
    })
    newA.push(gift)
    grouped.push([type, newA, v4()]);


    return grouped;
}

/**
 * 
 * @param type ### 2.6.7 盲盒礼物通知
水 类型 ID: 7
参数 1: 发送人 ID，整型
参数 2: 发送人昵称，字符串
参数 3:发送人会员等级，整型。非会员为 0
参数 4: 礼物种类数量，整型
参数 5: 礼物 ID。
参数 6: 礼物数量,整型
参数 7: 礼物 ID
参数8: 礼物数量，整型
参数 N: 礼物数量，整型 (N = 礼物种类数量 X 2 + 4)
 * @param gifts 
 * @param array 
 * @returns 
 */
function blindBoxArray(type: string, gifts: any, array: any[], footArry: any[]) {
    const grouped = [];
    const groupedItem = []; 
    for (let i = 0; i < footArry.length; i += 2) {
        const newA = footArry.slice(i, i + 2);
        const gift = gifts.find((item: any) => {
            return item.giftId == newA[0]
        })
        newA.push(gift)  
        groupedItem.push(newA);
    }
    grouped.push([type, [...array, groupedItem], v4()])
    return grouped;
}


function groupArray(type: string, array: any[]) {
    const grouped = [];
    grouped.push([type, array, v4()]);
    // for (let i = 0; i < array.length; i += groupSize) {
    //     grouped.push([type, array.slice(i, i + groupSize), v4()]);
    // }
    return grouped;
}


// * 类型 ID：5
// * 参数 1：发送人 ID，整型
// * 参数 2：发送人 昵称，字符串
// * 参数 3：发送人 会员等级，整型。 非会员为 0
// * 参数 4：礼物 ID。
// * 参数 5：礼物数量，整型

// export const msgMessageUnit=(type:MsgType,message:string)=>{
//     const messageList: any = JSON.parse(message);

//     if(type==MsgType.msg){
//         switch (messageList[0]) {
//             case roomMsgType.chatMessage: 
//                 // * 类型 ID：1
//                 // * 参数 1：发送人 ID，整型
//                 // * 参数 2：发送人 昵称，字符串
//                 // * 参数 3：发送人 会员等级，整型。 非会员为 0
//                 // * 参数 4：消息文本
//                 return `${messageList[2]}${messageList[1]}:`
//             case roomMsgType.entryNotification:
//                 // ### 2.6.2 进房通知
//                 // * 类型 ID：2
//                 // * 参数 1：进房人 ID，整型
//                 // * 参数 2：进房人 昵称，字符串
//                 // * 参数 3：进房人 会员等级，整型。 非会员为 0
//                 break;
//             case roomMsgType.departureNotification:

//                 break;
//             case roomMsgType.giftNotification:

//                 break;
//             default:
//                 break;
//         } 
//     }

// }

// ### 2.6.10 游戏选定通知（开启游戏模式）
// * 类型 ID：10
// * 参数 1：主播 ID，整型
// * 参数 2：主播 昵称，字符串
// * 参数 3：主播 会员等级，整型。 非会员为 0
// * 参数 4：后续参数个数，整型
// * 参数 5：游戏 ID，整型

// ### 2.6.11 退出游戏通知（关闭游戏模式）
// * 类型 ID：11
// * 参数 1：主播 ID，整型
// * 参数 2：主播 昵称，字符串
// * 参数 3：主播 会员等级，整型。 非会员为 0
// * 参数 4：后续参数个数，整型。
// * 参数 5：游戏 ID，整型

// ### 2.6.12 游戏开局通知
// * 类型 ID：12
// * 参数 1：主播 ID，整型
// * 参数 2：主播 昵称，字符串
// * 参数 3：主播 会员等级，整型。 非会员为 0
// * 参数 4：后续参数个数，整型。
// * 参数 5：游戏 ID，整型
// * 参数 6：对局 ID，字符串

// ### 2.6.13 对局结束通知
// * 类型 ID：13
// * 参数 1：主播 ID，整型
// * 参数 2：主播 昵称，字符串
// * 参数 3：主播 会员等级，整型。 非会员为 0
// * 参数 4：后续参数个数，整型。
// * 参数 5：游戏 ID，整型
// * 参数 6：对局 ID，字符串

// ### 2.6.14 对局结算完成通知
// * 类型 ID：13
// * 参数 1：主播 ID，整型
// * 参数 2：主播 昵称，字符串
// * 参数 3：主播 会员等级，整型。 非会员为 0
// * 参数 4：后续参数个数，整型。
// * 参数 5：游戏 ID，整型
// * 参数 6：对局 ID，字符串

/**
### 2.7 红包消息

说明：当主播发送红包时发送此消息。     
消息方向：服务器 > 客户端        
消息格式：```redpkt:红包 ID(字符串)```     
示例：```redpkt:202306212495769296727475```          
应答：无
 * @param message 
 * @returns 
 */
export const redpktMessage_Func = (message: string[]): AppThunk => async (dispatch, getState) => {
    const showRed = getState().globalModule.redPkModal.showRed;
    const liveId = getState().roomModule.liveId;
    dispatch(getRedPacket_Func(liveId));
    if (!showRed) {   //如果没有在抢红包  就提示抢红包
        dispatch(openRedModal(message[1]))
    }
}
/**
 * 
### 2.9 重新进入房间
说明:重新进入房间，服务重启后会用到
消息方向:客户端 > 服务端消息格式:
"enter:自定义息ID(整型):token’
示例: 消息 ID = 123，token 为“abcdefg"enter:123:abcdefg
应答:有
 */
export const enterMessage_Func = (): AppThunk => async (dispatch, getState) => {
    const userInfo: any = getState().loginModule.userInfo;
    const token: any = getState().webSocketModule.rtcToken
    if (userInfo) {
        // dispatch(setMessageList(["chat", [userInfo.name || userInfo.userId, message]]))
        wsService.sendMessage(`chat:${userInfo.userId}:${token}`);
    }
}


export default WebSocketClice.reducer;