import { ChannelTypeEnum, setChannelType } from "@/features/globalClice";
import { useDispatch } from "react-redux";
import './index.scss'

 
export default function Index({ value, items, onClick }: {
    value: number | string,
    items: {
        value: string | number,
        text: string
    }[],
    onClick: (value: number | string) => void
}) {

    const dispatch = useDispatch()

    return <div className="h5-btn-group">
        {
            items.map((item: {
                value: string | number,
                text: string
            }) => {
                return <div key={item.value} className={value == item.value ? "active" : ''} onClick={async () => {
                    onClick && onClick(item.value)
                }}>{item.text}</div>
            })
        }
    </div>
}