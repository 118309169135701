import { getConfig } from '@/api';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { v4 } from 'uuid';


export enum TokenTypeEnum {
  live = 'live',
  view = 'view'
}


export enum TransferModalTypeEnum {
  recharge = 'recharge',
  reflect = 'reflect'

}

export enum ChannelTypeEnum{
  live=1,
  tv=2
}

interface GlobalModuleState {
  tipsModal: {
    showTips: boolean,
    tipsText: string,
    btnText: string,
    type: 'success' | 'fail',
  },
  serverConfig?: {
    pogoContractAddress: string,
    redPkMinAmount: number,
    pogoWithdrawFee: number,
    pogoWithdrawMinAmount: number
  },

  rewardModal: {
    showReward: boolean,
    amount: string
  },
  loadStat: boolean,
  redPkModal: {
    showRed: boolean,
    redPkId: string
  },
  transferModal: {
    show: boolean,
    type: TransferModalTypeEnum
  },
  refreshSign:string,
  windowId:string,
  isNewPageTab:boolean,
  autoplayFailed:boolean,
  channelType:ChannelTypeEnum,
  watchStop:boolean,

  sendGiftModal:{
    show: boolean,
    gift: any
  },
  giftListModal:boolean,
  inviterModal:boolean,
  boxResult:{
    show:boolean,
    data:any
  }
}

const initialState: GlobalModuleState = {
  tipsModal: {
    showTips: false,
    tipsText: '',
    btnText: "Close",
    type: 'success',
  },
  serverConfig: undefined,
  rewardModal: {
    showReward: false,
    amount: ''
  },
  loadStat: false,
  redPkModal: {
    showRed: false,
    redPkId: ''
  },
  transferModal: {
    show: false,
    type: TransferModalTypeEnum.recharge
  },
  refreshSign:v4(),
  windowId:v4(),
  isNewPageTab:false,
  autoplayFailed:false,
  channelType:ChannelTypeEnum.tv,
  watchStop:false,
  sendGiftModal: {
    show: false,
    gift: null
  },
  giftListModal:false,
  inviterModal:false,
  boxResult:{
    show:false,
    data:null
  }
}
const GlobalClice = createSlice({
  name: 'GlobalModule',
  initialState,
  reducers: {
    setTipsModal(state, { payload }) {
      state.tipsModal = payload
    },
    setRedModal(state, { payload }) {
      state.redPkModal = payload
    },
    setRewardModal(state, { payload }) {
      state.rewardModal = payload
    },
    setLoadStat(state, { payload }) {
      state.loadStat = payload
    },
    setServerConfig(state, { payload }) {
      state.serverConfig = payload
    },
    setTransferModal(state, { payload }) {
      state.transferModal = payload
    },
    setTransferModalType(state, { payload }) {
      state.transferModal.type = payload
    },
    setRefreshSign(state,{payload}){
      state.refreshSign=v4()
    },
    setIsNewPageTab(state,{payload}){
      state.isNewPageTab=payload
    },
    setAutoplayFailed(state,{payload}){
      state.autoplayFailed=payload
    },
    setChannelType(state,{payload}){
      state.channelType=payload
    },
    setWatchStop(state,{payload}){
      state.watchStop=payload
    },
    setSendGiftModal: function (state, { payload }) {
      state.sendGiftModal = payload;
    },
    setGiftListModal: function (state, { payload }) {
      state.giftListModal = payload
    },
    setInviterModal:function(state, { payload }) {
      state.inviterModal = payload;
    },
    setBoxResult: function (state, { payload }) {
      state.boxResult = payload;
    }
  },
});

export const { setTipsModal, setRedModal, setRewardModal, setLoadStat, setServerConfig, setTransferModal,setTransferModalType,setRefreshSign,setIsNewPageTab,setAutoplayFailed,setChannelType,setWatchStop,
  setSendGiftModal,setGiftListModal,setInviterModal,setBoxResult } = GlobalClice.actions;

  export const openSendGiftModal = (gift:any):AppThunk => async (dispatch, getState) => {
    dispatch(setSendGiftModal({
      show: true,
      gift: gift
    }))
  }
  export const closeSendGiftModal = ():AppThunk => async (dispatch, getState) => {
    dispatch(setSendGiftModal({
      show: false,
      gift: null
    }))
  }

export const showSuccessModal = (text: string, btnText?: string): AppThunk => async (dispatch, getState) => {
  dispatch(setTipsModal({
    showTips: true,
    tipsText: text,
    btnText: btnText || "Close",
    type: 'success'
  }))
}
export const showFailModal = (text: string, btnText?: string): AppThunk => async (dispatch, getState) => {
  dispatch(setTipsModal({
    showTips: true,
    tipsText: text,
    btnText: btnText || "Close",
    type: 'fail'
  }))
}
export const closeTipsModal = (): AppThunk => async (dispatch, getState) => {
  dispatch(setTipsModal({
    showTips: false,
    tipsText: '',
    btnText: "Close",
    type: 'fail'
  }))
}

export const openRedModal = (redPkId: string): AppThunk => async (dispatch, getState) => {
  dispatch(setRedModal({
    showRed: true,
    redPkId: redPkId
  }))
}

export const closeRedModal = (): AppThunk => async (dispatch, getState) => {
  dispatch(setRedModal({
    showRed: false,
    redPkId: ''
  }))
}

export const openRewardModal = (amount: string): AppThunk => async (dispatch, getState) => {
  dispatch(setRewardModal({
    showReward: true,
    amount: amount
  }))
}

export const closeRewardModal = (): AppThunk => async (dispatch, getState) => {
  dispatch(setRewardModal({
    showReward: false,
    amount: ''
  }))
}

export const openLoad = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoadStat(true))
}
export const closeLoad = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoadStat(false))
}
export const sleep = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}



export const getConfig_Func = (): AppThunk => async (dispatch, getState) => {
  const result = await getConfig();
  dispatch(setServerConfig(result))
}

export const openTransferModal = (type:TransferModalTypeEnum): AppThunk => async (dispatch, getState) => {
  dispatch(setTransferModal({
    show:true,
    type:type
  }))
}
export const closeTransferModal = (): AppThunk => async (dispatch, getState) => {
  dispatch(setTransferModal({
    show:false,
    type:TransferModalTypeEnum.recharge
  }))
}

export const openBoxResult = (data:any):AppThunk => async (dispatch, getState) => {
  dispatch(setBoxResult({
    show: true,
    data: data
  }))
}

export const closeBoxResult = ():AppThunk => async (dispatch, getState) => {
  dispatch(setBoxResult({
    show: false,
    data: null
  }))
}

// export const emoSvg = [
//   { path: '/emo/EMOJI-01.png', name: '[01]' },
//   { path: '/emo/EMOJI-02.png', name: '[02]' },
//   { path: '/emo/EMOJI-03.png', name: '[03]' },
//   { path: '/emo/EMOJI-04.png', name: '[04]' },
//   { path: '/emo/EMOJI-05.png', name: '[05]' },
//   { path: '/emo/EMOJI-06.png', name: '[06]' },
//   { path: '/emo/EMOJI-07.png', name: '[07]' },
//   { path: '/emo/EMOJI-08.png', name: '[08]' },
//   { path: '/emo/EMOJI-09.png', name: '[09]' },
//   { path: '/emo/EMOJI-10.png', name: '[10]' },
//   { path: '/emo/EMOJI-11.png', name: '[11]' },
//   { path: '/emo/EMOJI-12.png', name: '[12]' },
//   { path: '/emo/EMOJI-13.png', name: '[13]' },
//   { path: '/emo/EMOJI-14.png', name: '[14]' },
//   { path: '/emo/EMOJI-15.png', name: '[15]' },
//   { path: '/emo/EMOJI-16.png', name: '[16]' },
//   { path: '/emo/EMOJI-17.png', name: '[17]' },
//   { path: '/emo/EMOJI-18.png', name: '[18]' },
//   { path: '/emo/EMOJI-19.png', name: '[19]' },
//   { path: '/emo/EMOJI-20.png', name: '[20]' },
//   { path: '/emo/EMOJI-21.png', name: '[21]' },
//   { path: '/emo/EMOJI-22.png', name: '[22]' },
//   { path: '/emo/EMOJI-23.png', name: '[23]' },
//   { path: '/emo/EMOJI-24.png', name: '[24]' },
//   { path: '/emo/EMOJI-25.png', name: '[25]' },
//   { path: '/emo/EMOJI-26.png', name: '[26]' },
//   { path: '/emo/EMOJI-27.png', name: '[27]' },
//   { path: '/emo/EMOJI-28.png', name: '[28]' },
//   { path: '/emo/EMOJI-29.png', name: '[29]' },
//   { path: '/emo/EMOJI-30.png', name: '[30]' },
//   { path: '/emo/EMOJI-31.png', name: '[31]' },
//   { path: '/emo/EMOJI-32.png', name: '[32]' },
//   { path: '/emo/EMOJI-33.png', name: '[33]' },
//   { path: '/emo/EMOJI-34.png', name: '[34]' },
//   { path: '/emo/EMOJI-35.png', name: '[35]' },
//   { path: '/emo/EMOJI-36.png', name: '[36]' },
//   { path: '/emo/EMOJI-37.png', name: '[37]' },
//   { path: '/emo/EMOJI-38.png', name: '[38]' },
//   { path: '/emo/EMOJI-39.png', name: '[39]' },
//   { path: '/emo/EMOJI-40.png', name: '[40]' },
//   { path: '/emo/EMOJI-51.png', name: '[51]' },
//   { path: '/emo/EMOJI-52.png', name: '[52]' },
//   { path: '/emo/EMOJI-53.png', name: '[53]' },
//   { path: '/emo/EMOJI-54.png', name: '[54]' },
//   { path: '/emo/EMOJI-55.png', name: '[55]' },
//   { path: '/emo/EMOJI-56.png', name: '[56]' },
//   { path: '/emo/EMOJI-57.png', name: '[57]' },
//   { path: '/emo/EMOJI-58.png', name: '[58]' },
//   { path: '/emo/EMOJI-59.png', name: '[59]' },
//   { path: '/emo/EMOJI-60.png', name: '[60]' },
//   { path: '/emo/EMOJI-61.png', name: '[61]' },
//   { path: '/emo/EMOJI-62.png', name: '[62]' },
//   { path: '/emo/EMOJI-63.png', name: '[63]' },
//   { path: '/emo/EMOJI-64.png', name: '[64]' },
//   { path: '/emo/EMOJI-65.png', name: '[65]' },
//   { path: '/emo/EMOJI-66.png', name: '[66]' },
//   { path: '/emo/EMOJI-67.png', name: '[67]' },
//   { path: '/emo/EMOJI-68.png', name: '[68]' },
//   { path: '/emo/EMOJI-69.png', name: '[69]' }

// ]

export const emoSvg: any = {
  '[Angry]': '/emo/Angry.png',
  '[Annoyed]': '/emo/Annoyed.png',
  '[Astounded]': '/emo/Astounded.png',
  '[Bald]': '/emo/Bald.png',
  '[Blush]': '/emo/Blush.png',
  '[Blushes]': '/emo/Blushes.png',
  '[Blushing]': '/emo/Blushing.png',
  '[Bruh]': '/emo/bruh.png',
  '[Cool]': '/emo/Cool.png',
  '[Cried]': '/emo/Cried.png',
  '[Crying]': '/emo/Crying.png',
  '[Damn]': '/emo/Damn.png',
  '[Dead]': '/emo/Dead.png',
  '[Dizzy]': '/emo/Dizzy.png',
  '[Dozing]': '/emo/Dozing.png',
  '[Dumbed]': '/emo/Dumbed.png',
  '[Dumbfounded]': '/emo/Dumbfounded.png',
  '[Dumbstruck]': '/emo/Dumbstruck.png',
  '[Fancy]': '/emo/Fancy.png',
  '[Flared]': '/emo/Flared.png',
  '[Grin]': '/emo/Grin.png',
  '[Haha]': '/emo/Haha.png',
  '[Happy]': '/emo/Happy.png',
  '[IKYK]': '/emo/IKYK.png',
  '[Indifference]': '/emo/indifference.png',
  '[Irritates]': '/emo/Irritates.png',
  '[Joyful]': '/emo/Joyful.png',
  '[Like]': '/emo/Like.png',
  '[Lively]': '/emo/Lively.png',
  '[LOLz]': '/emo/LOLz.png',
  '[Mad]': '/emo/Mad.png',
  '[Money]': '/emo/Money.png',
  '[Nap]': '/emo/Nap.png',
  '[Nuu]': '/emo/Nuu.png',
  '[OMO]': '/emo/OMO.png',
  '[Overjoyed]': '/emo/overjoyed.png',
  '[OWO]': '/emo/OWO.png',
  '[Pissed]': '/emo/Pissed.png',
  '[Please]': '/emo/Please.png',
  '[Radiant]': '/emo/Radiant.png',
  '[Radiantly]': '/emo/Radiantly.png',
  '[Rejected]': '/emo/Rejected.png',
  '[Sarcastic]': '/emo/sarcastic.png',
  '[Shocking]': '/emo/Shocking.png',
  '[Sick]': '/emo/Sick.png',
  '[Silent]': '/emo/Silent.png',
  '[Sleep]': '/emo/Sleep.png',
  '[Sleepless]': '/emo/Sleepless.png',
  '[Smile]': '/emo/Smile.png',
  '[Smiley]': '/emo/Smiley.png',
  '[Smiling]': '/emo/Smiling.png',
  '[Smirk]': '/emo/Smirk.png',
  '[Smirking]': '/emo/Smirking.png',
  '[Sob]': '/emo/Sob.png',
  '[Sobbing]': '/emo/Sobbing.png',
  '[Speechless]': '/emo/Speechless.png',
  '[Sweat]': '/emo/Sweat.png',
  '[Tasty]': '/emo/tasty.png',
  '[Upset]': '/emo/Upset.png',
  '[Urgh]': '/emo/Urgh.png',
  '[Wao]': '/emo/Wao.png',
  '[Weep]': '/emo/weep.png',
  '[Weeping]': '/emo/weeping.png',
  '[Weepy]': '/emo/Weepy.png',
  '[Well]': '/emo/well.png',
  '[What]': '/emo/What.png',
  '[Wink]': '/emo/Wink.png',
  '[Wow]': '/emo/Wow.png',
  '[Yawn]': '/emo/Yawn.png'
}

export default GlobalClice.reducer;