import { RootState } from "@/store";
import React,{ useEffect, useState } from "react" ;
import { useSelector } from "react-redux";

export default function Index() {
    const [liveTimeLong, setLiveTimeLong] = useState<number>(1000);
    const [liveTimeLongText, setLiveTimeLongText] = useState<string>('00:00:00');
    const { roomInfo } = useSelector((state:RootState) => {
        return {
            roomInfo: state.roomModule.roomInfo
        }
    })
    useEffect(() => {
        if (roomInfo) {
            setLiveTimeLong(roomInfo.timeLong)
        }
    }, [roomInfo])


    useEffect(() => {
        const timeout = setTimeout(() => {
            const timeLong = liveTimeLong + 1000;
            setLiveTimeLong(timeLong);
            const timeText = formatTime(timeLong)
            setLiveTimeLongText(timeText)
        }, 1000)
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [liveTimeLong])

    return roomInfo ? <div className="tools-icons">
        {liveTimeLongText}
    </div> : <></>
}

function formatTime(milliseconds:number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let formattedTime = '';

    // if (hours > 0) {
    formattedTime += `${hours.toString().padStart(2, '0')}:`;
    // }

    // if (hours > 0 || minutes > 0) {
    formattedTime += `${minutes.toString().padStart(2, '0')}:`;
    // }

    formattedTime += seconds.toString().padStart(2, '0');

    return formattedTime;
}
