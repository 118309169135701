import { applyLock, applyUnlock, getLockedRoom, getLockedVip, getRoomContractAddress, getVipCallData, getVipContractAddress, isReceivedVip, queryNftAct, receiveVip, upgradeNft } from '@/api/nfts';
import { getJsonFecth, getTokens, particle_abi_encodeFunctionCall, particle_abi_eth_call, particle_swap_checkApprove } from '@/api/seaport';
import { config } from '@/config';
import { addHexPrefix, intToHex } from '@particle-network/auth';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { message } from 'antd';
import { disConnect_Func, userInfo_Func } from './loginClice';
import { getRoomNFTCallData, receiveRoomNFT, receiveStatus } from '@/api/room';
import { AbiItem } from 'web3-utils';
import { closeLoad, openLoad, showFailModal } from './globalClice';
import { getUserRooms_Func } from './roomClice';
import { messageUtil } from '@/utils/tools';


interface LoginCliceState {
    VIPNFT: any[],
    vipContractAddress: string,
    vipNFTImage: string,
    roomNFTs: any[],
    roomContractAddress: string,
    roomNFTImage: string,
    isReceivedVip: boolean,
    isReceiveRoom: 1 | 2 | 3,    //  1未领取   2已领取    3领取失败
    lockedRoomList: any[],
    lockedVipList: any[],
    newVipNfts: [],

}


const initialState: LoginCliceState = {
    VIPNFT: [],
    vipContractAddress: '',
    vipNFTImage: '',
    roomNFTs: [],
    roomContractAddress: '',
    roomNFTImage: '',
    isReceivedVip: false,
    isReceiveRoom: 1,
    lockedRoomList: [],
    lockedVipList: [],
    newVipNfts: []
}
const NftClice = createSlice({
    name: 'NftModule',
    initialState,
    reducers: {
        setVIPNFT(state, { payload }) {
            state.VIPNFT = payload
        },
        setVipContractAddress(state, { payload }) {
            state.vipContractAddress = payload
        },
        setVipNFTImage(state, { payload }) {
            state.vipNFTImage = payload
        },
        setRoomNFTs(state, { payload }) {
            state.roomNFTs = payload
        },
        setRoomContractAddress(state, { payload }) {
            state.roomContractAddress = payload
        },
        setRoomNFTImage(state, { payload }) {
            state.roomNFTImage = payload
        },
        setIsReceivedVip(state, { payload }) {
            state.isReceivedVip = payload
        },
        setLockedRoomList(state, { payload }) {
            state.lockedRoomList = payload
        },
        setLockedVipList(state, { payload }) {
            state.lockedVipList = payload
        },
        setIsReceiveRoom(state, { payload }) {
            state.isReceiveRoom = payload
        },
        setNewVipNfts(state, { payload }) {
            state.newVipNfts = payload
        }
    },
});

export const { setVIPNFT, setIsReceivedVip, setRoomNFTs, setLockedRoomList, setLockedVipList, setVipContractAddress, setRoomContractAddress, setIsReceiveRoom, setNewVipNfts, setVipNFTImage, setRoomNFTImage } = NftClice.actions;





/**
 * 初始化数据
 * @param chainId 
 * @param account 
 * @returns 
 */
export const nftInit_Func = (chainId: number, account: string): AppThunk => async (dispatch, getState) => {

    dispatch(getContractAddress_Func(chainId, account));
    dispatch(isReceivedVip_Func());
    dispatch(receiveStatus_Func());

}

/**
 * 检查是否为VIP NFT
 * @param chainId 
 * @param account 
 * @param f 
 * @returns 
 */
export const getContractAddress_Func = (chainId: number, account: string, f?: Function): AppThunk => async (dispatch, getState) => {
    if (!config.chainIds.includes(chainId)) {
        messageUtil("Wrong network", "error")
        f && f();
        return
    }
    dispatch(openLoad())
    const result: any = await getVipContractAddress();
    const roomContractResult: any = await getRoomContractAddress();
    const nftResult = await getTokens(chainId, [account]);
    if (result.success && nftResult.status == 200) {
        const list: any[] = nftResult.data.result;
        const containsAddress = list.filter(obj => obj.address.toUpperCase() == result.data.toUpperCase());
        dispatch(setVIPNFT(containsAddress));
        dispatch(setVipContractAddress(result.data));
        localStorage.setItem('vipContractAddress', result.data)
        if (containsAddress.length > 0) {
            localStorage.setItem('vipTokenId', containsAddress[0].tokenId)
        }else{
            localStorage.setItem('vipTokenId', "")
        }
        // dispatch(GetImageUrl_Func(chainId, account, result.data, (image: string) => {
        //     console.log(image)
        //     dispatch(setVipNFTImage(image))
        // }))
    }
    if (roomContractResult.success && nftResult.status == 200) {
        const list: any[] = nftResult.data.result;
        const roomNFTList = list.filter(obj => obj.address.toUpperCase() == roomContractResult.data.toUpperCase());
        dispatch(setRoomNFTs(roomNFTList));
        dispatch(setRoomContractAddress(roomContractResult.data))
        // dispatch(GetImageUrl_Func(chainId, account, roomContractResult.data, (image: string) => {
        //     dispatch(setRoomNFTImage(image))
        // }))
    }
    dispatch(closeLoad())



}

// export const GetImageUrl_Func = (chainId: number, account: string, contractAddress: string, cb?: Function): AppThunk => async (dispatch, getState) => {
//     const multicallAbi =
//     {
//         name: "contractURI",
//         stateMutability: "view",
//         type: "function",
//         inputs: [],
//         outputs: [{ internalType: "string", name: "", type: "string" }]
//     };

//     const abistr = JSON.stringify(multicallAbi)
//     const methodName = "custom_contractURI";
//     const abiJson = `[${abistr}]`;
//     const params: any = [contractAddress, methodName, [], abiJson]
//     const address = account;



//     const res = await particle_abi_encodeFunctionCall(chainId, params);
//     const value = '0x0';
//     const txnParams = {
//         from: address,
//         to: contractAddress,
//         value: value,
//         data: res.data.result
//     };

//     const result = await particle_abi_eth_call(chainId, [txnParams, "latest"]);
//     const offset = parseInt(result.data.result.substr(2, 64), 16)
//     const length = parseInt(result.data.result.substr(66, 64), 16)
//     const jsonUrl = result.data.result.substr(offset * 2 + 64 + 2, length * 2);
//     // 按照utf8编码解析
//     const jsonPath = Buffer.from(jsonUrl, 'hex').toString('utf8');
//     try {
//         const jsonResult: any = await getJsonFecth(jsonPath);
//         cb && cb(jsonResult.image)
//     } catch (error) {

//     }
// }

/**
 * 是否领取了Vip NFT 
 * @returns  
 */
export const isReceivedVip_Func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await isReceivedVip();
    if (result.success) {
        dispatch(setIsReceivedVip(result.data))
    }
}
/**
 * 返回已锁定的 VIP NFT TokenID
 * @returns 
 */
export const getLockedVip_Func = (): AppThunk => async (dispatch, getState) => {
    const contractAddress = getState().nftModule.vipContractAddress;
    const result: any = await getLockedVip();

    if (result.success) {
        let newArry = []
        for (let index = 0; index < result.data.length; index++) {
            const element = result.data[index];
            const tokenInfo = await getTokenURI(contractAddress, element);
            const resultINfo: any = await queryNftAct(element)

            newArry.push({
                tokenId: element,
                locked: true,
                ...tokenInfo,
                ...(resultINfo.success ? resultINfo.data : [])
            })
        }
        dispatch(setLockedVipList(newArry))
    }

}
/**
 * 返回已锁定的房间 NFT TokenID 
 * @returns 
 */
export const getLockedRoom_Func = (): AppThunk => async (dispatch, getState) => {
    const contractAddress = getState().nftModule.roomContractAddress;
    const result: any = await getLockedRoom();

    if (result.success) {
        let newArry = []
        for (let index = 0; index < result.data.length; index++) {
            const element = result.data[index];
            const tokenInfo = await getTokenURI(contractAddress, element)
            newArry.push({
                tokenId: element,
                ...tokenInfo
            })
        }
        dispatch(setLockedRoomList(newArry))
    }

}


/**
 * 领取 VIP NFT 
 * @param chainId 
 * @param address 
 * @param cb 
 * @returns 
 */
export const receiveVip_Func = (chainId: number, address: string, cb?: Function): AppThunk => async (dispatch, getState) => {
    dispatch(openLoad())
    const isReceivedVip = getState().nftModule.isReceivedVip;
    try {
        if (!isReceivedVip) {
            let result: any = await receiveVip();
            dispatch(multicall_Func(chainId, address, result, (e: boolean) => {
                dispatch(isReceivedVip_Func())
                dispatch(closeLoad())
            }));
        }else{
            messageUtil('This address has already claimed NFT')
        }
        dispatch(closeLoad())
    } catch (error: any) {
        messageUtil(error.message, "error")
        dispatch(closeLoad())
    }


}

/**
 * 重新生成领取 VIP NFT 数据 
 * @param chainId 
 * @param address 
 * @param cb 
 * @returns 
 */
export const getVipCallData_Func = (chainId: number, address: string, cb?: Function): AppThunk => async (dispatch, getState) => {
    dispatch(openLoad())
    let result: any = await getVipCallData();
    dispatch(multicall_Func(chainId, address, result, (e: boolean) => {
        dispatch(closeLoad())
    }));
}
/**
 * 申请锁定
 * @param chainId 
 * @param address 
 * @param result 
 * @returns 
 */
export const applyLock_Func = (chainId: number, account: any, contract: string, tokenId: string, type: 'ROOMNFT' | 'VIPNFT'): AppThunk => async (dispatch, getState) => {
    dispatch(openLoad())
    let result: any = await applyLock(contract || '', tokenId);

    try {
        const abi: AbiItem[] = [
            {
                "inputs": [
                    {
                        "name": "_spender",
                        "type": "address"
                    },
                    {
                        "name": "_tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            }
        ]
        const Oject = new window.web3.eth.Contract(abi, contract);

        const txnParams = {
            from: account,
            gasPrice: window.web3.utils.toWei('3', 'gwei')
        };
        await Oject.methods.approve(result.data.contractAddress, tokenId).send(txnParams);
        dispatch(multicall_Func(chainId, account, result, (e: boolean) => {
            if (type === "ROOMNFT") {
                dispatch(getLockedRoom_Func())
            } else {
                dispatch(getLockedVip_Func())
            }
            dispatch(closeLoad())
        }))
    } catch (error: any) {
        if (error.code === 4001) {
            console.log('Reject transaction')
        } else {
            messageUtil(error.message, "error")
        }
        dispatch(closeLoad())
    }

}





/**
 * 申请解锁
 * @param chainId 
 * @param address 
 * @param result 
 * @returns 
 */
export const applyUnlock_Func = (chainId: number, account: string, tokenId: string, type: 'ROOMNFT' | 'VIPNFT', cb?: Function): AppThunk => async (dispatch, getState) => {
    dispatch(openLoad())
    const roomContractAddress = type === "ROOMNFT" ? getState().nftModule.roomContractAddress : getState().nftModule.vipContractAddress
    let result: any = await applyUnlock(roomContractAddress, tokenId);
    dispatch(multicall_Func(chainId, account, result, (e: boolean) => {

        if (type === "ROOMNFT") {
            dispatch(getLockedRoom_Func())
        } else {
            dispatch(getLockedVip_Func())
        }
        dispatch(closeLoad())
        cb && cb();

    }))
}

/**
 * 领取房间NFG
 * @returns 
 */
export const receiveRoomNFT_Func = (chainId: number, address: string): AppThunk => async (dispatch, getState) => {
    dispatch(openLoad())
    const result: any = await receiveRoomNFT();
    if (result.success) {
        dispatch(multicall_Func(chainId, address, result, (e: boolean) => {
            dispatch(receiveStatus_Func())
            dispatch(closeLoad())
        }))
    } else {
        dispatch(closeLoad())
    }
}
/**
 * 查询房间NFT 状态
 * @returns 
 */
export const receiveStatus_Func = (): AppThunk => async (dispatch, getState) => {
    const result: any = await receiveStatus();
    if (result.success) {
        dispatch(setIsReceiveRoom(result.data))
    }
}
/**
 * 重新生成领取 房间 NFT 数据 
 * @param chainId 
 * @param address 
 * @returns 
 */
export const getRoomNFTCallData_Func = (chainId: number, address: string): AppThunk => async (dispatch, getState) => {
    dispatch(openLoad())
    const result: any = await getRoomNFTCallData();
    if (result.success) {
        dispatch(multicall_Func(chainId, address, result, () => {
            dispatch(closeLoad())
        }))
    } else {
        dispatch(closeLoad())
    }
}





/**
 * 执行合约操作
 * @param chainId 
 * @param address 
 * @param result 
 * @returns 
 */
const multicall_Func = (chainId: number, address: string, result: any, cb?: Function): AppThunk => async (dispatch, getState) => {
    if (result.success) {
        try {
            const params = [result.data.contractAddress, `custom_multicall`, [result.data.params], "[{\"inputs\":[{\"internalType\":\"uint256[]\",\"name\":\"data\",\"type\":\"uint256[]\"}],\"name\":\"multicall\",\"outputs\":[],\"stateMutability\":\"payable\",\"type\":\"function\"}]"]
            const res = await particle_abi_encodeFunctionCall(chainId, params)
            const fromAddress = address;
            const toAddress = result.data.contractAddress;
            const value = result.data.value;
            const gasEstimate = await window.web3.eth.estimateGas({
                from: fromAddress,
                to: toAddress,
                value: value,
                data: res.data.result
            });
            const txnParams = {
                from: address,
                to: toAddress,
                value: value,
                data: res.data.result,
                gasPrice: window.web3.utils.toWei('3', 'gwei'),
                gasLimit: addHexPrefix(intToHex(gasEstimate))
            };
            console.log(txnParams)
            // dispatch(setLoading(false))
            window.web3.eth.sendTransaction(txnParams, (error: any, hash) => {
                if (error) {
                    if (error.code !== 4011) {
                        if (error.code == 10005) {
                            dispatch(disConnect_Func());
                        } else if (error.code == -32603) {
                            messageUtil('transaction underpriced', "error")
                        }
                    }
                    cb && cb(false)
                } else {

                    setTimeout(() => {
                        console.log("Successful operation")
                        dispatch(userInfo_Func())
                        dispatch(nftInit_Func(chainId, address))
                        dispatch(getUserRooms_Func())
                        cb && cb(true)
                    }, 20 * 1000)
                    // cb && cb(`You have successfully applied for it. There will be a delay in the receipt time of the assets on the chain. Please wait patiently for 1 to 10 minutes.`)
                }
            });
        } catch (error: any) {
            cb && cb(false)
            if (error.message.includes('token already minted')) {
                if (process.env.REACT_APP_ISMOBILE === 'true') {
                    messageUtil("token already minted", "error")
                } else {
                    dispatch(showFailModal("token already minted"))
                }
            } else {
                messageUtil(error.message, "error")
            }

        }
    }
}

/**
 * 获取图片
 * @param contractAddress 
 * @param tokenId 
 * @returns 
 */
export const getTokenURI = async (contractAddress: string, tokenId: any) => {
    console.log('tokenId:', contractAddress, tokenId);
    const contractABI: AbiItem[] = [
        {
            "name": "tokenURI",
            "type": "function",
            "inputs": [
                {
                    "name": "_tokenId",
                    "type": "uint256"
                }
            ],
            "outputs": [
                {
                    "name": "uri",
                    "type": "string"
                }
            ],
            "constant": true // 可能不是所有合约都有这个字段，根据实际情况进行调整
        }
    ]

    // 实例化合约
    const contractInstance = new window.web3.eth.Contract(contractABI, contractAddress);
    try {
        const tokenURI = await contractInstance.methods.tokenURI(tokenId).call();
        const jsonResult: any = await getJsonFecth(tokenURI);
        return jsonResult;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}


/**
 * 升级vip NFT
 * @param nftId 
 * @returns 
 */
export const upgradeNft_Func = (chainId: number, address: string, nftId: string, cb?: Function): AppThunk => async (dispatch, getState) => {

    dispatch(openLoad())
    const queryNftActResult: any = await queryNftAct(nftId);
    if (queryNftActResult.success) {
        if (queryNftActResult.data.upgrade) {
            const result: any = await upgradeNft(nftId);
            if (result.success) {
                dispatch(multicall_Func(chainId, address, result, () => {
                    dispatch(closeLoad())
                    cb && cb(true)
                }))
            }
        } else {
            cb && cb(false)
            // dispatch(showFailModal(""))
        }
    } else {
        cb && cb(false)
    }
}
/**
 * 查询nft活跃度
 * @param nftId 
 * @param cb 
 * @returns 
 */
export const queryNftAct_Func = (nftId: string, cb?: (result: any) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await queryNftAct(nftId);
    if (result.success) {
        cb && cb(result.data)
        return result.data
    } else {
        cb && cb(null)
        return null;
    }
}


export const particle_swap_checkApprove_Func = (walletAddress: string, tokenAddress: string, amount: string): AppThunk => async (dispatch, getState) => {
    const result: any = await particle_swap_checkApprove(walletAddress, tokenAddress, amount)
}
export default NftClice.reducer;