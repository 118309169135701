import ButtonGroup from "@/H5Components/buttonGroup";
import './index.scss';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import newbie from '@assets/h5Images/newbie';
import invnting from '@assets/h5Images/invnting';
import box from '@assets/h5Images/box'
import cedis from '@assets/h5Images/cedis'
import twitter from '@assets/h5Images/twitter';
import { useNavigate } from "react-router-dom";

const listData = [{
    id: 1,
    icon: newbie,
    btnText: "Ongoing",
    img: box,
    boxCount: 1,
    title: "Newbie Quest",
    subTitle: "Complete Newbie Quest first to see other Quests",
    status: 1,
    pageUrl: "/questinfo"
    // }, {
    //     id: 2,
    //     icon: twitter,
    //     btnText: "Daily",
    //     img: box,
    //     boxCount: 2,
    //     title: "Claim chest on our profile",
    //     subTitle: "Go to @Gameta_Official profile to Claim Chests",
    //     status: 1
}, {
    id: 3,
    icon: invnting,
    btnText: "Ongoing",
    img: cedis,
    boxCount: 1,
    title: "Inviting Quest",
    subTitle: "The more invitations there are, the more rewards there will be",
    status: 1,
    pageUrl: "/invitingquest"
}]
export default function Index() {

    const navigate = useNavigate();
    const [status, setStatus] = useState<number | string>(1);
    const { taskList } = useSelector((state: RootState) => {
        return {
            taskList: state.taskModule.taskList
        }
    })

    useEffect(() => {
        var one: boolean = true;
        for (let index = 0; index < taskList.length; index++) {
            const element = taskList[index];
            if (element.task.id == 10003 || element.task.id == 10005 || element.task.id == 10006) {
                if (element.data.taskStatus != 2) {
                    one = false
                }
                // } else if (element.task.id == 10007 && element.data.taskStatus == 2) {
                //     listData[1].status = 2;
                //     listData[1].btnText = "Complete"
            } else if (element.task.id == 10008 && element.data.taskStatus == 2) {
                listData[2].status = 2;
                listData[2].btnText = "Complete"
            }
        }
        if (one) {
            listData[0].status = 2;
            listData[0].btnText = "Complete"
        } else {
            listData[0].status = 1;
            listData[0].btnText = "Ongoing"
        }
    }, [taskList])


    return <div className="h5-soga-quest-container">
        <ButtonGroup onClick={(value: number | string) => {
            setStatus(value)
        }}
            value={status}
            items={[
                {
                    value: 1,
                    text: 'Available Quests'
                },
                {
                    value: 2,
                    text: 'Done Quests'
                }
            ]} />

        {
            listData.filter((item) => (item.status == status)).map((item) => {
                return <div className="h5-questItem" key={item.id} onClick={() => {
                    // onItemClick && onItemClick(item.id); 
                    navigate(item.pageUrl)
                }}>
                    <div className="top">
                        <img className="img" src={item.icon} />
                        <div className="btn">{item.btnText}</div>
                    </div>
                    <div className='box-line'>
                        <img src={item.img} />
                        <label>x{item.boxCount}</label>
                    </div>
                    <div className="title">
                        {item.title}
                    </div>
                    <div className="subTitle">
                        {item.subTitle}
                    </div>
                </div>
            })
        }
    </div>
}