import React, { useEffect, useState } from 'react'
import { Slider } from 'antd';
// import peopleImg from '@assets/images/people.png' 
import cedis from '@assets/h5Images/cedis';
import { useSelector } from 'react-redux'
import './progress.scss';
import { RootState } from '@/store';



function ProgressScss({ percent }: { percent: any }) {



  const [marks, setMarks] = useState({});


  const { taskList } = useSelector((state:RootState) => {
    return {
      taskList: state.taskModule.taskList
    }
  })

  useEffect(() => {

    const task = taskList.find((item) => {
      return item.task.id == "10008"
    })

    const obj:any = {
    };
    task.task.contents.forEach((item:any) => {
      if (item.conditions.numberOfInvitees) {
        obj[item.conditions.numberOfInvitees] = {
          label: <div className='people'>
            <img src={cedis} />
            <div className='amount'>x{item.rewards[0].amount}</div>
            <div className='peopleCount'>{item.conditions.numberOfInvitees}人</div>
          </div>
        }
      }
    })
    setMarks(obj)
  }, [taskList])

  return (
    <div className='h5-ProgressScss'>
      <Slider className='inner' marks={marks} value={percent} min={0} max={100} />
    </div>
  )
}

export default ProgressScss