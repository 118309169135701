import { axios } from '@utils/request'
import Qs from 'qs'

export function getGifts(type: number) {
  return axios({
    url: `/gifts:list?type=${type}`,
    method: 'get'
  })
}

/**
 * 赠送礼物（购买礼物）
 * @param roomId 
 * @param giftId 
 * @param quantity
 * @returns 
 */
export function getBuyGifts(roomId: any, giftId: number, quantity: number) {
  return axios({
    url: `/room:giftsBuy`,
    method: 'post',
    data: Qs.stringify({
      roomId,
      giftId,
      quantity
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 礼物列表前100
 * @param liveId
 * @returns 
 */
export function getLiveRanks(liveId: any) {
  return axios({
    url: `/gifts:liveRanks`,
    method: 'get',
    params: {
      liveId: liveId
    }
  })
}

/**
 * 礼物流水
 * @param liveId
 * @param pageStart
 * @param pageSize
 * @returns 
 */
export function getRecords(liveId: any, pageStart: number = 1, pageSize?: number) {
  return axios({
    url: `/gifts:records`,
    method: 'get',
    params: {
      liveId: liveId,
      pageStart: pageStart,
      pageSize: pageSize || 10
    }
  })
}

/**
 * 发红包
 * @param roomId 
 * @param amount 
 * @param quantity 
 * @returns 
 */
export function giveRedPacket(roomToken: string, amount?: number, quantity?: number) {
  return axios({
    url: `/red:give`,
    method: 'post',
    data: Qs.stringify({
      roomToken,
      amount,
      quantity
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
/**
 * 抢红包
 * @param roomToken 
 * @param rid 
 * @returns 
 */
export function receiveRedPacket(roomToken: string, rid: string) {
  return axios({
    url: `/red:receive`,
    method: 'post',
    data: Qs.stringify({
      roomToken,
      rid
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 可抢的红包列表
 * @param liveId 
 * @returns 
 */
export function getRedPacket(liveId: number) {
  return axios({
    url: `/red:list`,
    method: 'get',
    params: {
      liveId
    }
  })
}

/**
 * 赠送礼物（Tv） 
 * @param {*} channelId 
 * @param {*} giftId 
 * @param {*} quantity 
 * @param {*} videoId 
 * @returns 
 */
export function getBuyTvGifts(channelId: number, giftId: number, quantity: number, videoId: number) {
  return axios({
    url: '/room:giftsBuyTv',
    method: 'post',
    data: Qs.stringify({
      channelId,
      giftId,
      quantity,
      videoId
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}