
import React, { useState } from 'react';
import './index.scss'
import { Tooltip } from 'antd'
import EmoticonBox from './emoticonBox'

import { selectedEmoji, emoji } from '@assets/h5Images/emoji'

export default function Index({ onEmoItemClick, className, disabled }:{ onEmoItemClick:(value:string)=>void, className:string, disabled:boolean }) {
  const [openStat, setOpenStat] = useState(false)

  function onEmojiItemClick(item:string) {
    onEmoItemClick(item)
    setOpenStat(!openStat)
  }

  function onOpen(open:boolean) {
    if (open == false) {
      setOpenStat(false)
    }
  }

  function openTooltip() {
    setOpenStat(true)
  }
  if (disabled) {
    return <img src={emoji} alt="" className={`smile ${className}`} />
  }
  return <div className='h5-soga-emoji'>
    <Tooltip getTooltipContainer={() => {
      const doc:any=document.getElementById("h5-send-message-card");
      return doc
    }} placement="bottom" open={openStat} onOpenChange={onOpen} color="white" trigger="click" overlayClassName="h5-soga-emoTooltip" title={<EmoticonBox onEmojiItemClick={onEmojiItemClick} />}>
      <img src={emoji} alt="" className={`smile ${className}`} onClick={openTooltip} />
    </Tooltip>
  </div>
}