import React, { useEffect, useState } from 'react'
import { Slider } from 'antd';
// import peopleImg from '@assets/images/people.png' 
import cedis, { disabledCedis } from '@assets/h5Images/cedis';
import complate from '@assets/h5Images/complate'
import './progress.scss';
import { useDispatch, useSelector } from 'react-redux';
import { tasks_issueAwards_Func } from '@features/taskClice'
import { RootState } from '@/store';



function ProgressScss({ percent, task, records }:{ percent:any, task:any, records:any }) {


    const dispatch = useDispatch();
    const [marks, setMarks] = useState({});


    const { awardsRecord } = useSelector((state:RootState) => {
        return {
            awardsRecord: state.taskModule.awardsRecord
        }
    })


    useEffect(() => { 
        const obj:any = {
        };
        const newAwardsRecord = awardsRecord.map((item) => {
            return item.taskRecordDetailId
        })
        const mewRecords = records.map((item:any) => { 
            if (newAwardsRecord.includes(item.taskRecordDetailId)) {
                return item.contentIndex
            } else {
                return undefined
            }
        }) 
        task.contents.forEach((item:any, index:number) => {
            if (percent >= item.conditions.timeDuration) {
                obj[item.conditions.timeDuration] = {
                    label: <div className='people'>
                        <img className='active' src={cedis} onClick={() => {
                            const content = records.find((item:any) => {
                                return item.contentIndex == index
                            })
                            dispatch(tasks_issueAwards_Func(task.id, content.taskRecordDetailId, content.taskRecordId));
                        }} />
                        <div className='amount'>x{item.rewards[0].amount}</div>
                        <div className='peopleCount'>{item.conditions.timeDuration}min</div>
                        {mewRecords.includes(index) ? <> <img src={complate} className="complate" /></> : <></>}

                    </div>
                }
            } else {
                obj[item.conditions.timeDuration] = {
                    label: <div className='people'>
                        <img src={disabledCedis} />
                        <div className='amount'>x{item.rewards[0].amount}</div>
                        <div className='peopleCount'>{item.conditions.timeDuration}min</div>
                    </div>
                }
            }

        })
        setMarks(obj)
    }, [percent,records,awardsRecord])

    return (
        <div className='h5-ProgressScss h5-timeProgressScss'>
            <Slider className='inner timeInner' marks={marks} value={percent} min={0} max={60} />
        </div>
    )
}

export default ProgressScss