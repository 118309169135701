import { getBuyGifts_Func, getBuyTvGifts_Func } from "@/features/giftClice";
import { ChannelTypeEnum } from "@/features/globalClice";
import { RootState } from "@/store";
import { Mask, Toast } from "antd-mobile";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cedis from "@assets/h5Images/cedis";
import modalClose from '@assets/h5Images/modalClose'
import './sendGift.scss'

export default function Index({ visible, onClose }: { visible: boolean, onClose?: () => void }) {
    const [count, setCount] = useState(1)

    const dispatch = useDispatch();

    const { sendGiftModal, roomId, channelType } = useSelector((state: RootState) => {
        let roomId = 0;
        if (state.globalModule.channelType == ChannelTypeEnum.live) {
            if (state.roomModule.onLineList.length > 0) {
                const roomObj = state.roomModule.onLineList.find((item) => {
                    return item.roomType == 0
                })
                if (roomObj) {
                    roomId = roomObj.roomId
                }
            }
        }
        return {
            sendGiftModal: state.globalModule.sendGiftModal,
            channelType: state.globalModule.channelType,
            roomId: roomId
        }
    })

    const buyGift = (giftId: number, quality: number, obj: any) => {
        //此代码是区分视频播放和直播的判断
        if (channelType == ChannelTypeEnum.live) {
            if (roomId > 0) {
                dispatch(getBuyGifts_Func(roomId, giftId, quality))
            } else {
                // message.error("直播已停止")
                Toast.show("直播已停止");
            }
        } else {
            dispatch(getBuyTvGifts_Func(giftId, quality, obj))
        }
    }

    return <Mask className="h5-send-gift-modal" visible={visible}>
        <div className="h5-send-gfit-message">

            <img src={modalClose} className="modal-close" onClick={() => {
                onClose && onClose();
            }} />
            <div className="gift-info">
                <div className="gift-icon">
                    <img src={`${sendGiftModal.gift?.imgBaseUri}${sendGiftModal.gift?.icon}`} />

                </div>
                <div className="gift-name">
                    {sendGiftModal.gift?.title}
                </div>
                <img src={cedis} className='cedis-icon' />
                <div className='gift-price'>
                    {sendGiftModal.gift?.unitPrice}
                </div>
            </div>
            <div className='btns'>
                <div className="count-btn">
                    <div className={`${count == 1 ? 'selected' : ''}`} onClick={() => {
                        setCount(1)
                    }}>1</div>
                    <div className={`${count == 10 ? 'selected' : ''}`} onClick={() => {
                        setCount(10)
                    }}>10</div>
                    <div className={`${count == 100 ? 'selected' : ''}`} onClick={() => {
                        setCount(100)
                    }}>100</div>
                    <div className={`${count == 200 ? 'selected' : ''}`} onClick={() => {
                        setCount(200)
                    }}>200</div>
                </div>

            </div>

            <div className="btm-div">
                <div className="count-price">
                    <div className="total">Total:</div>
                    <img src={cedis} className='cedis-icon' />
                    <div className='gift-price'>
                        {count * sendGiftModal.gift?.unitPrice}
                    </div>
                </div>
                <div className="send-btn" onClick={() => {
                    buyGift(sendGiftModal.gift?.giftId, count, sendGiftModal.gift)
                }}>
                    Send
                </div>
            </div>

        </div>
    </Mask>
}