import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import backIcon from '@assets/h5Images/backIcon';
import twitter from '@assets/h5Images/twitter';
import like from '@assets/h5Images/like';
import { checkBindTwitter_Func, task_do_Func } from '@features/taskClice';
import box from '@assets/h5Images/box'
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom';
import './index.scss'

export default function Index() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [btn10005, setBtn10005] = useState("Check")
    const { taskList } = useSelector((state: RootState) => {
        return {
            taskList: state.taskModule.taskList
        }
    })

    // useEffect(() => {
    //     const task = taskList.find((item) => {
    //         return item.task.id == "10005"
    //     })
    //     if (task && location.href.includes(task.task.contents[0].conditions?.tweetId)) {
    //         setBtn10005("Check")
    //     }

    // }, [])

    const onDoResult = (code: string) => {
        if (code == "PU028") {
            // navigate(`/`)
            //推特授权逻辑
        }
    }
    return <div className="h5-soga-quest-container">
        <div className="quest-info-head" >
            <img src={backIcon} onClick={() => {
                // onBackClick && onBackClick();
                navigate("/questlist")
            }} />Newbie Quest
        </div>
        <div className="info-items">
            {
                taskList.map((item) => {
                    let status = 1
                    if (item.records.length > 0) {
                        status = item.data.taskStatus
                    }
                    if (item.task.id == "10003") {
                        return <div className="info-item" key={"10003"} >
                            <div className='left'><img src={twitter} />  {item.task.title.en} </div> {status != 2 ? <div className='btn' onClick={() => {
                                // dispatch(checkBindTwitter_Func())
                                dispatch(task_do_Func("10003", onDoResult));
                            }}> Check</div> : <div className='btn'>Complete</div>}
                        </div>
                    } else if (item.task.id == "10005") {
                        return <div className="info-item" key={"10005"}>
                            <div className='left'><img src={like} />  {item.task.title.en} </div> {status != 2 ? <div className='btn' onClick={() => {
                                dispatch(task_do_Func("10005", onDoResult));
                            }}> {btn10005}</div> : <div className='btn'>Complete</div>}
                        </div>
                    }
                    return null
                })
            }

        </div>
        {
            taskList.map((item) => {
                let status = 1
                if (item.records.length > 0) {
                    status = item.data.taskStatus
                }
                if (item.task.id == "10006") {
                    return <div key={item.task.id} className="reward-item">
                        <div className="top">
                            Reward
                        </div>
                        <div className="bottom">
                            <div className="title">Newbie Chest</div>
                            <div className="box-img">
                                <img src={box} />
                            </div>
                            {
                                status != 2 ? <div className="openBtn" onClick={() => {
                                    dispatch(task_do_Func("10006", onDoResult));
                                }}>Open</div> : <div className="openBtn"  >Complete</div>
                            }
                        </div>
                    </div>
                }
                return null
            })
        }
    </div> 
}