 import { Mask, Toast } from "antd-mobile";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import modalClose from '@assets/h5Images/modalClose'
import './sendGift.scss'
import { Input } from "antd";
import { bindInviteCode_Func } from "@/features/loginClice";

export default function Index({ visible, onClose }: { visible: boolean, onClose?: () => void }) {
    const dispatch = useDispatch();
    const [code, setCode] = useState<string>('');

    return <Mask className="h5-send-gift-modal" visible={visible}>
        <div className="h5-send-gfit-message">

            <img src={modalClose} className="modal-close" onClick={() => {
                onClose && onClose();
            }} />
            <div className="invitation-modal">
                <div className="invitaion-title">Fill in the inviter</div>
                <Input value={code} className='invitation-input' placeholder='Enter invitation code' onChange={(e) => {
                    setCode(e.target.value)
                }} />
                <div className="input-tip">Please fill it in accurately and cannot be changed.</div>

                <div className='btn' onClick={() => {
                    if (code) {
                        dispatch(bindInviteCode_Func(code))
                    } else { 
                        Toast.show("Please enter the code!")
                    }
                }}>
                    Save
                </div>
            </div>

        </div>

    </Mask>
}