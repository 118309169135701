import React, { useState } from 'react';
import playIcon, { stopPlayIcon } from '@assets/h5Images/play';
import {setWatchStop} from '@features/globalClice'
import { useDispatch } from 'react-redux';

export default function Index({play,onPayClick, onStopClick}:{play:boolean,onPayClick:()=>void, onStopClick:()=>void}) {
  
    const dispatch=useDispatch()
    return <div className="tools-icons">
        <img src={play ? stopPlayIcon : playIcon} className="play-img" onClick={() => {
            if (play) {
                onStopClick && onStopClick();
                dispatch(setWatchStop(true))
            } else {
                onPayClick && onPayClick();
                dispatch(setWatchStop(false))
            }
            // setPlay(!play)
        }} />
    </div>
}