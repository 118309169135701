import React, { useState, useEffect } from 'react';
import './index.scss'; // 导入样式文件，与上面的 CSS 代码相同
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { removeGiftList } from '@/features/webSocketClice';
import flower from '@assets/svgs/flower.png'
import { config } from '@/config';

const GiftAnimation = ({ onAnimationEnd, obj, count }: { onAnimationEnd: any, obj: any, count: number }) => { 
    return <div className="gift-animation" onAnimationEnd={onAnimationEnd}>
        <div className="content">
            <img src={ `${obj.imgBaseUri}${obj.icon}` }  />
            <div className="count"><label>x</label> {count}</div>
        </div>
    </div>
};

const Index = () => {

    const dispatch = useDispatch();
    const { giftList } = useSelector((state: RootState) => {
        return {
            giftList: state.webSocketModule.giftList
        }
    })

    // 动画结束时的回调，隐藏礼物特效
    const handleAnimationEnd = (uuid: string) => {
        dispatch(removeGiftList(uuid))
    };

    return (
        <div className="container">
            {giftList.map((item, index) => {  
                return <GiftAnimation count={item[1][5]} obj={item[1][6]} key={item[2]} onAnimationEnd={() => {
                    handleAnimationEnd(item[2])
                }} />
            })}
            {/* {showGift && <GiftAnimation onAnimationEnd={handleAnimationEnd} />} */}
        </div>
    );
};

export default Index;
