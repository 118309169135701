import { RootState } from '@/store';
import { refreshToken_Func, userInfo_Func } from '@features/loginClice'
import { useAccount } from '@particle-network/connect-react-ui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '@/config'
export default function Index() {
    const dispatch = useDispatch();
    const account = useAccount();
    const [isRefresh,setIsRefresh]=useState(false);
    const token = useSelector((state: RootState) => {
        return state.loginModule.token;
    })

    useEffect(() => {
        localStorage.removeItem('signingLock'); 
        const token = localStorage.getItem('token')
        if (token) {
            dispatch(refreshToken_Func(()=>{
                !isRefresh && setIsRefresh(true)
            }));
        }  
    }, [])


    useEffect(() => {
        if (token && isRefresh) {
          dispatch(userInfo_Func(() => {
          }));
        }
      }, [isRefresh])
    
    useEffect(() => {
        let timer: any = null; 
        if (account && token && timer === null) {
            // dispatch(refreshToken_Func());
            timer = setTimeout(() => {
                dispatch(refreshToken_Func())
            }, config.refreshTokenTime)
        } else if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }

        return () => {
            if (timer != null) {
                clearTimeout(timer);
                timer = null;
            }
        }
    }, [account, token])

}