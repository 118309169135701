 
import { RootState } from "@/store";
import { Mask } from "antd-mobile"; 
import { useSelector } from "react-redux";
import cedis from "@assets/h5Images/cedis";
import modalClose from '@assets/h5Images/modalClose'
import openBox from "@assets/h5Images/openBox";
import './sendGift.scss'

export default function Index({ visible, onClose }: { visible: boolean, onClose?: () => void }) {
   
    const { boxResult } = useSelector((state:RootState) => {
        return {
            boxResult: state.globalModule.boxResult
        }
    }) 
    return <Mask className="h5-send-gift-modal" visible={visible}>
        <div className="h5-send-gfit-message">

            <img src={modalClose} className="modal-close" onClick={() => {
                onClose && onClose();
            }} />
             <div className="box-result-context">
            <div className="box-img"><img src={openBox} /></div>
            <div className='tips'>Open Newbie Chest Success</div>
            <div className='subtips'>Congratulations,you have received:</div>

            <div className='cedis'><img src={cedis} /></div>
            <div className='amount'>{boxResult?.data?.amount || 0}</div>
        </div>

        </div>
    </Mask>
}