import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate,MemoryRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import Index from "./views/index";
import AuthRouter from '@components/AuthRouter'


import { ModalProvider } from '@particle-network/connect-react-ui';
import { Chain, metaMask } from '@particle-network/connect';
import { config } from '@/config';
import useSetBaseFontSize from './hooks/useSetBaseFontSize';
import { useDispatch } from 'react-redux'
import { onMessageSubject } from './features/webSocketClice';
import Home from './views/Home';
import LiveRooms from './views/liveRooms'
import RoomNFTlist from './views/roomNFTlist';
import RoomNFTInfo from './views/roomNFTInfo';
import VIPNfts from './views/vipNfts';
import NftDetail from './views/vipNftDetail'
import RoomNfts from './views/roomNfts'
import RoomNftsDetail from './views/roomNftsDetail'
import PersonalCenter from './views/personalCenter';
import Search from './views/search'
import History from './views/history'
import Follow from './views/follow'
import Recharge from './views/recharge'
import Report from './views/report'
import Swap from './views/swap'
import VipMint from './views/vipMint'
import MintHistory from './views/mintHistory'
import Test from './views/test';
import Tv from './views/tv' 
import Authorize from './views/authorize'


import H5Index from './H5Views/index';
import H5MyNfts from './H5Views/myNfts'
import H5Home from './H5Views/home';
import QuestList from './H5Views/quest';
import QuestInfo from './H5Views/quest/info';
import InvitingQuest from './H5Views/quest/invitingQuest'
import H5Wallet from './H5Views/wallet'
import H5Personal from './H5Views/wallet/personal'
function App() {
  useSetBaseFontSize();

  // if (process.env.REACT_APP_ISMOBILE === 'true') {
    return <div className="App">
      <ConfigProvider locale={zhCN}>

        <ModalProvider
          walletSort={['Wallet', 'Particle Auth']}
          options={{
            projectId: config.projectId,
            clientKey: config.clientKey,
            appId: config.appId,
            chains: config.chains as Chain[],
            wallets: [metaMask({ projectId: config.projectId })],
          }}
          theme='auto'
        >
          <BrowserRouter>
            {/* <Suspense fallback={<CLoading visible={true} />}> */}
            <Routes>
              <Route path='/' element={<H5Index />} >
                <Route path='/' element={<H5Home />} /> 
                <Route path='/myNfts' element={<H5MyNfts />} />
                <Route path='/questlist' element={<QuestList />} />
                <Route path='/questinfo' element={<QuestInfo />} />
                <Route path="/invitingquest" element={<InvitingQuest />} />
                <Route path="/wallet" element={<H5Wallet/> } />
                <Route path="/personal" element={<H5Personal />} />
                <Route path="/" element={<Navigate replace to="/" />} /> 
              </Route>
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            {/* </Suspense> */}
          </BrowserRouter>
        </ModalProvider>
      </ConfigProvider>
    </div>
  // } else {
    // return <div className="App">
    //   <ConfigProvider locale={zhCN}>

    //     <ModalProvider
    //       walletSort={['Wallet', 'Particle Auth']}
    //       options={{
    //         projectId: config.projectId,
    //         clientKey: config.clientKey,
    //         appId: config.appId,
    //         chains: config.chains as Chain[],
    //         wallets: [metaMask({ projectId: config.projectId })],
    //       }}
    //       theme='auto'
    //     >
    //       <BrowserRouter>
    //         {/* <Suspense fallback={<CLoading visible={true} />}> */}
    //         <Routes>
    //           <Route path='/' element={<Index />} >
    //             <Route path='/' element={<Home />} />
    //             {/* <Route path='/liverooms/:roomId/:tokenType' element={<AuthRouter><LiveRoom /></AuthRouter>} /> */}
    //             <Route path='/liveroom/:roomId/:tokenType' element={<AuthRouter><LiveRooms /></AuthRouter>} />
    //             <Route path='/RoomNFTlist' element={<AuthRouter><RoomNFTlist /></AuthRouter>} />
    //             <Route path='/roomNFTInfo/:roomId' element={<AuthRouter><RoomNFTInfo /></AuthRouter>} />
    //             <Route path="/tv" element={<AuthRouter><Tv /></AuthRouter>} />
    //             <Route path='/vipNfts' element={<AuthRouter><VIPNfts /></AuthRouter>} />
    //             <Route path='/roomNfts' element={<AuthRouter><RoomNfts /></AuthRouter>} />
    //             <Route path='/vipNfts/nftDetail/:tokenId' element={<AuthRouter><NftDetail /></AuthRouter>} />
    //             <Route path='/roomNfts/roomNftsDetail/:roomId' element={<AuthRouter><RoomNftsDetail /></AuthRouter>} />
    //             <Route path='/personalCenter' element={<AuthRouter><PersonalCenter /></AuthRouter>} />
    //             <Route path='/search' element={<AuthRouter><Search /></AuthRouter>} />
    //             <Route path='/history' element={<AuthRouter><History /></AuthRouter>} />
    //             <Route path='/following' element={<AuthRouter><Follow type="Following" /></AuthRouter>} />
    //             <Route path='/followers' element={<AuthRouter><Follow type="Followers" /></AuthRouter>} />
    //             {/* <Route path='/recharge' element={<AuthRouter><Recharge operate="recharge"/></AuthRouter>} /> */}
    //             {/* <Route path='/reflect' element={<AuthRouter><Recharge operate="reflect"/></AuthRouter>} /> */}
    //             <Route path='/report' element={<AuthRouter><Report /></AuthRouter>} />
    //             <Route path='/swap' element={<AuthRouter><Swap /></AuthRouter>} />
    //             <Route path='/vipMint' element={<VipMint />} />
    //             <Route path='/mintHistory' element={<AuthRouter><MintHistory /></AuthRouter>} />
    //             <Route path='/authorize' element={<AuthRouter><Authorize /></AuthRouter>} />
    //             <Route path='/test' element={<AuthRouter><Test /></AuthRouter>} />

    //             <Route path="/" element={<Navigate replace to="/" />} />
    //           </Route>
    //           <Route path="*" element={<Navigate replace to="/" />} />
    //         </Routes>
    //         {/* </Suspense> */}
    //       </BrowserRouter>
    //     </ModalProvider>
    //   </ConfigProvider>
    // </div>
  // }
};

export default App;
