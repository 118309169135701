import { useDispatch, useSelector } from 'react-redux'
import './index.scss'
import ButtonGroup from '@H5Components/buttonGroup'
import { ChannelTypeEnum, setChannelType } from '@/features/globalClice';
import { RootState } from '@/store';
import Live from '@H5Components/Live';
import Tv from '@H5Components/Tv' 

export default function Index() {

    const dispatch = useDispatch();
    const { channel } = useSelector((state: RootState) => {
        return {
            channel: state.globalModule.channelType
        }
    })
 
    return <div className="home-container">
        <ButtonGroup onClick={(value: number | string) => {
            dispatch(setChannelType(value))
        }}
            value={channel}
            items={[
                {
                    value: 2,
                    text: 'Channel'
                },
                {
                    value: 1,
                    text: 'Live'
                }
            ]} />

        {
            channel==ChannelTypeEnum.live && <Live/>
        }
        {
            channel==ChannelTypeEnum.tv && <Tv/>
        }
    </div>
}