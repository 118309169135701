import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import LiveOperate from "@H5Components/LiveOperate";
import Operate from "@H5Components/LiveOperate/operate";
import './index.scss'
import { useDispatch } from 'react-redux';
import ChatWindow from '@H5Components/ChatWindow/tvChatWindow'
import Watched from '@H5Components/Watched';
import { videosList_func, getComments_func, pushComments_func, setComments, setCurrentChannel, clearOrginMessageList } from '../../features/roomClice';
import TvVideo from '@H5Components/TvVideo';
import { setChannelType, ChannelTypeEnum, setWatchStop } from '@features/globalClice';
import { RootState } from '@/store';
import useMessage from './useManageList';
import GiftAnimation from '@components/GiftAnimation';
import BlindBoxGiftAnimation from '@components/GiftAnimation/blindBox';
export default function Index() {

    useMessage();

    const [channel, setChannel] = useState<any>(null)
    const [currentSourceIndex, setCurrentSourceIndex] = useState<number>(0);
    const { channels, videos, orginMessageList } = useSelector((state: RootState) => {
        return {
            channels: state.roomModule.channels,
            videos: state.roomModule.videos,
            orginMessageList: state.roomModule.orginMessageList
        }
    })

    const dispatch = useDispatch();


    useEffect(() => {
        if (channels && channels.length > 0) {
            setChannel(channels[0])
            dispatch(videosList_func(channels[0].id));
            dispatch(getComments_func(channels[0].id));
        }
    }, [channels])


    useEffect(() => {
        if (videos && videos.length > 0 && channel) {
            dispatch(setCurrentChannel({
                channelId: channel.id,
                videoId: videos[currentSourceIndex]?.id
            }))
        }
    }, [videos, currentSourceIndex, channel])
    const volumeControlRef: any = useRef();

 

    useEffect(() => {
        dispatch(setChannelType(ChannelTypeEnum.tv));
        dispatch(setWatchStop(false))
    }, [])

    // useEffect(() => {
    //     dispatch(setComments([]));
    //     dispatch(clearOrginMessageList([]))
    // }, [])
    return <>
        <LiveOperate type={ChannelTypeEnum.tv} watchCount={channel?.followCount || 0} />
        <div className="audio">
            <div className="tv-video-container">
                {videos.length > 0 && <TvVideo ref={volumeControlRef} videos={videos} onPlayIndex={(index:number) => {
                    setCurrentSourceIndex(index)
                }} />}
                <GiftAnimation />
                <BlindBoxGiftAnimation  channelType={ChannelTypeEnum.tv}/>
            </div>
        </div>
        <div className="h5-roomName">
            {videos.length > 0 ? videos[currentSourceIndex].name : ''}
        </div>

        {videos.length > 0 && <Watched videoId={videos[currentSourceIndex]?.id} />}

        <div className={`message-container ${orginMessageList.length <= 0 ? 'soga-nomessage' : ''}`}>
            <ChatWindow />
        </div>



        <Operate type={ChannelTypeEnum.tv}   onPushComments={(comment) => {
            dispatch(pushComments_func(videos[currentSourceIndex].id, Math.floor(volumeControlRef.current.onVideoPlayTime().currentTime), comment));
        }} />
    </>
}