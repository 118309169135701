import { checkBindDiscord, discordCallback, getUserTasks, listAwardsRecord, task_do, tasks_issueAwards, twitter_callback, twitter_checkBindTwitter } from "@/api/task";
import { config } from "@/config";
import { AppThunk } from "@/store";
import { createSlice } from "@reduxjs/toolkit";
import { closeLoad, openBoxResult, openLoad } from "./globalClice";


interface TaskCliceState {
    awardsRecord: any[],
    taskList: any[],
}

const initialState: TaskCliceState = {
    awardsRecord: [],
    taskList: [],
}

const TaskClice = createSlice({
    name: 'TaskModule',
    initialState,
    reducers: {
        setAwardsRecord(state, { payload }) {
            state.awardsRecord = payload
        },
        setTaskList(state, { payload }) {
            state.taskList = payload
        }
    },
});

export const { setAwardsRecord, setTaskList } = TaskClice.actions

/**
 * 检测推特是否授权
 * @returns 
 */
export const checkBindTwitter_Func = (): AppThunk => async (dispatch, getState) => {

    const result: any = await twitter_checkBindTwitter(config.twitterCallBackUrl);
    if (result.success && !result.data.bind) {
        window.open(result.data.value)
    } else if (result.success) {
        // dispatch(setTwitterStat(2));
        window.open(`${config.twitterHomeUrl}${result.data.value}`)
    }
}

/**
 * 获取 dash 任务执行
 * @param oauthToken 
 * @param oauthVerifier 
 * @returns 
 */
export const twitter_callback_Func = (state: string, code: string): AppThunk => async (dispatch, getState) => {

    const result: any = await twitter_callback(state, code);
    if (result.success) {
        // dispatch(setShowtwitter(true));
        // dispatch(setTwitterStat(2));
        window.open(`${config.twitterHomeUrl}${result.data}`)
    } else {
        if (result.code == 'PU029') {
            // dispatch(setShowMsg(true))
            // dispatch(setTitle('Tips'))
            // dispatch(setContext('Twitter has already bound the user'))

            dispatch(checkBindTwitter_Func());

        }
    }

}

/**
 * 检测是否绑定discord
 * @returns 
 */
export const checkBindDiscord_func = (): AppThunk => async (dispatch, getState) => {
    const result = await checkBindDiscord(config.twitterCallBackUrl);
    if (!result.data.bind) {
        window.open(result.data.value)
    } else {
        // dispatch(setDiscordStat(2));
        window.open(result.data.value)
    }
}

/**
 * 获取 dash 授权
 * @param oauthToken 
 * @param oauthVerifier 
 * @returns 
 */
export const discordCallback_Func = (code: string): AppThunk => async (dispatch, getState) => {
    const result: any = await discordCallback(code, config.twitterCallBackUrl);
    if (result.success) {
        // dispatch(setDiscordStat(2));
        // dispatch(setShowDiscord(true));
        window.open(`${result.data}`)
    } else {
        if (result.code == 'PU037') {
            // dispatch(setShowMsg(true))
            // dispatch(setTitle('Tips'))
            // dispatch(setContext('Discord has already bound the user'))
        }
    }

}

export const listAwardsRecord_Func = (taskId: number | string, taskRecordId: number | string): AppThunk => async (dispatch, getState) => {
    const result: any = await listAwardsRecord(taskId, taskRecordId);
    if (result.success) {
        dispatch(setAwardsRecord(result.data.list || []));
    }
}

export const tasks_issueAwards_Func = (taskId:number, taskRecordDetailId:number, taskRecordId:number):AppThunk => async (dispatch, getState) => {
    const result:any = await tasks_issueAwards(taskId, taskRecordDetailId, taskRecordId);
    if (result.success) {
        dispatch(listAwardsRecord_Func(taskId, taskRecordId))
    }
}


export const getUserTasks_Func = ():AppThunk => async (dispatch, getState) => {
    const result:any = await getUserTasks("10003,10005,10006,10008");
    if (result.success) {
        dispatch(setTaskList(result.data))
    }
}

export const task_do_Func = (taskId:string, cb:Function):AppThunk => async (dispatch, getState) => {
    dispatch(openLoad());
    const result:any = await task_do(taskId);
    if (result.success) {
        if (taskId == "10006") {
            dispatch(openBoxResult(result.data.data));
        }
        dispatch(getUserTasks_Func());
    } else { 
        cb && cb(result.code)
    }

    dispatch(closeLoad());
}
export default TaskClice.reducer