import React, { useEffect, useState } from 'react'
import '@particle-network/connect-react-ui/dist/index.css';
import { useAccount, useConnectKit, useParticleProvider } from "@particle-network/connect-react-ui";
import Web3 from 'web3'
import { isEVMProvider } from "@particle-network/connect";
import { useDispatch, useSelector } from 'react-redux';
import { disConnect_Func, register_Func, setLoginState, userInfo_Func } from '@/features/loginClice';
import { config } from '@/config';
import { RootState } from '@/store';
import { setRefreshSign } from '@/features/globalClice';




function Index() {
  const account: string | undefined = useAccount();
  const provider: any = useParticleProvider();

  const dispatch = useDispatch();




  const [web3, setWeb3] = useState<any>(window.web3);
  const connectKit = useConnectKit();



  const { userInfo,refreshSign,windowId } = useSelector((state: RootState) => {
    return {
      userInfo: state.loginModule.userInfo,
      refreshSign:state.globalModule.refreshSign,
      windowId:state.globalModule.windowId
    }
  })

  useEffect(() => {
    if (provider && isEVMProvider(provider)) {
      console.log("set web3");
      window.web3 = new Web3(provider as any)
      setWeb3(new Web3(provider as any));
    }
  }, [provider]);

  useEffect(() => {
    if (web3) {
      connectKit.on('connect', (provider) => {
        const token2 = localStorage.getItem('token');
        if(!token2){
          dispatch(setRefreshSign(''))
        }
        // else{
        //   dispatch(userInfo_Func())
        // }
      });
  
      connectKit.on('disconnect', () => { 
        dispatch(disConnect_Func())
      });
      connectKit.on('chainChanged', (chain) => {
      });
      connectKit.on('accountsChanged', (accounts) => {
        // if(userInfo && userInfo.wallet!=accounts[0]){
        //   dispatch(disConnect_Func())
        // } 
      });
    }
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.wallet != account) {
      dispatch(disConnect_Func())
    }
    const token2 = localStorage.getItem('token');
    if (account && token2) {
      dispatch(setLoginState(true))
    }
  }, [account, userInfo])

  useEffect(() => {

    const token2 = localStorage.getItem('token'); 
    if (account && provider && (isEVMProvider(provider)) && !token2) {
      setTimeout(() => {
        const lock = localStorage.getItem('signingLock');
        if (!lock) {
          localStorage.setItem('signingLock', 'true');
          setTimeout(() => {

            web3.eth.personal
              .sign(config.signMessage, account, "")
              .then((result: string) => {
                dispatch(register_Func(account, result, 'bsc', (result: boolean) => {
                  if (!result) {
                    connectKit.disconnect();
                    dispatch(disConnect_Func())
                  } else {
                    dispatch(setLoginState(true))
                    localStorage.removeItem('signingLock'); 

                    // const broadcastChannel = new BroadcastChannel('pogoBrodcastChannel');
                    // broadcastChannel.postMessage({
                    //   id:windowId,
                    //   code:'connect'
                    // });
                  }
                })); 
              })
              .catch((error: any) => {
                connectKit.disconnect();
                dispatch(disConnect_Func())
              })
          }, 600)
        } else {
          console.log('Another window is already signing.');
          const lockIn=setInterval(()=>{
            const lock = localStorage.getItem('signingLock'); 
            if (!lock) { 
              dispatch(userInfo_Func())
              clearInterval(lockIn)
            }
          },800)
        }

      }, Math.floor(Math.random() * (500 - 100 + 1)) + 100)
    }

    if (account && token2) {
      dispatch(setLoginState(true))
    }


  }, [account,refreshSign])



}

export default Index;