import React from 'react'
import './emoticonBox.scss'
import { emoSvg } from '../../features/globalClice'; 

export default function EmoticonBox({ onEmojiItemClick }:{ onEmojiItemClick:(value:string)=>void }) {
  return <div className='h5-soga-emoticon'>
    {
      Object.keys(emoSvg).map((item, index) =>
        <img key={index} src={`${emoSvg[item]}`} alt={item} onClick={() => onEmojiItemClick(item)} />
      )
    }
  </div>
}