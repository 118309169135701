import { AssetTypeEnum } from "@/features/accountClice";
import { RootState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import headImg from '@assets/svgs/defaultImg.svg'
import { useEffect, useState } from "react";
import { getFansList_Func, getFollowList_Func, operateFollow_Func } from "@/features/loginClice";
import { ActionSheet, Popup } from "antd-mobile";
import backIcon from '@assets/h5Images/backIcon';
import './index.scss'
import type {
    Action,
    ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet'
import { CheckOutlined } from "@ant-design/icons";

export default function Index({ visible, onClose, type }: { visible: boolean, onClose: () => void, type: 'Following' | 'Followers' }) {

    const dispatch = useDispatch();
    const [opVisible, setOpVisible] = useState<boolean>(false)
    const [followedUserId, setFollowedUserId] = useState<any>(0)
    const [currentType, setCurrentType] = useState<'Following' | 'Followers'>(type)

    const { followfansNum, userInfo, dataList } = useSelector((state: RootState) => {
        const datas = (currentType == "Following" ? state.loginModule.followList : state.loginModule.fansList)
        return {
            userInfo: state.loginModule.userInfo,
            dataList: datas,
            followfansNum: state.loginModule.followfansNum
        }
    })

    useEffect(() => {
        setCurrentType(type)
    }, [type])


    useEffect(() => {
        if (userInfo) {
            getData();
        }
    }, [userInfo, currentType])

    const getData = () => {
        if (currentType === 'Following') {
            dispatch(getFollowList_Func(1, 1000))
        } else {
            dispatch(getFansList_Func(1, 1000))
        }
    }

    const actions: Action[] = [
        {
            text: 'unfollow', key: 'copy', onClick: () => {
                dispatch(operateFollow_Func('del', followedUserId, () => {
                    setOpVisible(false)
                }, 1000))
            }
        },
    ]
    return <><Popup
        visible={visible}
        onMaskClick={() => {
            onClose()
        }}
        position='right'
        className="follow-container"
        bodyStyle={{ width: '100vw', maxWidth: "1000px" }}
    >

        <div className="h5-personalPage-container">
            <div className="wallet-container">
                <div className="quest-info-head" >
                    <img src={backIcon} onClick={() => {
                        onClose()
                    }} />
                </div>
                <div className="follow-card">
                    <div className="item" onClick={() => {
                        setCurrentType("Following")
                    }}>
                        <div className="frist">{followfansNum.followCount || 0}</div>
                        <div className={`end ${currentType == "Following" ? 'active' : ''}`}>Following</div>
                    </div>
                    <div className="middle"></div>
                    <div className="item" onClick={() => {
                        setCurrentType("Followers")
                    }}>
                        <div className="frist">{followfansNum.fansCount || 0}</div>
                        <div className={`end ${currentType == "Followers" ? 'active' : ''}`}>Followers</div>
                    </div>
                </div>


                <div className="card">
                    <div className='body'>
                        {
                            dataList && dataList.map((item: any) => {
                                return <div className='item' key={item.followedUserId}>
                                    <div className="left"><img src={item?.avatar || headImg} />  {item?.name}</div>
                                    <div className="right">
                                        {
                                            currentType === 'Following' ? <>
                                                <div className="btn fbtn" onClick={() => {
                                                    setOpVisible(true)
                                                    setFollowedUserId(item.followedUserId)
                                                }}><CheckOutlined />  Following </div>
                                            </> : <>
                                                {
                                                    item.relStatus === 0 ?
                                                        <div className='btn' onClick={() => {
                                                            dispatch(operateFollow_Func('add', item.follwerUserId, () => {

                                                            }, 1000))
                                                        }}>Follow</div> :
                                                        <div className='btn fbtn' onClick={() => {
                                                            setOpVisible(true)
                                                            setFollowedUserId(item.followedUserId)
                                                        }}> <CheckOutlined /> Following</div>
                                                }
                                            </>
                                        }

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </Popup>
        <ActionSheet
            className="h5-soge-ActionSheet"
            visible={opVisible}
            actions={actions}
            onClose={() => setOpVisible(false)}
        />
    </>
}