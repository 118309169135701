import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { balance, getReceiver, history, withdrawApply, bonusPoolQuery, charitablePool, receivableAmount, receiveAll, userRecords } from '@/api/account';
import { closeLoad, openLoad, showFailModal, showSuccessModal } from './globalClice';
import { AbiItem } from 'web3-utils';
import { message } from 'antd';
import { particle_getPrice } from '@/api/seaport';
import { messageUtil } from '@/utils/tools';


export enum AssetTypeEnum {
  POGO = 'POGO',
  ETH = "ETH"
}

interface AccountCliceState {
  balance: any[],
  historyList: any,
  receiverAddress: string,
  bnbBalance: number,
  pogoBalance: number,
  marketUnitPrice: any,
  jackpotInfo: any,
  poolBalance: string,
  amountBalance: string,
  recordList: any[]
}
const initialState: AccountCliceState = {
  balance: [],
  historyList: {},
  receiverAddress: '',
  bnbBalance: 0,
  pogoBalance: 0,
  marketUnitPrice: null,
  jackpotInfo: {},
  poolBalance: '',
  amountBalance: '',
  recordList: []
}

const AccountClice = createSlice({
  name: 'AccountModal',
  initialState,
  reducers: {
    setBalance(state, { payload }) {
      state.balance = payload;
    },
    setHistoryList(state, { payload }) {
      state.historyList = payload;
    },
    setReceiverAddress(state, { payload }) {
      state.receiverAddress = payload
    },
    setBnbBalance(state, { payload }) {
      state.bnbBalance = payload
    },
    setPogoBalance(state, { payload }) {
      state.pogoBalance = payload
    },
    setMarketUnitPrice(state, { payload }) {
      state.marketUnitPrice = payload
    },
    setJackpotInfo(state, { payload }) {
      state.jackpotInfo = payload
    },
    setPoolBalance(state, { payload }) {
      state.poolBalance = payload
    },
    setAmountBalance(state, { payload }) {
      state.amountBalance = payload
    },
    setRecordList(state, { payload }) {
      state.recordList = payload
    }
  }
});

export const { setBalance, setHistoryList, setReceiverAddress, setBnbBalance, setPogoBalance, setMarketUnitPrice, setJackpotInfo, setPoolBalance, setAmountBalance, setRecordList } = AccountClice.actions;

/**
 * 钱包余额接口
 * @returns 
 */
export const getBalance_Func = (): AppThunk => async (dispatch, getState) => {
  const result: any = await balance()
  if (result.success) {
    const arr: any = []
    result.data.forEach((item: any) => {
      if (item.asset === 'POGO' || item.asset === 'BNB') {
        arr.push(item)
      }
    })
    dispatch(setBalance(arr))
  }
}

/**
 * 获取链上bnb 交易
 * @param accout 
 * @returns 
 */
export const getWalletBalance_Func = (accout: string, pogoContractAddress: string): AppThunk => async (dispatch, getState) => {
  const balances = await window.web3.eth.getBalance(accout);
  const bnbBalance = window.web3.utils.fromWei(balances, 'ether');
  dispatch(setBnbBalance(Number(bnbBalance).toFixed(4)))


  const abi: AbiItem = {
    constant: true,
    inputs: [{ "name": "_owner", "type": "address", "internalType": "address" }],
    name: "balanceOf",
    outputs: [{ "name": "balance", "type": "uint256", "internalType": "address" }],
    type: "function"
  }
  const contract = new window.web3.eth.Contract([abi], pogoContractAddress);
  const pogoBalance = await contract.methods.balanceOf(accout).call();
  const pogoAmount = window.web3.utils.fromWei(pogoBalance, 'ether');
  dispatch(setPogoBalance(pogoAmount))


}
/**
 * 获取市场单价
 * @param chain_Id 
 * @param pogoContractAddress 
 * @returns 
 */
export const particle_getPrice_Func = (chain_Id: number, pogoContractAddress: string): AppThunk => async (dispatch, getState) => {
  const result: any = await particle_getPrice(chain_Id, ['native', pogoContractAddress]);
  if (result && result.data.result) {
    let marketUnitPrice: any = {};
    result.data.result.forEach((item: any) => {
      if (item.address == "native") {
        marketUnitPrice["bnb"] = item.currencies[0].price
      } else if (item.address == pogoContractAddress) {
        marketUnitPrice["pogo"] = item.currencies[0].price
      }
    })
    dispatch(setMarketUnitPrice(marketUnitPrice))
  }
}


export const sendTransaction_Func = (account: string, amount: number): AppThunk => async (dispatch, getState) => {

  const result: any = await getReceiver();
  const serverConfig = getState().globalModule.serverConfig;
  if (result.success && result.data.length > 0) {


    const contractABI: AbiItem[] = [
      {
        "constant": false,
        "inputs": [
          {
            "name": "_to",
            "type": "address"
          },
          {
            "name": "_value",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "payable": false,
        "type": "function"
      }
    ]


    // 合约的 ABI 和地址
    const contractAddress = serverConfig?.pogoContractAddress; // ERC-20 代币合约地址 

    // 创建代币合约实例
    const contract = new window.web3.eth.Contract(contractABI, contractAddress);

    // 发送者地址
    const senderAddress = (await window.web3.eth.getAccounts())[0];

    // 接收者地址和转账数量（使用合约的小数位精度）
    const receiverAddress = result.data[0]; // 接收者地址
    const amount2 = window.web3.utils.toWei(amount.toString(), 'ether'); // 转账数量，以 wei 为单位

    dispatch(openLoad())
    try {
      // 调用合约的 transfer 方法
      const txnParams = {
        from: senderAddress,
        gasPrice: window.web3.utils.toWei('3', 'gwei')
      };
      const tx = await contract.methods.transfer(receiverAddress, amount2).send(txnParams);

      console.log('Transaction hash:', tx.transactionHash);
      console.log('Transaction receipt:', tx);
      dispatch(getBalance_Func())
      contractAddress && dispatch(getWalletBalance_Func(account, contractAddress));
      dispatch(closeLoad())
      dispatch(showSuccessModal("Recharged successfully"));
      // 你可以在此处处理交易成功后的逻辑
    } catch (error: any) {
      dispatch(closeLoad())
      messageUtil(error.message, "error")
      // 处理错误情况
    }

  }



}



/**
 * 历史账单接口
 * @param month 
 * @param pageStart 
 * @returns 
 */
export const getHistory_Func = (month: string, pageStart: number = 1, pageSize: number, timestamp: number, cb?: Function): AppThunk => async (dispatch, getState) => {
  const result: any = await history(month, pageStart, pageSize, timestamp)
  if (result.success) {
    const data = result.data
    const newObj = {
      list: [],
      count: null
    }
    const arr: any = []
    data.list.forEach((item: any) => {
      const obj = {
        time: '',
        days: '',
        billSubject: item.billSubject,
        memo: '-',
        amount: item.amount,
        status: item.status,
        imgStat: 0,
        billType: item.billType,
        hashUrl: ''
      }
      let newAmount = item.amount
      if (newAmount.indexOf('-')) {
        obj.amount = '+' + item.amount
        obj.imgStat = 0
      } else {
        obj.amount = item.amount
        obj.imgStat = 1
      }
      // let newMemo = item.memo
      console.log(item.memo);
      if (item.memo !== undefined) {
        if (item.memo.length > 30) {
          obj.memo = item.memo.slice(0, 5) + '...' + item.memo.slice(-5, item.memo.length)
          obj.hashUrl = item.memo.slice(7)
        } else {
          obj.memo = item.memo
        }
      }
      const newDate = new Date(item.createTime);
      let year = newDate.getFullYear();
      let month = newDate.getMonth() + 1 < 10 ? '0' + Number(newDate.getMonth() + 1) : newDate.getMonth() + 1;
      let day = newDate.getDate() < 10 ? '0' + Number(newDate.getDate()) : newDate.getDate();
      let days = year + '-' + month + '-' + day
      let h = (newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours())
      let m = (newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes())
      let s = (newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds())
      let time = h + ':' + m + ':' + s
      obj.time = time
      obj.days = days
      arr.push(obj)
    })
    newObj.list = arr
    newObj.count = result.data.count
    cb && cb(newObj)
    // dispatch(setHistoryList(result.data))
  }
}

/**
 * 获取充值地址地址
 * @returns 
 */
export const getReceiver_func = (): AppThunk => async (dispatch, getState) => {
  const result: any = await getReceiver()
  if (result.success && result.data.elngth > 0) {
    dispatch(setReceiverAddress(result.data[0]))
  }
}

export const withdrawApply_Func = (asset: string, amount: number): AppThunk => async (dispatch, getState) => {
  const result: any = await withdrawApply(asset, amount);
  if (result.success) {
    dispatch(getBalance_Func())
    if (process.env.REACT_APP_ISMOBILE === 'true') {
      messageUtil("Operation successful,Withdrawal requires manual review, which takes 3~5 working days")
    } else {
      dispatch(showSuccessModal("Operation successful,Withdrawal requires manual review, which takes 3~5 working days"))
    }

  } else {
    if (process.env.REACT_APP_ISMOBILE === 'true') {
      messageUtil("Operation failed")
    } else {
      dispatch(showFailModal("Operation failed"))
    }
  }
}

export const bonusPoolQuery_Func = (date: string): AppThunk => async (dispatch, getState) => {
  const result: any = await bonusPoolQuery(date)
  if (result.success) {
    dispatch(setJackpotInfo(result.data))
  } else {
    messageUtil(result.msg, "error")
  }
}

export const charitablePool_Func = (): AppThunk => async (dispatch, getState) => {
  const result: any = await charitablePool()
  if (result.success) {
    dispatch(setPoolBalance(result.data))
  } else {
    messageUtil(result.msg, "error")
  }
}

export const receivableAmount_Func = (): AppThunk => async (dispatch, getState) => {
  const result: any = await receivableAmount()
  if (result.success) {
    dispatch(setAmountBalance(result.data))
  } else {
    messageUtil(result.msg, "error")
  }
}

export const receiveAll_Func = (): AppThunk => async (dispatch, getState) => {
  const result: any = await receiveAll()
  if (result.success) {
    dispatch(receivableAmount_Func())
    messageUtil('Received successfully')
  } else {
    messageUtil(result.msg, "error")
  }
}

/**
 * 查询用户分红记录
 * @param month 
 * @returns 
 */
export const userRecords_Func = (month: string): AppThunk => async (dispatch, getState) => {
  const result: any = await userRecords(month)
  if (result.success) {
    dispatch(setRecordList(result.data))
  } else {
    messageUtil(result.msg, "error")
  }
}

export default AccountClice.reducer;