import { AssetTypeEnum } from "@/features/accountClice";
import { ChannelTypeEnum, setWatchStop } from "@/features/globalClice";
import { RootState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import noLive from '@assets/h5Images/noLive';
import LiveOperate from '@H5Components/LiveOperate';
import Operate from '@H5Components/LiveOperate/operate'
import './index.scss'
import { useEffect, useRef } from "react";
import { getOnLineList_Func, getliveInfo_Func, setRoomInfo, setTokenInfo_Func, tokenResultError_Func } from "@/features/roomClice";
import LiveVideo from "@H5Components/LiveVideo";
import { getRoomRtcToken_fun } from "@/features/loginClice";
import { WebsocketConnect, clearMessage, webSocketClose } from "@/features/webSocketClice";
import Watched from "../Watched";
import ChatWindow from "../ChatWindow";

export default function Index() {


    const dispatch = useDispatch(); 

    const { VIPNFT, roomId, orginMessageList, roomName, liveId } = useSelector((state: RootState) => {
        let roomId = 0;
        let roomName = '';

        if (state.roomModule.onLineList.length > 0) {
            const roomObj = state.roomModule.onLineList.find((item) => {
                return item.roomType == 0
            })
            if (roomObj) {
                roomId = roomObj.roomId
                roomName = roomObj.roomName
            }
        }

        const allPogoBalance = state.accountModal.balance?.find((item) => {
            return item.asset == AssetTypeEnum.POGO
        })
        return {
            roomInfo: state.roomModule.roomInfo,
            VIPNFT: state.nftModule.VIPNFT,
            roomId: roomId,
            pogoBalance: allPogoBalance,
            userInfo: state.loginModule.userInfo,
            orginMessageList: state.webSocketModule.messageList,
            roomName: roomName,
            liveId: state.roomModule.liveId
        }
    })

    useEffect(() => {
        if (VIPNFT && roomId) {
            join(VIPNFT.length > 0 ? 2 : 3)
        }
        return () => {
            dispatch(clearMessage())
            dispatch(setRoomInfo(null))
            webSocketClose()
        }
    }, [VIPNFT, roomId])

    useEffect(() => {
        if (liveId > 0) {
            dispatch(getliveInfo_Func(liveId))
            //   dispatch(getRedPacket_Func(liveId))
        }
    }, [liveId, roomId]);
    const join = async (type: 1 | 2 | 3) => {
        const result: any = await getRoomRtcToken_fun(roomId, type);
        if (result.success) {
            dispatch(setTokenInfo_Func(result));
            WebsocketConnect(result.data.rtmToken, roomId.toString() || '', type)

        } else {
            dispatch(tokenResultError_Func(result))

            if (result.code != "PR011") {

            } else {
                // setTryComplate(true)
            }
        }

    }

    useEffect(() => {
        dispatch(setWatchStop(false))
    }, [])
    useEffect(() => {
        // 定时轮询的间隔，这里设置为5秒
        dispatch(getOnLineList_Func())
        const interval = setInterval(() => {
            dispatch(getOnLineList_Func())
        }, 10000);

        // 组件卸载时清除定时器
        return () => {
            clearInterval(interval);
        };

    }, [])



    if (roomId > 0) {
        return <>
            <LiveOperate type={ChannelTypeEnum.live} watchCount={0}/>
            <div className="audio">
                <LiveVideo />
            </div>
            <div className="h5-roomName">{roomName}</div>
            <Watched videoId={roomId} />
            <div className={`message-container ${orginMessageList.length <= 0 ? 'soga-nomessage' : ''}`}>
                <ChatWindow />
            </div>
            <Operate type={ChannelTypeEnum.live} />
        </>
    } else {
        return <div className="no-live">
            <img src={noLive} />
            <div>No live streaming</div>
        </div>
    }
}

