import { axios as ax } from '@utils/request'
import { config } from '../config';
import axios from 'axios'
import { v4 } from 'uuid'


export function seaport_listing(chainId: number, params: any) {
    return ax({
        url: `https://api-market.particle.network/chains/${chainId}/listing`,
        method: 'post',
        data: params
    })
}


export function getTokens(chainId: number, params: string[]) {
    return axios.post('https://rpc.particle.network/evm-chain', {
        chainId: chainId,
        jsonrpc: '2.0',
        id: v4(),
        method: 'particle_getNFTs',
        params: params,
    }, {
        auth: {
            username: config.projectId,
            password: config.clientKey,
        }
    });
}

export function particle_abi_eth_call(chain_Id: number, params: any) {
    return axios.post('https://rpc.particle.network/evm-chain', {
        chainId: chain_Id,
        jsonrpc: '2.0',
        id: v4(),
        method: 'eth_call',
        params: params,
    }, {
        auth: {
            username: config.projectId,
            password: config.clientKey,
        }
    });
}

export function particle_abi_encodeFunctionCall(chain_Id: number, params: string[]) {
    return axios.post('https://rpc.particle.network/evm-chain', {
        chainId: chain_Id,
        jsonrpc: '2.0',
        id: v4(),
        method: 'particle_abi_encodeFunctionCall',
        params: params,
    }, {
        auth: {
            username: config.projectId,
            password: config.clientKey,
        }
    });
}



export function particle_getPrice(chain_Id: number, params: string[]) {
    return axios.post('https://rpc.particle.network/evm-chain', {
        chainId: chain_Id,
        jsonrpc: '2.0',
        id: v4(),
        method: 'particle_getPrice',
        params: [
            params,
            [
                'usd'
            ],
        ],
    }, {
        auth: {
            username: config.projectId,
            password: config.clientKey,
        }
    });
}


export function particle_swap_checkApprove(walletAddress:string,tokenAddress:string,amount:string){
    return axios.post('https://rpc.particle.network/evm-chain',{
        "id":v4(),
        "jsonrpc": "2.0",
        "method": "particle_swap_checkApprove",
        "params": [
            walletAddress, // wallet address
          {
            "tokenAddress": tokenAddress, // from token address
            "amount": amount
          }
        ]
      }, {
        auth: {
            username: config.projectId,
            password: config.clientKey,
        }
    });
}

export function getJsonFecth(url: string) {
    return fetch(url).then(function (response) {
        return response.json();
    })
}

