import { ConnectButton, useAccount, useConnectKit } from "@particle-network/connect-react-ui";
import './index.scss';
import { config } from "@/config";
import { getAddress } from "@/utils/stringFormat";
import useRefreshToken from "@/hooks/useRefreshToken";



export default function Index(){
    useRefreshToken()
    const account=useAccount();
    const connectKit = useConnectKit()
    return <div>
        <ConnectButton.Custom>
            {(customProps)=>{
                return <div className={`soga-connectBtn ${customProps.account && !config.chainIds.includes(customProps.chain?.id || 0) && 'soga-wrong'}`} onClick={()=>{
                    if(customProps.account &&  config.chainIds.includes(customProps.chain?.id || 0)){
                        // connectKit.disconnect() 
                    }else if(customProps.account && !config.chainIds.includes(customProps.chain?.id || 0)){
                        customProps.openChainModal &&  customProps.openChainModal();
                    }else{
                        customProps.openConnectModal && customProps.openConnectModal();
                    }
                }}>
                    {
                        customProps.account ? <>
                            {config.chainIds.includes(customProps.chain?.id || 0) ? getAddress(account || '') : "Wrong network"}
                        </>:"connect wallet"
                    }
                </div>
            }}
        </ConnectButton.Custom>
    </div>
}