import "./index.scss";
import { Outlet, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import useWeb3 from "@/hooks/useWeb3";
import { useAccount, useConnectKit, useNetwork } from "@particle-network/connect-react-ui";
import { useDispatch, useSelector } from "react-redux";
import { Loading, Mask, SpinLoading, TabBar } from 'antd-mobile'
import { FC, useEffect } from "react";
import home, { homeSelected } from '@assets/h5Images/home';
import quest, { questSlected } from '@assets/h5Images/quest';
import myBag, { myBagSelected } from '@assets/h5Images/myBag';
import myNfts, { myNftsSelected } from '@assets/h5Images/myNfts';
import { walletIcon, walletSelectedIcon } from '@assets/h5Images/wallet';
import { disConnect_Func } from "@/features/loginClice";
import { RootState } from "@/store";
import { getLockedVip_Func, nftInit_Func } from "@/features/nftsClice";
import { getBalance_Func, getWalletBalance_Func, particle_getPrice_Func } from "@/features/accountClice";
import { TransferModalTypeEnum, closeBoxResult, closeSendGiftModal, getConfig_Func, setInviterModal, setTransferModal } from "@/features/globalClice";
import { getGifts_Func } from "@/features/giftClice";
import { discordCallback_Func, getUserTasks_Func, twitter_callback_Func } from "@/features/taskClice";
import { onMessageSubject } from "@/features/webSocketClice";
import smallLogo from '@assets/h5Images/smallLogo';
import ConnectButton from '@H5Components/connectButton';
import ConnectWallet from '@H5Components/connectWallet';
import SendGift from '@H5Components/modal/sendGift';
import GiftsPopup from '@H5Components/modal/giftsPopup';
import InviterModal from '@H5Components/modal/inviterModal'
import BoxResult from '@H5Components/modal/boxResult';
import TransferModal from '@H5Components/modal/transferModal'



const Bottom: FC = () => {
    const location = useLocation()
    const { pathname } = location
    const navigate = useNavigate()

    const setRouteActive = (value: string) => {
        navigate(value)
    }

    const tabs = [
        {
            key: '/',
            title: 'Home',
            icon: (active: boolean) => {
                if (active) {
                    return <img className="icon-item" src={homeSelected} />
                } else {
                    return <img className="icon-item" src={home} />
                }
            },
        },
        {
            key: '/questlist',
            title: 'Quest',
            icon: (active: boolean) => {
                if (active) {
                    return <img className="icon-item" src={questSlected} />
                } else {
                    return <img className="icon-item" src={quest} />
                }
            },
        },
        // {
        //     key: '/myNfts',
        //     title: 'My Nfts',
        //     icon: (active: boolean) => {
        //         if (active) {
        //             return <img className="icon-item" src={myNftsSelected} />
        //         } else {
        //             return <img className="icon-item" src={myNfts} />
        //         }
        //     },
        // },
        {
            key: '/wallet',
            title: 'Wallet',
            icon: (active: boolean) => {
                if (active) {
                    return <img className="icon-item" src={walletSelectedIcon} />
                } else {
                    return <img className="icon-item" src={walletIcon} />
                }
            },
        },
    ]


    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}


export default function Index() {
    useWeb3() 
    const dispatch = useDispatch();
    const { chain, connectId } = useNetwork();
    const account = useAccount();

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();




    const { loginState, serverConfig, userInfo, VIPNFT, channelType, loadStat, sendGiftModal, giftListModal,inviterModal,boxResult,transferModal } = useSelector((state: RootState) => {
        return {
            loginState: state.loginModule.loginState, 
            serverConfig: state.globalModule.serverConfig,
            userInfo: state.loginModule.userInfo,
            VIPNFT: state.nftModule.VIPNFT,
            channelType: state.globalModule.channelType,
            loadStat: state.globalModule.loadStat,
            sendGiftModal: state.globalModule.sendGiftModal,
            giftListModal: state.globalModule.giftListModal,
            inviterModal: state.globalModule.inviterModal,
            boxResult:state.globalModule.boxResult,
            transferModal:state.globalModule.transferModal
        }
    })


    // console.log(chain)
    useEffect(() => {
        if (loginState && chain && userInfo) {
            // dispatch(nftInit_Func(chain.id, userInfo.wallet))
            dispatch(getBalance_Func())
            dispatch(getConfig_Func())
            dispatch(getUserTasks_Func())
        }

    }, [loginState, chain, userInfo?.userId])


    useEffect(() => {
        if (serverConfig && account) {
            dispatch(getWalletBalance_Func(account, serverConfig.pogoContractAddress));
        }
    }, [serverConfig, account])

    useEffect(() => {
        if (chain && serverConfig) {
            dispatch(particle_getPrice_Func(chain.id, serverConfig.pogoContractAddress))
        }
    }, [chain, serverConfig])

    useEffect(() => {
        if (userInfo) {
            dispatch(getGifts_Func(channelType))
        }
    }, [userInfo, channelType])


    useEffect(() => {
        if (searchParams.get('state') && searchParams.get('code') && loginState) {

            dispatch(twitter_callback_Func(searchParams.get('state') || '', searchParams.get('code') || ''))
        }
    }, [searchParams.get('state'), searchParams.get('code'), loginState])


    useEffect(() => {
        if (searchParams.get('code') && loginState) {
            dispatch(discordCallback_Func(searchParams.get('code') || ''))
        }
    }, [searchParams.get('code'), loginState])

    useEffect(() => {
        dispatch(onMessageSubject());
    }, [])



    return <>
        <div className="h5App">
            <div className="h5Header">
                <img src={smallLogo} />

                <ConnectButton />
            </div>
            {
                (account) ? <>
                    <div className="h5body">
                        <Outlet />
                    </div>
                    <div className="H5Bottom">
                        <Bottom />
                    </div>

                </> : <>
                    <ConnectWallet />
                </>
            }

        </div>

        <Mask visible={loadStat} >
            <SpinLoading className="h5-SpinLoading" color="#FFFFFF" />
        </Mask>
        <SendGift visible={sendGiftModal.show} onClose={() => {
            dispatch(closeSendGiftModal())
        }} />
        <GiftsPopup visible={giftListModal} />
        <InviterModal visible={inviterModal} onClose={()=>{
            dispatch(setInviterModal(false))
        }}/>
        <BoxResult visible={boxResult.show} onClose={() => {
            dispatch(closeBoxResult())
        }} />
        <TransferModal visible={transferModal.show} onClose={()=>{
             dispatch(setTransferModal({
                show:false,
                type:TransferModalTypeEnum.recharge
              }))
        }}/>
    </>
}