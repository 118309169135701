import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOrginMessageList } from '../../features/roomClice';
import { RootState } from '@/store';

export default function Index() {
    const dispatch = useDispatch();
    const { comments } = useSelector((state:RootState) => {
        return {
            comments: state.roomModule.comments,
        }
    })
    useEffect(() => {
        let ter:any;
        if (comments && comments.length > 0) {
            let index = comments.length - 1;
            ter = setInterval(() => { 
                dispatch(setOrginMessageList(comments[index]));
                index = index - 1;
                if (index == 0) {
                    index = comments.length - 1;
                }
            }, 400);
        }
        return () => {
            ter && clearInterval(ter);
        }
    }, [comments]);

    return {
        
    }
}